

import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import {
    Typography,
    IconButton,
    Divider,
    CircularProgress,
    useTheme
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NavigationIcon from '@mui/icons-material/Navigation';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import axios from 'axios';
import config from '../../config';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CommentTextField } from '../PatientFormContainer/pages/PatientInfoPage/components/CommentTextField';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)




const supportedWhatAppFileTypes = ['DOCUMENT', 'AUDIO', 'VIDEO', 'VOICE'];

const statusIcons = {
    failed: <ErrorOutlineIcon sx={{ fontSize: '1.25rem', color: 'red' }} />,
    pending: <CircularProgress size={'1rem'} sx={{ color: 'white' }} />,
    delivered: <CheckIcon sx={{ fontSize: '1.25rem', color: 'white' }} />,
    seen: <DoneAllIcon sx={{ fontSize: '1.25rem', color: 'white' }} />
};


const WhatsappChatComponent = ({
    selectedChat,
    setsnackbarStatus,
    messageQueryData

}) => {

    const [message, setMessage] = useState('');

    const queryClient = useQueryClient();

    const theme = useTheme();

    const sendMessageMutation = useMutation({
        mutationFn: ({ updaterData }) => {

            return axios.post(
                `${config.API_URL}/operator/whatsAppSend`,
                updaterData,
                { withCredentials: true }
            )

        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['messageData'] });

            setMessage('');
        },
        onError: (error, variables) => {
            setsnackbarStatus({ open: true, message: "Failed to Send Message", severity: 'error' })
        }
    });

    const currentChatUser = messageQueryData?.data[selectedChat]?.conversation;

    const handleAddComment = async (e) => {
        e.preventDefault();

        const updaterData = {
            to: currentChatUser,
            content: message,
        };
        sendMessageMutation.mutate({ updaterData });

    };

    const [imageHover, setImageHover] = useState(-1);
    const [position, setPosition] = useState({ x: 0, y: 0 });


    const messagesArray = messageQueryData?.data[selectedChat]?.messages.sort((a, b) => new Date(a.date) - new Date(b.date));




    const renderComments = () => {

        let groupedComments = [];
        let dateParseCurrent = null;
        let dateParsePrevious = null;
        let concatString = '';
        if (messagesArray?.length > 0) {
            groupedComments = messagesArray.reduce((acc, msgData, index) => {

                const messageText = msgData?.message?.text;
                if (index === 0) {
                    return [{ ...msgData }];
                } else if (msgData?.message?.type.toUpperCase() !== "TEXT") {
                    return [...acc, { ...msgData }];

                } else {

                    dateParseCurrent = new Date(msgData.date);
                    dateParsePrevious = new Date(messagesArray[index - 1].date);

                    if (msgData?.from === messagesArray[index - 1]?.from && ((dateParseCurrent) - (dateParsePrevious)) <= 60000) {
                        concatString = `${acc[acc.length - 1].message?.text} \n ${messageText}`;
                        return [...acc.slice(0, acc.length - 1), { ...msgData, message: { type: 'text', text: concatString } }]
                    } else {
                        return [...acc, { ...msgData }];
                    }

                }


            }, []
            )
        }
        const groupedCommentsArray = Object.values(groupedComments);

        return (
            groupedCommentsArray?.map((msgData, index) => {

                const parseDate = new Date(msgData.date);

                return (
                    <div key={`${msgData['_id']}-${index}-root-div`} className={`commentRow ${(msgData.type === 'outgoing') ? "commentRowMe" : "commentRowYou"}`}>
                        {

                            (msgData.type === 'outgoing') ? // Operator Mode (change to Operator Name when new accounts are added)
                                <>
                                    <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold', color: 'rgb(125,188,255)', marginRight: '5px', lineBreak: 'anywhere' }}>
                                        {`${msgData.userLabel}`}
                                    </Typography>
                                    <div className='DividerStampContainer'>

                                        <div className='commentDateStamp'>
                                            <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#ffffff8a', paddingRight: '5px' }}>
                                                {`${dayjs(parseDate).fromNow()}`}
                                            </Typography>
                                        </div>
                                        <Divider orientation="horizontal" flexItem sx={{
                                            background: '#ffffff3b', width: `60%`
                                            , height: '2px', borderRadius: '16px', alignSelf: 'center'
                                        }} />

                                    </div>
                                </>
                                :
                                <>
                                    <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#02f779bd', lineBreak: 'anywhere' }}>
                                        {`+${msgData.from}`}
                                    </Typography>
                                    <div className='DividerStampContainer'>

                                        <Divider orientation="horizontal" flexItem sx={{
                                            background: '#ffffff3b', height: '2px', width: `60%`,
                                            borderRadius: '16px', alignSelf: 'center', maxWidth: '200px'
                                        }} />
                                        <div className='commentDateStamp'>
                                            <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#ffffff8a', paddingRight: '5px' }}>
                                                {`${dayjs(parseDate).fromNow()}`}
                                            </Typography>
                                        </div>
                                    </div>
                                </>


                        }





                        <div className={`commentElement ${(msgData.type === 'outgoing') ? "commentElementOperator" : "commentElementUser"}
                                ${((msgData.type === 'outgoing') ? "commentElementMe" : "commentElementYou")}`}
                        >

                            {(msgData.type === 'outgoing') &&
                                <div className='commentIconContainer'>
                                    {statusIcons[msgData.status]}
                                </div>}

                            <div className='commentTextContainer'>
                                {
                                    msgData?.message?.type?.toUpperCase() === "TEXT" && msgData?.message?.text?.split('\n').map((text, index) => {
                                        return (
                                            <Typography key={`${msgData['_id']}-${index}`} sx={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'white' }}>
                                                {`${text}`}
                                            </Typography>
                                        )
                                    })
                                }
                                {
                                    msgData?.message?.type?.toUpperCase() === "IMAGE" &&
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}>
                                        {msgData?.message?.caption && <Typography key={`${msgData['_id']}-img-caption`} sx={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'white', marginBottom: '8px' }}>
                                            {`${msgData?.message?.caption}`}
                                        </Typography>}

                                        <img
                                            onClick={() => {
                                                const fileUrlArray = msgData?.message?.blobUrl.split('/');
                                                const link = document.createElement('a');
                                                link.href = msgData?.message?.blobUrl;
                                                link.download = `${fileUrlArray[fileUrlArray.length - 1].split('?')[0]}`; // Set to desired filename
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                            }}

                                            onMouseMove={(e) => {
                                                setImageHover(index);
                                                //const rect = e.target.getBoundingClientRect();
                                                setPosition({ x: e.clientX, y: e.clientY });
                                            }}
                                            onMouseLeave={() => setImageHover(-1)}
                                            src={msgData?.message?.blobUrl}
                                            className={`whatsAppImage`}
                                        />

                                        {imageHover === index && createPortal(
                                            <img
                                                src={msgData?.message?.blobUrl}
                                                style={{
                                                    position: 'fixed',
                                                    left: `${position.x}px`,
                                                    top: `${position.y - 400}px`
                                                }}
                                                className={`whatsAppImageHover`}
                                            />,
                                            document.getElementById('portal-root'), `portal-${index}`
                                        )}


                                    </div>




                                }
                                {
                                    supportedWhatAppFileTypes.includes(msgData?.message?.type?.toUpperCase()) &&
                                    <div>
                                        {msgData?.message?.caption && <Typography key={`${msgData['_id']}`} sx={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'white' }}>
                                        {`${msgData?.message?.caption}`}
                                        </Typography>}
                                        <a
                                        href={msgData?.message?.blobUrl}
                                        download
                                        style={{color: 'white'}}
                                        >
                                        {`${msgData?.message?.blobName}`}
                                        </a>

                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                )


            })
        )
    }


    return (<>

        <div id="portal-root"></div>
        <div className='commentsContainer'>

            <div key={'commentsDisplay-root'} className='commentsDisplay'>
                <div>
                    {messagesArray && messagesArray?.length > 0 && renderComments()}
                </div>

            </div>

            <div className='commentsInput'>

                <CommentTextField
                    id="standard-multiline-comment"
                    variant='standard'
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />

                <div style={{ height: '100%', width: 'auto', display: 'flex', alignItems: 'center' }}>

                    <IconButton
                        onClick={handleAddComment}
                        disabled={sendMessageMutation?.isPending}
                        sx={{

                            background: 'rgb(55, 107, 185)',
                            outline: '2px solid #7ca8c696',
                            '&:hover': {
                                transition: '0.25s ease-in-out', background: 'rgb(125,188,255)'
                            }
                        }}>
                        <NavigationIcon sx={{ transform: 'rotate(90deg)', fontSize: '1.25rem', }} />
                    </IconButton>

                </div>
            </div>


        </div>

    </>)

}



export default WhatsappChatComponent;

























