import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import TextLanguage from '../../../data/TextLanguage.json';
import { ResponsiveLine } from '@nivo/line';
import { countColor, usdColor, exrColor } from './DataTablePaymentHeader';

import { NivoTheme } from '../../Dashboard/NivoTheme';

const customTheme = {
    ...NivoTheme,
    crosshair: {
        line: {
            stroke: 'red', // Change this to your desired color
            strokeWidth: 1,
            strokeOpacity: 0.75,
        },
    },
};

/*

<div className='PayrollUploaderContainer'>
                <FormControl sx={{ minWidth: 150 }}>
        <InputLabel shrink={true} id="payrollSelectorLabel">
            <Typography>
                {`${TextLanguage["PayrollInsuranceType"][lang]}`}
            </Typography>
        </InputLabel>
        <Select
            value={payrollType}
            onChange={handlePayrollTypeSelect}

            sx={{ width: 250, maxHeight: 45, textAlign: 'left' }}
        >
            {payrollTypeList?.map((option) => (
                <MenuItem key={`payrollType-${option.value}`} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
*/
export function InlineGraph({
    parsedPaymentListHospQuery,
    lang

}) {


    const [parsedPaymentGraphData, setParsedPaymentGraphData] = useState([]);
    const [graphMaxValues, setGraphMaxValues] = useState({
        totalCount: 1,
        GOPTotalUSD: 1,
        GOPTotalTL: 1,
        EXR: 1
    });

    useEffect(() => {
        if (parsedPaymentListHospQuery.isSuccess) {

            const aggrList = parsedPaymentListHospQuery?.data?.data?.aggregateDateList.map((record) => {
                return {
                    ...record,

                    totalCount: record.totalCount,
                    EXR: record.GOPTotalTL / (record.GOPTotalUSD * 100),
                    GOPTotalTL: record.GOPTotalTL
                }
            });
            const maxValues = aggrList.reduce((max, item) => {
                return {
                    totalCount: Math.max(max.totalCount, item.totalCount),
                    GOPTotalUSD: Math.max(max.GOPTotalUSD, item.GOPTotalUSD),
                    GOPTotalTL: Math.max(max.GOPTotalTL, item.GOPTotalTL),
                    EXR: Math.max(max.EXR, item.EXR)
                };
            }, { GOPTotalUSD: -Infinity, GOPTotalTL: -Infinity, totalCount: -Infinity, EXR: -Infinity });

            setGraphMaxValues(maxValues);

            const normalizedAggrList = aggrList.map((record) => {
                return {
                    ...record,

                    totalCount: record.totalCount / maxValues.totalCount,
                    GOPTotalUSD: record.GOPTotalUSD / maxValues.GOPTotalUSD,
                    GOPTotalTL: record.GOPTotalTL / maxValues.GOPTotalTL,
                    EXR: record.EXR / maxValues.EXR
                }

            });
            //console.log(normalizedAggrList)

            setParsedPaymentGraphData([
                {
                    id: 'Total USD',
                    color: usdColor,
                    data: normalizedAggrList?.map((record) => {
                        return { x: new Date(record["paymentDateParsed"]), y: record["GOPTotalUSD"] }
                    })
                },
                {
                    id: 'Exchange Rate',
                    color: exrColor,
                    data: normalizedAggrList?.map((record) => {
                        return { x: new Date(record["paymentDateParsed"]), y: record["EXR"] }
                    })
                },
                {
                    id: 'Total Count',
                    color: countColor,
                    data: normalizedAggrList?.map((record) => {
                        return { x: new Date(record["paymentDateParsed"]), y: record["totalCount"] }
                    })
                }

            ]);

        }
        //console.log(graphMaxValues);

    }, [parsedPaymentListHospQuery.isSuccess, parsedPaymentListHospQuery.data])






    return (<div className='paymentListHeaderWrapper' style={{
        width: '100%',
        height: '56px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    }}>

        <div style={{ width: '100%', position: 'absolute', top: '0px', bottom: '0px' }}>
            <div style={{ width: '100%', height: '100%' }}>
                <ResponsiveLine data={parsedPaymentGraphData} animate curve="monotoneX" //colors={{ datum: 'color' }}
                    enableSlices="x" pointColor={{
                        theme: 'background'
                    }}
                    margin={{
                        bottom: 8,
                        left: 4,
                        right: 4,
                        top: 8
                    }}
                    theme={customTheme}
                    colors={{
                        datum: 'color'
                    }}
                    fill={[{ match: { id: 'Exchange Rate' }, id: 'gradientEmpty' }]}
                    defs={[
                        {
                            id: 'gradientEmpty',
                            type: 'linearGradient',
                            colors: [
                                { offset: 0, color: 'rgba(255, 0, 0, 0.1d5)' },
                                { offset: 100, color: 'rgba(255, 0, 0, 0)' }
                            ]
                        }

                    ]}
                    enableArea={true}
                    enableGridX={true}
                    enableGridY={false}
                    lineWidth={1}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: 'auto',
                        stacked: false,
                        reverse: false
                    }}
                    sliceTooltip={({
                        slice
                    }) => {
                        const date = slice.points[0].data.xFormatted.split('T')[0].split('-');
                        const day = date[2];
                        const month = date[1];
                        const year = date[0]; // Calculate the tooltip position
                        return <div className='miniGraphTooltip' style={{ position:'absolute !important', top:'0px', left:'0px', opacity:'1 !important'}}>
                            <div style={{
                                marginBottom: '5px',
                                width: '100%',
                                padding: '8px',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Typography variant='h6' style={{
                                    color: 'white'
                                }}>
                                    {`${day - 1}/${month}/${year}`}
                                </Typography>
                            </div>
                            <div className='miniGraphTooltipTextContainer'>
                                <div className='miniGraphTooltipTextLine'>
                                    <div className='miniGraphTooltipMarker' style={{
                                        background: countColor
                                    }} />
                                    <Typography variant='body1' style={{
                                        color: 'white'
                                    }}>
                                        {`${(slice.points[0].data.yFormatted * graphMaxValues.totalCount).toFixed(0)} ${TextLanguage["Patients"][lang]}`}
                                    </Typography>
                                </div>
                                <div className='miniGraphTooltipTextLine'>
                                    <div className='miniGraphTooltipMarker' style={{
                                        background: usdColor
                                    }} />
                                    <Typography variant='body1' style={{
                                        color: 'white'
                                    }}>
                                        {`$${(slice.points[2].data.yFormatted * graphMaxValues.GOPTotalUSD).toFixed(0)}`}
                                    </Typography>
                                </div>
                                <div className='miniGraphTooltipTextLine'>
                                    <div className='miniGraphTooltipMarker' style={{
                                        background: exrColor
                                    }} />
                                    <Typography variant='body1' style={{
                                        color: 'white'
                                    }}>
                                        {`${(slice.points[1].data.yFormatted * graphMaxValues.EXR).toFixed(1)} ${TextLanguage["EXR"][lang]} `}
                                    </Typography>
                                </div>
                            </div>
                        </div>;
                    }}
                    xFormat="time:%Y-%m-%dT%H:%M:%S.%LZ"
                    xScale={{
                        format: '%Y-%m-%dT%H:%M:%S.%LZ',
                        precision: 'day',
                        type: 'time'
                    }}
                    axisBottom={false}
                    axisLeft={false}
                    axisTop={null}
                    axisRight={null} />
            </div>
        </div>


    </div>);
}
