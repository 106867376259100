import React, { useState, useEffect } from "react";
import TextLanguage from "../../../data/TextLanguage.json";

import {
    Autocomplete,
    Button,
    TextField
} from '@mui/material';
import { LanguageSelector } from "../../LanguageSelector";

import { BorderLinearProgress } from "../../PatientFormContainer/pages/PatientInfoPage/components/BorderLinearProgress";
import Paginator from "../Paginator";
import { FilterSelector } from "./FilterSelector";
// import { useUploadExcelFilteredList } from "../../../hooks/useUploadExcelFilteredList";
import { useQueryClient } from "@tanstack/react-query";


import { PatientListExcelButton } from "./PatientListExcelButton";

import { getReducedHospList } from '../../../functions/getReducedHospList';
import { FilterListUploadButton } from "./FilterListUploadButton";
import { matchSorter } from "match-sorter";
import { normalizeString } from "../../PatientFormContainer/pages/PatientInfoPage/functions/normalizeString";

export const DataTableFooter = ({ patientList, opMode, handleformOpen, lang,
    setSearchFilter, searchFilter, uploadShortcutMutation, setsnackbarStatus,
    uploadProgress, setUploadProgress, setlang, patientDashboard, viewportWidth
}) => {

    const queryClient = useQueryClient();


    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);
    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const validateData = queryClient.getQueryData(['validate']);
    const confirmedUserID = validateData?.data?.userID;
    const OperatorMode = confirmedUserID === 'Operator';

    //onClick={fileUploader(uploadExcelFilteredListMutation, '.xls,.xlsx', { filter })}


    // Handle checkbox change


    const hospitalFilterList = getReducedHospList(hospitalUserListData?.data);

    const filterOptions = (options, { inputValue }) => {
        const normalizedInput = normalizeString(inputValue?.toLowerCase());

        return matchSorter(options, normalizedInput, {
            keys: [option => normalizeString(option?.label?.toLowerCase())],
            base: 10,
            maxRanking: 10,
            threshold: matchSorter.rankings.CONTAINS
        })
    };

    return (<div className='maindataTableFooter'>

        <div className='dataTableHeaderBottom' style={{ padding: opMode ? '0px 0px 0px 2px' : '0px' }}>
            <div className='dataTableHeaderButtonGroup' style={{ margin: opMode ? '5px 0px' : '0px' }}>


                {opMode && <Button sx={{ borderRadius: '0px', padding: '10px 15px', maxHeight: '45px', width: 'auto',minWidth:'130px' }} onClick={handleformOpen}>
                    {TextLanguage["NewPatient"][lang]}
                </Button>}

               {!OperatorMode && <PatientListExcelButton
                    patientList={patientList}
                    lang={lang}
                    opMode={opMode}
                    worksWithTL={opMode ? true :
                        hospitalUserListData?.data[0]?.worksWithTL

                    }
                />}
                {false && <FilterListUploadButton
                    lang={lang}
                    setsnackbarStatus={setsnackbarStatus}
                    setUploadProgress={setUploadProgress}
                />}


                {hospitalUserListData?.status === 200 && opMode &&

                        <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                                option?.value === value?.value
                            }
                            filterOptions={filterOptions}
                            noOptionsText={"No Matches"}
                            size={'small'}
                            selectOnFocus
                            clearOnBlur
                            value={hospitalFilterList?.find(option => option?.value === searchFilter?.hospID) || null}
                            options={!!hospitalFilterList ?
                                hospitalFilterList?.sort((a, b) => -b?.label?.localeCompare(a?.label))
                                :
                                [{ label: 'Loading...', value: null }]
                            }
                            onChange={
                                (e, value) => {
                                    setSearchFilter({ ...searchFilter, hospID: value?.value });
                                }
                            }
                            getOptionLabel={(option) => `${option?.label}`}
                            renderInput={(params) => <TextField {...params} label={TextLanguage["FilterByHospital"][lang]} />}
                            sx={{
                                width: '100% !important', minWidth: '270px !important', maxWidth: '300px !important',
                                '& .MuiFormControl-root': { height: '45px !important', width: '100% !important' },
                                '& .MuiInputBase-root': { height: '100%' }
                            }}
                        />

                    // <FilterSelector
                    //     filterLabel={"hospID"}
                    //     selectLabel={TextLanguage["FilterByHospital"][lang]}
                    //     optionsList={hospitalFilterList}
                    //     setSearchFilter={setSearchFilter}
                    //     searchFilter={searchFilter}
                    // />
                }

                {InsuranceCompanyListData?.status === 200 && opMode && (viewportWidth > 1260) &&

                    <FilterSelector
                        filterLabel={"InsCo"}
                        selectLabel={TextLanguage["FilterByInsurance"][lang]}
                        optionsList={InsuranceCompanyListData?.data}
                        setSearchFilter={setSearchFilter}
                        searchFilter={searchFilter}
                        width={200}
                    />
                }


            </div>

        </div>

        {(uploadShortcutMutation.isPending) &&
            <div className="dataTableFooterLoadingBar">
                <BorderLinearProgress variant='determinate' value={uploadProgress}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} />
            </div>
        }

        <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>

            {(viewportWidth > 1560) && <LanguageSelector lang={lang} setlang={setlang} />}

            <Paginator
                component="div"
                count={patientDashboard?.totalPatientCount || 0}
                rowsPerPageOptions={[50, 100, 150]} />

        </div>

    </div>);
}


