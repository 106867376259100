import React from "react";
import { Divider, Typography } from '@mui/material';
import numeral from "numeral";


export const formatGOPPriceGroup = (patientRow) => {
        return (
            patientRow?.hospCases?.map(

                (hospCase, index) => {

                    const parsedPriceUSD = numeral(hospCase?.GOPPriceUSD || 0).format('0,0');
                    const parsedPriceTL = numeral(hospCase?.GOPPriceTL / 100 || 0).format('0,0.00');
                    const parsedExr = numeral((hospCase?.GOPPriceTL / 100) / hospCase?.GOPPriceUSD || 0).format('0,0.00');

                    return (<div key={`${index}-goppricegroup`} className="GOPPriceRowGroup">


                        <Typography className='RowPriceText'>
                            {`$ ${parsedPriceUSD}`}
                        </Typography>
                    <Divider orientation="vertical" flexItem />

                        <Typography className='RowPriceText'>
                        {`${parsedExr}`}
                    </Typography>

                    <Divider orientation="vertical" flexItem />

                    <Typography className='RowPriceText'>
                        {`${parsedPriceTL} ₺`}
                    </Typography>
                    </div>
                    )
                }
            )


        );
    }

