
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useMailFetchQuery = ({userID, hospID, caseNumber, opMode}) => {


    async function MailFetchFn({ queryKey }) {
        const [_key, QuerySelection] = queryKey;

        const { userID, caseNumber, hospID } = QuerySelection;

        return await axios.get(`${config.API_URL}/mail/mailContent?userID=${userID}&hospID=${hospID}&caseNumber=${caseNumber}`, {
            withCredentials: true
        });



    }

    return useQuery({
        queryKey: ['mailFetch',
            {
                caseNumber: caseNumber,
                userID: userID,
                hospID: hospID
            }

        ],
        queryFn: MailFetchFn,
        enabled: (!!opMode && !!userID && !!caseNumber && !!hospID),


    });




}