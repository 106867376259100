import React, { useState, useEffect } from "react";
import TextLanguage from "../../../data/TextLanguage.json";
import {
    FormControl,
    IconButton,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Button,
    Typography
} from '@mui/material';
import { ArrowRightIcon } from "@mui/x-date-pickers";
import CancelIcon from '@mui/icons-material/Cancel';
import { FilterClearButton } from "../FilterClearButton";
export const LDMNumberFilter = ({labelName, onFilter, onFilterYear}) => {


    const getYear = new Date().getFullYear();

    const [filterLDMYear, setFilterLDMYear] = useState(getYear);
    const handleLDMYearInc = (i) => () => {
        const setYear = Math.min(Math.max(2025, filterLDMYear + i), getYear);
        setFilterLDMYear(setYear);
        onFilterYear(setYear);
    };
    useEffect(() => {
        onFilterYear(getYear);
    }, [])

    const [selectedFilterVal, setselectedFilterVal] = useState('');


    const handleFieldChange = (e) => {
        setselectedFilterVal(e.target.value);
        onFilter(e.target.value);
    }



    return (<div className='CustomFilterContainer'>
        <FormControl sx={{ background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 36%) 60%)',
             width: '230px', minWidth: '230px', border: '1px solid black', height: '100%',
             '& .MuiInputBase-root':{
                        height:'100%',
                        padding: '0px',
                        paddingLeft: '0.5rem',
                    },
                    '& .MuiInputBase-input': {
                    height:'100%',
                    padding: '0',
                    paddingBottom: '0.05rem'
                }

             }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-filterref">{`${labelName}`}</InputLabel>
            <OutlinedInput
                type={'number'}
                placeholder={`TUR${filterLDMYear}-###`}
                value={selectedFilterVal}
                onChange={handleFieldChange}
                startAdornment={<InputAdornment sx={{ p:0, m:0 }} position="start">
                    <IconButton
                        sx={{ transform: 'rotate(180deg)', paddingInline: '0px 0.5rem' }}
                        disableRipple
                        onClick={handleLDMYearInc(-1)}
                        edge="start"
                    >
                        <ArrowRightIcon />
                    </IconButton>
                    <IconButton
                        sx={{ paddingInline: '0px 0.75rem' }}
                        disableRipple
                        onClick={handleLDMYearInc(1)}
                        edge="end"
                    >
                        <ArrowRightIcon />
                    </IconButton>
                    {selectedFilterVal &&
                    <Typography sx={{ fontWeight: 'bold', color: 'darkgray',  m: 0 }}>
                        {`TUR${filterLDMYear}-`}
                    </Typography>}
                </InputAdornment>}
                label="RefNo" />
        </FormControl>
        {selectedFilterVal && <FilterClearButton
            onClick={() => {
                setselectedFilterVal("");
                onFilter(null);
            }}
            disableRipple
            sx={{ left: '180px' }}>
           <CancelIcon />
            </FilterClearButton>}
    </div>);
}
