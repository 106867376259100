import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import numeral from 'numeral';
import { userTypesRBK } from '../../../../../data/userTypes';


import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TimestampConverter } from '../functions/TimestampConverter';
dayjs.extend(relativeTime)



const logTypeMap = {
    patientInfo: 'Updated Patient Info',
    addHospital: 'Sent to New Hospital',
    removeHospital: 'Cancelled Hospital',
    UpdatePatient: 'Updated Patient Info',
    UpdateGOP: 'Updated GOP',
    ReviewCase: 'Reviewed Case',
    NewMessage: 'Sent Message',
    NewPatient: 'Created Patient Case',
    RemovedCase: 'Removed Patient Case',
    RemovedHospital: 'Removed Hospital',
    UploadDocuments: 'Uploaded Documents',
    UploadProforma: 'Uploaded Proforma',
    UploadInvoice: 'Uploaded Invoice',
    DeleteInvoice: 'Deleted Invoice',
    DeleteProforma: 'Deleted Proforma',
    DeleteDocuments: 'Deleted Documents',
    DeleteGOP: 'Deleted GOP',
    IncomingMailAttachment: 'Sent Mail Attachment',
}

const logTypeString = ( log ) => {
    switch (log?.logType) {
        case 'UpdateGOP':
            if (!!log?.GOP_State) {
                switch (log?.GOP_State) {
                    case 1:
                        return 'GOP Approved';
                        break;
                    case 0:
                        return 'GOP Pending';
                        break;
                    case -1:
                        return 'GOP Rejected';
                        break;
                    default:
                        return null;
                        break;
                }
            } else if(!isNaN(log?.GOPPriceUSD)){
                const parsedUSD = numeral(log?.GOPPriceUSD).format('0,0');
                const parsedTL = numeral(log?.GOPPriceTL/100).format('0,0.00');
                return `Updated GOP to ${parsedUSD} $ / ${parsedTL} ₺`;
            }
        default:
            return logTypeMap[log?.logType];
    }
}


export const ActivityLogItem = ({ log, index, hospitalLabel, mainActivityLog,
    setformOpen = ()=>{},
    setselectedRowCaseNo = () => { },
    setSelectedTab = () => { },
    setmodalPosition = () => { },
    setFormCreateMode = ()=>{},
    setselectedhospCaseUserID = ()=>{},
    setselectedhospCaseHospID = ()=>{},
    disableClick = false,
    setPaymentMode = ()=>{},
}) => {


   const [currentTime, setCurrentTime] = useState(Date.now());

   const diff =  new Date(Date.now()) - new Date(log.timestamp);
   const updateInterval = (diff < 86400000) ? 60000 : 60000*61;

   useEffect(() => {

    const interval = setInterval(() => {
        setCurrentTime(Date.now());
    }, updateInterval); // Update every minute

    return () => clearInterval(interval);
}, []);
    const handleItemClick =  () => {
        if (log.logType === 'RemovedCase' || disableClick) { return }

       // if (singlePatientQuery.isLoading || singlePatientQuery.isError || !singlePatientQuery?.data?.data?.patientList[0]) { return }

        if (log.logType === 'ReviewCase'){
            setPaymentMode(true);
        }


         setSelectedTab("PatientCaseInformation");

         setselectedRowCaseNo(log.caseNumber);
         setselectedhospCaseUserID(log?.hospital);
         setselectedhospCaseHospID(log?.hospID);


        setformOpen(true);
        setTimeout(() => {
            setmodalPosition('0%')
        }, 15);
        setFormCreateMode(false);

    };



    return (<div key={`ActLogItem-${index}`} onClick={handleItemClick} className={`ActivityLogItemContainer ${userTypesRBK.includes(log.logUserType) ? `ActivityLogItemContainerOperator` : `ActivityLogItemContainerClient`}`}>
        <Typography sx={{ userSelect:'none',position: 'absolute', bottom: 4, right: 8, fontSize: '0.8rem', color: 'lightgray' }}>
            {`${TimestampConverter(log.timestamp)}`}
        </Typography>
        <div style={{ userSelect:'none',display: 'flex', flexDirection: 'row', gap: '0.2rem' }}>
            <Typography sx={{userSelect:'none', fontWeight: 'bold' }}>
                {`${log.logUser}:`}
            </Typography>
            {logTypeMap[log.logType] && <Typography sx={{userSelect:'none'}}>
                {`${logTypeString(log)}`}
            </Typography>}
        </div>
        {hospitalLabel && <Typography sx={{userSelect:'none', fontSize: '0.95rem' }}>
            {`${hospitalLabel}`}
        </Typography>}

        {
            mainActivityLog ? <Typography sx={{ userSelect:'none',fontSize: '0.95rem', fontWeight:'bold' }}>
                {`${log.RBKCaseNo}`}
            </Typography> : null
        }

    </div>);
}
