import React, { useState } from 'react';

import { TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { FilterClearButton } from './FilterClearButton';


const TextFilter = ({ onFilter, labelName, props, dense, placeholderTemplate = '', numberInput = false }) => {
    const [selectedFilterVal, setselectedFilterVal] = useState('');

    const handleFieldChange = (e) => {
        setselectedFilterVal(e.target.value);
        onFilter(e.target.value);
    };

    return (
        <div className='CustomFilterContainer' >
            <TextField
            type={numberInput ? 'number' : 'text'}
            InputLabelProps={{
                shrink: true,
              }}
                label={labelName}
                value={selectedFilterVal}
                onChange={handleFieldChange}
                placeholder={placeholderTemplate}
                sx={ {width: '200px', height:'100%',
                    paddingTop:(dense === true)?'0.2rem':'0px',
                    '& .MuiInputBase-root':{
                        height:'100%',
                        padding: '0px'
                    },
                    '& .MuiInputBase-input': {
                    height:'100%',
                     padding: '0px',
                    padding: (dense === true)?  '0.8rem 1.25rem 0.5rem 1.25rem': '1rem',
                },
                '&:hover': {
                    border: '1px solid rgba(205, 229, 254, 0.5)',
                    outline: 'none'
                  },
                  '&:focus-within': {
                    border: '1px solid rgba(205, 229, 254, 0.5)',
                    outline: 'none'
                  },


            } }
            />

            {selectedFilterVal && <FilterClearButton
            onClick={() => {
                setselectedFilterVal("");
                onFilter(null);
            }}
            disableRipple
           >
           <CancelIcon />
            </FilterClearButton>}
        </div>
    );
};

export default TextFilter;
