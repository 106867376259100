import React from 'react';
import { styled } from '@mui/material/styles';
import { Tab } from '@mui/material';

export const FormTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 'bold !important',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },

    marginRight: theme.spacing(1),
    color: 'white',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#40a9ff',
        opacity: 1,
    },
    '&.Mui-selected': {
        fontWeight: 'bold !important',
        color: 'rgb(3, 140, 253)',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));
