import React, { useState } from 'react';

import {
    Button,
    Typography,
    IconButton,
    Autocomplete,
    TextField
} from '@mui/material';

import DateFilter from '../DateFilter';
import TextFilter from '../TextFilter';

import TextLanguage from '../../../data/TextLanguage.json';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useProcessCaseMutation } from '../../../hooks/useProcessCaseMutation';
import { useCompletePaymentListMutation } from '../../Payment/hooks/useCompletePaymentListMutation';

import { PaymentListHeader } from './PaymentListHeader';

import { useUploadExcelMutation } from '../../../hooks/useUploadExcelMutation';
import { FilterSelector } from './FilterSelector';
import { useQueryClient } from '@tanstack/react-query';
import { fileUploader } from '../../../functions/fileUploader';
import { useSearchParams } from 'react-router-dom';
import { InlineGraph } from './InlineGraph';

// import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { getReducedHospList } from '../../../functions/getReducedHospList';
import { LDMNumberFilter } from './LDMNumberFilter';
import { normalizeString } from '../../PatientFormContainer/pages/PatientInfoPage/functions/normalizeString';
import { matchSorter } from 'match-sorter';

// const payrollTypeList = [
//     { value: 0, label: 'Type A' },
//     { value: 1, label: 'Type B' },
// ]

export const exrColor = '#f4452a';
export const usdColor = '#9cf42a';
export const countColor = '#ffffff';


const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '6px !important',
    padding: '10px 16px !important',
    border: `1px solid ${theme.palette.dark[0]}`,
    background: theme.palette.gradient.a,
    color: 'white',
    height: 'auto',
    '&:hover': {
        border: `1px solid ${theme.palette.dark[1]}`,
        boxShadow: `inset 0px 0px 12px -2px ${theme.palette.buttonGlow[3]}`,
        background: theme.palette.gradient.a,
        outline: 'none',

        filter: 'brightness(1.25) contrast(1.1)',
    },

}));



export const DataTablePaymentHeader = ({
    setFilterToggle, filterToggle, lang, setSearchFilter, searchFilter, selectedRows,
    setActivePaymentList, setsnackbarStatus,
    ActivePaymentQuery, activePaymentState,
    parsedPaymentListHospQuery,
    archivePaymentListQuery,
    setPaymentListModalOpen, setmodalPosition,




    selectedHospital,
    setselectedHospital,

    opMode

}) => {

    // const theme = useTheme();

    const queryClient = useQueryClient();
    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const currentTab = parseInt(searchParams.get('currentTab')) || 0;

    // const currentPage = 'payment';
    const ProcessMutation = useProcessCaseMutation({ setsnackbarStatus, setActivePaymentList });
    const CompleteListMutation = useCompletePaymentListMutation({ setsnackbarStatus, setActivePaymentList, setPaymentListModalOpen });

    const handleCommitProcessed = async () => { // Move to processed after all files are uploaded to LDM
        const updaterData = selectedRows;
        ProcessMutation.mutate({ updaterData: updaterData, route: 'moveToProcessed' });
    }
    // eslint-disable-next-line
    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadExcelMutation = useUploadExcelMutation({ setsnackbarStatus, setUploadProgress });

    const handleCreateActivePaymentList = async () => { // Create Active Payment List for Hospitals
        setPaymentListModalOpen(true);
        setmodalPosition('0%');
        /*
        const updaterData = selectedRows;
        ProcessMutation.mutate({ updaterData: updaterData, route: 'createActivePaymentList' });
*/
    };

    const handleCompletePayment = () => { //  Finish Payments on Active Payment List => Archive current Active Payment List
        CompleteListMutation.mutate({});
    }


    const onDateFilter = (filter) => {
        setSearchFilter({ ...searchFilter, ...filter });
        console.log(searchFilter);
    };

    const onTextFilter = (field) => (filter) => {
        setSearchFilter({ ...searchFilter, ...{ [field]: filter } });
        console.log(searchFilter);
    }

    const filterOptions = (options, { inputValue }) => {
        const normalizedInput = normalizeString(inputValue?.toLowerCase());

        return matchSorter(options, normalizedInput, {
            keys: [option => normalizeString(option?.label?.toLowerCase())],
            base: 10,
            maxRanking: 10,
            threshold: matchSorter.rankings.CONTAINS
        })
    };


    /*
        const [payrollType, setPayrollType] = useState(payrollTypeList[0]?.value);
        const handlePayrollTypeSelect = (e) => {
            setPayrollType(e.target.value);
        }

    */

    const hospitalFilterList = getReducedHospList(hospitalUserListData?.data);

    return (<div className='datatableHeader' id='divDTHeader'>



        <div className='datatableHeaderFilterRowContainer'>



            {([0, 1].includes(currentTab)) && <IconButton onClick={() => { setFilterToggle(!filterToggle); }}> <FilterAltIcon fontSize='large' htmlColor='white' /></IconButton>}

            {([0, 1].includes(currentTab)) && <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignContent: 'center', alignItems: 'center' }}>
                {hospitalUserListData?.status === 200 &&

                    <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                            option?.value === value?.value
                        }
                        filterOptions={filterOptions}
                        noOptionsText={"No Matches"}
                        size={'small'}
                        selectOnFocus
                        clearOnBlur
                        value={hospitalFilterList?.find(option => option?.value === searchFilter?.hospID) || null}
                        options={!!hospitalFilterList ?
                            hospitalFilterList?.sort((a, b) => -b?.label?.localeCompare(a?.label))
                            :
                            [{ label: 'Loading...', value: null }]
                        }
                        onChange={
                            (e, value) => {
                                setSearchFilter({ ...searchFilter, hospID: value?.value });
                            }
                        }
                        getOptionLabel={(option) => `${option?.label}`}
                        renderInput={(params) => <TextField {...params} label={TextLanguage["FilterByHospital"][lang]} />}
                        sx={{
                            width: '100% !important', minWidth: '270px !important', maxWidth: '300px !important',
                            '& .MuiFormControl-root': { height: '45px !important', width: '100% !important' },
                            '& .MuiInputBase-root': { height: '100%' }
                        }}
                    />

                    // <FilterSelector
                    //     filterLabel={"hospID"}
                    //     selectLabel={TextLanguage["FilterByHospital"][lang]}
                    //     optionsList={hospitalFilterList}
                    //     setSearchFilter={setSearchFilter}
                    //     searchFilter={searchFilter}
                    // />


                }
                {InsuranceCompanyListData?.status === 200 && <FilterSelector
                    filterLabel={"InsCo"}
                    selectLabel={TextLanguage["FilterByInsurance"][lang]}
                    optionsList={InsuranceCompanyListData?.data}
                    setSearchFilter={setSearchFilter}
                    searchFilter={searchFilter}
                />}



            </div>}



            {currentTab === 0 && <StyledButton onClick={fileUploader(uploadExcelMutation, '.xlsx,.xls,.xlsm,.xlsb')}

                disableRipple={true}

            >
                <Typography>
                    {TextLanguage["Payroll"][lang]}
                </Typography>
                <FileUploadIcon htmlColor='white' sx={{ width: '25px', height: '25px' }} />
            </StyledButton>

            }



            {
                [2, 3, 4].includes(currentTab) &&
                <PaymentListHeader
                    lang={lang}
                    currentList={
                        currentTab === 2 ? ActivePaymentQuery?.data?.data
                            :
                            currentTab === 3 ? archivePaymentListQuery?.data?.data
                                :
                                parsedPaymentListHospQuery?.data?.data
                    }
                    selectedItem={
                        [2,3].includes(currentTab) ? selectedHospital : null
                    }
                    setSelectedItem={
                       [2,3].includes(currentTab) ? setselectedHospital : null
                    }

                    opMode={opMode}

                />
            }



            {currentTab === 0 && <div className="CommitButtonContainer">
                <StyledButton sx={{ borderRadius: 0 }} onClick={handleCommitProcessed} >
                    <Typography>
                        {TextLanguage["CommitAsProcessed"][lang]}
                    </Typography>
                </StyledButton>
            </div>}
            {currentTab === 1 && <div className="CommitButtonContainer">
                <StyledButton sx={{ borderRadius: 0 }} onClick={handleCreateActivePaymentList} disabled={(ActivePaymentQuery.isSuccess && ActivePaymentQuery?.data?.data?.paymentList?.length > 0)}>
                    <Typography>
                        {TextLanguage["CreatePaymentList"][lang]}
                    </Typography>
                </StyledButton>
            </div>}


            {currentTab === 2 && <div className="CommitButtonContainer">
                <StyledButton sx={{ borderRadius: 0 }} onClick={handleCompletePayment}
                    disabled={!activePaymentState}
                >
                    <Typography>
                        {TextLanguage["CompletePaymentList"][lang]}
                    </Typography>
                </StyledButton>
            </div>}


            {(currentTab === 4) && (parsedPaymentListHospQuery?.isSuccess) &&
                <InlineGraph
                    parsedPaymentListHospQuery={parsedPaymentListHospQuery}
                    lang={lang}

                />}







        </div>

        {(filterToggle) ? null : <div className='datatableHeaderFilterRowContainer' style={{ justifyContent: 'flex-start' }}>
            <TextFilter numberInput={true} labelName={`RBK ${TextLanguage["RefNo"][lang]}`} onFilter={onTextFilter('caseNumber')} placeholderTemplate={`RBK###`} />

            <LDMNumberFilter
                onFilter={onTextFilter('LDMCaseNoInt')}
                onFilterYear={onTextFilter('LDMCaseNoYear')}
                labelName={`LDM ${TextLanguage["RefNo"][lang]}`}
            />

            <TextFilter labelName={TextLanguage["FilterByName"][lang]} onFilter={onTextFilter('name')} />
            <DateFilter onFilter={onDateFilter} />

        </div>}
    </div>)
}





