import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialogButton = styled(Button)(({ ...props }) => (({ theme }) => ({
    borderRadius: '6px !important',
    padding: '0.35rem 1.25rem !important',
    border: `1px solid ${theme.palette.dark[0]}`,
    background: theme.palette.gradient.a,
    color: 'white',
    height: 'auto',
    boxShadow: `0px 0px 8px 2px ${theme.palette.shadow[1]}`,
    '&:hover': {
        border: `1px solid ${theme.palette.dark[1]}`,
        boxShadow: `inset 0px 0px 12px -2px ${theme.palette.buttonGlow[3]}, 0px 0px 8px 2px ${theme.palette.shadow[1]}`,
        background: theme.palette.gradient.a,
        outline: 'none',
        filter: 'brightness(1.25) contrast(1.1)',
    },
    '&:active': { boxShadow: `none` }
})));
