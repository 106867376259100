
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

export const useUploadShortcutMutation = ({setsnackbarStatus, setUploadProgress}) => {

    const queryClient = useQueryClient();



    return useMutation({
        mutationFn: ({ formData, uploadingFileType, clickedRowCaseNo, selectedhospCaseHospID }) => {


            return axios.post(
                `${config.API_URL}/data/pushBlob?filetype=${uploadingFileType}&caseNumber=${clickedRowCaseNo}&selectedhospCaseHospID=${selectedhospCaseHospID}`,
                formData,
                {
                    withCredentials: true,
                    onUploadProgress: progressEvent => setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                }

            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'], refetchType: 'active'}, {cancelRefetch: false });
            queryClient.invalidateQueries({ queryKey: ['activityLog'], refetchType: 'active'}, {cancelRefetch: false });

            queryClient.invalidateQueries({ queryKey:
                ['fileTokens', { caseNumber: variables?.clickedRowCaseNo, selectedhospCaseHospID: variables?.selectedhospCaseHospID }]
                , refetchType: 'active'}, {cancelRefetch: false
            });

            setsnackbarStatus(
                {
                    open: true,
                    message: `Successfully Uploaded ${variables?.uploadingFileType === 'patientDoc' ? 'Documents' : 'Invoice'} for ${variables?.clickedRowCaseNo}`,
                    severity: 'success'

                }
            )
        },
        onError: (error, variables) => {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Uploading File for ${variables?.clickedRowCaseNo}`,
                    severity: 'error'

                }
            )
        }
    })






}