
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useActivateTwoFactorConfirmMutation = ({setsnackbarStatus, setShowQR}) => {

     const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ token }) => {


            return axios.post(`${config.API_URL}/operator/enable2FA`, { token }, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {
            setsnackbarStatus({ open: true, message: '2FA Activated', severity: 'success' });
            setShowQR(false);
            queryClient.invalidateQueries({ queryKey: ['userSettings'], refetchType: 'active'}, {cancelRefetch: false });
        },
        onError: (error, variables) => {
            setsnackbarStatus({ open: true, message: 'Invalid Code', severity: 'error' });

        }
    });
}
