import React, { useState, useEffect } from 'react';

import TextLanguage from '../../../../../data/TextLanguage.json';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';




import {
    Button,
    Typography,
    Tooltip
} from '@mui/material';

import { styled, useTheme } from '@mui/material/styles';

import { ActivityLog } from './ActivityLog';
import { useQueryClient } from '@tanstack/react-query';
import { PatientInfoList } from './PatientInfoList';
import { displayCaseNo } from '../../../../../functions/displayCaseNo';

import { AddHospitalDialog } from './AddHospitalDialog';


const SharpButton = styled(Button)({

    boxShadow: 'inset 0px 0px 12px 0px rgba(11, 11, 11,0.35)',
    outline: '1px solid rgba(11, 11, 11,0.25)',
    border: 'none',
    borderRadius: '0px !important',
    height: '38px',
    maxHeight: '100%',
    '&:hover': {
        boxShadow: 'inset 0px 0px 12px 0px rgba(11, 11, 11,0.05)',
        outline: 'none',
        border: 'none',
    },
    position: 'relative', // Bottom Border Shadow
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: "100%",
        height: '1px',
        backgroundColor: 'rgba(22, 22, 22,0.75)',
        borderRadius: '0px',
        boxShadow: '0px 0px 10px 6px rgba(22, 22, 22,0.25)',
    },
});

//   fileUploader(uploadMutation, '.pdf,.doc,.docx,image/*', { uploadingFileType, selectedhospCaseHospID, clickedRowCaseNo: selectedRow?.RBKCaseNo })


export const PatientInfoComponent = ({
    selectedRow, opMode, setformEditmodeDisplay, lang,
    selectedhospCaseUserID, setselectedhospCaseUserID,
    selectedhospCaseHospID,setselectedhospCaseHospID,
    setsnackbarStatus,
    onRowFileURLs, paymentMode, setRemoveFormOpen
}) => {

    const queryClient = useQueryClient();
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const [addHospOpen, setAddHospOpen] = useState(false);

    const handleRemoveHospSubmit = () => {
        setRemoveFormOpen({ state: true, selectedRow: selectedRow, selectedhospCaseUserID: selectedhospCaseUserID, selectedhospCaseHospID: selectedhospCaseHospID });
    };

    //const dense = useMediaQuery(theme.breakpoints.down('xl'));
    return (<div className='PatientInfo-container'>

        <div className='infoSection'>
            <div className="patientInfoHeader">
                <div className='patientInfoCaseTitle'>
                    <Typography style={{ fontSize: "1rem", color: 'white', fontWeight: 'bold', textWrap: 'nowrap' }}>
                        {displayCaseNo(selectedRow)}
                    </Typography>

                </div>


                {(opMode && !paymentMode) && <div className='hospitalAddRemButtonGroup'>

                    <Tooltip title={<Typography>{TextLanguage["EditPatient"][lang]}</Typography>} disableInteractive slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -14]}}]}}} arrow placement="right-start">
                        <span>
                            <SharpButton sx={{}} onClick={() => { setformEditmodeDisplay(false) }}>
                                <EditIcon />
                            </SharpButton>
                        </span>
                    </Tooltip>

                    <Tooltip title={<Typography>{TextLanguage["AddNewHospital"][lang]}</Typography>} disableInteractive slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -14]}}]}}} arrow placement="right-start">
                        <span>
                            <SharpButton sx={{}} onClick={() => { setAddHospOpen(true) }}>
                                <AddIcon sx={{ marginRight: '5px' }} />
                            </SharpButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={<Typography>{TextLanguage["RemoveSelectedHospital"][lang]}</Typography>} disableInteractive slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -14]}}]}}} arrow placement="right-start">
                        <span>

                            <SharpButton disabled={(selectedRow?.hospCases?.length === 1 || !selectedhospCaseUserID || !selectedhospCaseHospID)}
                                sx={{

                                    background: 'var(--color-error-dark)',
                                    '&:hover': { background: 'var(--color-error)' }
                                }}
                                onClick={handleRemoveHospSubmit}>
                                <RemoveIcon sx={{ marginRight: '5px' }} />

                            </SharpButton>
                        </span>
                    </Tooltip>

                    <AddHospitalDialog
                        hospitalUserListData={hospitalUserListData}
                        setformEditmodeDisplay={setformEditmodeDisplay}
                        setAddHospOpen={setAddHospOpen}
                        addHospOpen={addHospOpen}
                        selectedRow={selectedRow}
                        lang={lang}
                    />

                </div>}


            </div>

            {// Hospital Case Selector
                <div className='hospitalCaseSection'>
                    {selectedRow?.hospCases?.map((hospCase, index) => {
                        // const notSelected = (hospCase.userID === selectedhospCaseUserID) ? false : true;
                        const selected = (hospCase.hospID === selectedhospCaseHospID);
                        return (
                            <div key={`hospitalCaseSubSection-${index}`} style={{
                                padding: '0px 2px',
                            }}>
                                <Button className={`hospitalCaseSelection ${selected ? '':'hospitalCaseSelectionActive'}`}
                                    sx={{
                                        background: selected ? 'rgb(48, 120, 197)':'rgb(49,80,125)' ,
                                        color: selected ? 'white':'lightgray' ,
                                        '&:hover': {
                                            background: selected ?  'rgb(48, 120, 197)':'rgb(49,80,125)',
                                            filter: selected ? 'brightness(1.15)' : 'brightness(1.25) contrast(1.1)',

                                        },
                                        width: '100%', padding: '5px', borderRadius: '0px'
                                    }} onClick={() => {
                                        setselectedhospCaseUserID(hospCase.userID);
                                        setselectedhospCaseHospID(hospCase.hospID);
                                    }}
                                >
                                    {`${hospitalUserListData?.data.find(hosp => hospCase.hospID === hosp.hospID)?.hospitalName}`}
                                </Button>
                            </div>
                        );

                    })}
                </div>}

            <PatientInfoList
                lang={lang}
                selectedRow={selectedRow}
                selectedhospCaseUserID={selectedhospCaseUserID}
                selectedhospCaseHospID={selectedhospCaseHospID}
                onRowFileURLs={onRowFileURLs}
                opMode={opMode}
                setsnackbarStatus={setsnackbarStatus}
                paymentMode={paymentMode}
            />



            {opMode && <ActivityLog RBKCaseNo={selectedRow?.RBKCaseNo} mainActivityLog={false} disableClick={true} lang={lang} />}

        </div>
    </div>);




}











