
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";
import { useNavigate } from "react-router-dom";

export const useParsedPaymentListGroupQuery = ({opMode, enabled}) => {

    const navigate = useNavigate();

    async function fetchParsedPayment({ queryKey }) {
        const [_key, paymentID] = queryKey;

        return await axios.get(`${config.API_URL}/payment/PaymentPatientListInfo`, {
            withCredentials: true,
        })

    }



    return useQuery(
        {
            queryKey: ['ParsedPaymentListGroup'],
            enabled: opMode && enabled,
            queryFn: fetchParsedPayment,
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes

        }
    )





}


