import React from 'react';
import { Typography, Button } from '@mui/material';
import TextLanguage from '../../../data/TextLanguage.json';


import FileDownload from '@mui/icons-material/FileDownload';



export const invoiceButtonTemplate = (tokenList, lang) => (data, index) => {




    const invoiceFile = data?.hospCases[0]?.files?.find(file => file?.filetype === 'invoice');
    const userID = data?.hospCases[0]?.userID;

    const sasToken = tokenList?.find(hosp => userID === hosp.userID)?.sasToken;
    const fileURL = `${invoiceFile?.blobUrl}?${sasToken}`;

    return (<Button key={`downloadInvoiceButton-${data?.RBKCaseNo}-${index}`} href={fileURL} className='DocDownloadButton'
        disableRipple={true}
        sx={{
            width: '100%', height: 'auto', minWidth: '40px', minHeight: '25px', p: '0.25rem', m: '0px',
            borderRadius: '0px',
            background: 'var(--color-success-darkest)',
            border: '1px solid var(--color-light3)',
            boxShadow: 'none',
            '&:hover': {
                background: 'var(--color-success-dark)',
                boxShadow: 'none',
                outline: 'none',
                border: '1px solid var(--color-success-light)',
            }
        }}
    >
        <FileDownload htmlColor='white' sx={{ width: '25px', height: '25px' }} />
        <Typography>
            {TextLanguage['Download'][lang]}
        </Typography>
    </Button>)
};