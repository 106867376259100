
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useUpdateUserMutation = ({
   setsnackbarStatus
}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData, oldData, key, reset }) => {

            return axios.put(`${config.API_URL}/operator/updateUserInfo`, updaterData, {
                withCredentials: true,
            });

        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['userSettings'], refetchType: 'active'}, {cancelRefetch: false });
        },
        onError: (error, variables) => {
            console.log(variables);
            setsnackbarStatus({ open: true, message: 'There was an error during the update', severity: 'error' });

            variables.reset(variables.oldData[variables.key]);
                queryClient.setQueryData(['userSettings'], (prevData) => { // Reverse Optimistic Update
                    return { ...prevData, [variables.key]: variables.oldData[variables.key] };
                });

        }
    });
}
