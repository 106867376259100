import React from 'react';
import CustomListItem from '../../../components/CustomListItem';
import TextLanguage from '../../../../../data/TextLanguage.json';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Typography } from '@mui/material';
import { UploadedFilesComponent } from './UploadedFilesComponent';

import { parseDateString } from '../../../../../functions/parseDateString';

export const PatientInfoList = ({
    lang,
    selectedRow,
    selectedhospCaseUserID,
    selectedhospCaseHospID,
    onRowFileURLs,

    opMode,
    setsnackbarStatus,
    paymentMode
}) => {





    return <div className='InfoListContainer'>
        <CustomListItem key={`NameItem`} index={0} label={TextLanguage["Name"][lang]} value={selectedRow?.name} />
        <CustomListItem key={`DateOfBirthItem`} index={1} label={TextLanguage["DateOfBirth"][lang]} value={parseDateString(selectedRow?.dob)} />
        <CustomListItem key={`AdmissionDateItem`} index={2} label={TextLanguage["AdmissionDate"][lang]} value={parseDateString(selectedRow?.admissionDate)} />
        <CustomListItem key={`PhoneItem`} index={3} label={TextLanguage["Phone"][lang]} value={selectedRow?.mobilePhone} />
        <CustomListItem key={`InsuranceCompanyItem`} index={4} label={TextLanguage["InsuranceCompany"][lang]} value={selectedRow?.InsuranceCo} />
        <CustomListItem key={`PolicyNoItem`} index={5} label={TextLanguage["PolicyNo"][lang]} value={selectedRow?.policyNo} />
        <CustomListItem key={`FranchiseItem`} index={6} label={TextLanguage["Franchise"][lang]} value={selectedRow?.franchise} />
        <CustomListItem key={`NotesItem`} multiline={true} index={7} label={TextLanguage["Notes"][lang]} value={selectedRow?.notes} />
        <CustomListItem key={`ComplaintsItem`} multiline={true} index={8} label={TextLanguage["Complaints"][lang]} value={selectedRow?.complaints} />



        {(selectedhospCaseUserID && selectedhospCaseHospID) && <UploadedFilesComponent
            key={`${selectedhospCaseUserID}-${selectedhospCaseHospID}`}
            onRowFileURLs={onRowFileURLs}
            selectedhospCaseUserID={selectedhospCaseUserID}
            selectedhospCaseHospID={selectedhospCaseHospID}
            selectedRow={selectedRow}
            opMode={opMode}
            lang={lang}
            setsnackbarStatus={setsnackbarStatus}
            disabled={paymentMode}
            />}



    </div>;
}


/*
  {(selectedhospCaseUserID && !paymentMode && selectedRow?.hospCases?.find(hospCase => hospCase.hospID === selectedhospCaseHospID)?.flags) &&
            opMode ?


            <>
                {selectedRow?.hospCases?.find(hospCase => hospCase.hospID === selectedhospCaseHospID)?.flags?.RejectedProformabyOp &&
                    <div className='flagContainer'>
                        <WarningRoundedIcon sx={{ color: 'red', fontSize: '1.5rem', marginRight: '0.5rem' }} />
                        <Typography sx={{ color: 'yellow' }}>
                            {TextLanguage["ProformaError"][lang]}
                        </Typography>
                    </div>}

                {selectedRow?.hospCases?.find(hospCase => hospCase.hospID === selectedhospCaseHospID)?.flags?.RejectedGOPbyUser &&
                    <div className='flagContainer'>
                        <WarningRoundedIcon sx={{ color: 'red', fontSize: '1.5rem', marginRight: '0.5rem' }} />
                        <Typography sx={{ color: 'yellow' }}>
                            {TextLanguage["GOPError"][lang]}
                        </Typography>
                    </div>}
            </>



            :

            <>

                {selectedRow?.hospCases?.find(hospCase => hospCase.hospID === selectedhospCaseHospID)?.flags?.RejectedProformabyOp &&
                    <div className='flagContainer'>
                        <WarningRoundedIcon sx={{ color: 'red', fontSize: '1.5rem', marginRight: '0.5rem' }} />
                        <Typography sx={{ color: 'yellow' }}>
                            {TextLanguage["ProformaError"][lang]}
                        </Typography>
                    </div>}

                {(selectedRow?.hospCases?.find(hospCase => hospCase.hospID === selectedhospCaseHospID)?.flags?.RejectedGOPbyUser
                ||
                selectedRow?.hospCases?.find(hospCase => hospCase.hospID === selectedhospCaseHospID)?.GOP_State === -1) &&
                    <div className='flagContainer'>
                        <WarningRoundedIcon sx={{ color: 'red', fontSize: '1.5rem', marginRight: '0.5rem' }} />
                        <Typography sx={{ color: 'yellow' }}>
                            {TextLanguage["GOPError"][lang]}
                        </Typography>
                    </div>}



            </>}
*/