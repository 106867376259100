
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config.js';

export const useCompletePaymentListMutation = ({setsnackbarStatus, setActivePaymentList, setPaymentListModalOpen}) => {

    const queryClient = useQueryClient();

    return useMutation({

        mutationFn: ({}) => {


            return axios.post(
                `${config.API_URL}/payment/completeActivePaymentList`,
                {},

                {
                    withCredentials: true
                }

            );
        },
        onSuccess: (data, variables, context) => {

                setActivePaymentList([]);
                setPaymentListModalOpen(false);
                queryClient.invalidateQueries({ queryKey: ['ActivePaymentList'], refetchType: 'active'}, {cancelRefetch: false });
                queryClient.invalidateQueries({ queryKey: ['ActivePaymentListAggr'], refetchType: 'active'}, {cancelRefetch: false });
                queryClient.invalidateQueries({ queryKey: ['ArchivePaymentListGroup'], refetchType: 'active'}, {cancelRefetch: false });
                queryClient.invalidateQueries({ queryKey: ['ArchivePaymentList'], refetchType: 'active'}, {cancelRefetch: false });


            setsnackbarStatus(
                {
                    open: true,
                    message: `Upload to /completeActivePaymentList Successful`,
                    severity: 'success'
                }
            )

        },
        onError: (error, variables, context) => {
            console.log(error);


            if (error?.response?.status === 406) {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Payment List Already Active`,
                        severity: 'error'
                    }
                )
            } else {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Upload to /completeActivePaymentList Failed`,
                        severity: 'error'
                    }
                )
            }
        }
    })




}