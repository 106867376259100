import React from "react";
import { Typography } from '@mui/material';

export const formatHospitalUserIDPayments = (HospitalUserID) => (hospCaseRow) => {

    const label = HospitalUserID?.find(hosp => hosp.hospID === hospCaseRow.hospID)?.hospitalName
    ||
    HospitalUserID?.find(hosp => hosp.value === hospCaseRow.userID)?.label



    return (
        <div key={`hospCasePay-${hospCaseRow._id}`} style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '1rem' }}>
                {label}
            </Typography>
        </div>
    )
}