import React, { useState, useEffect } from 'react';
import TextLanguage from '../../../../../data/TextLanguage.json';
import {
    Box, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Autocomplete, TextField,
    Divider,
    Typography,
    IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useUpdatePatientMutation } from '../hooks/useUpdatePatientMutation';
import { getReducedHospList } from '../../../../../functions/getReducedHospList';
import { normalizeString } from '../functions/normalizeString';
import { matchSorter } from 'match-sorter';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDialogButton } from '../../../components/StyledDialogButton';



export const AddHospitalDialog = ({
    hospitalUserListData,
    setformEditmodeDisplay,
    setAddHospOpen,
    addHospOpen,
    selectedRow,
    lang,

}) => {


    const theme = useTheme();
    const hospitalFilterList = getReducedHospList(hospitalUserListData?.data);

    const updatePatientMutation = useUpdatePatientMutation({ setformEditmodeDisplay });


    const filterOptions = (options, { inputValue }) => {
        const normalizedInput = normalizeString(inputValue?.toLowerCase());

        return matchSorter(options, normalizedInput, {
            keys: [option => normalizeString(option?.hospitalName?.toLowerCase())],
            base: 10,
            maxRanking: 10,
            threshold: matchSorter.rankings.CONTAINS

        })
    };



    const [newHospID, setnewHospID] = useState('');
    const [newUserID, setnewUserID] = useState('');
    const [message, setMessage] = useState(selectedRow?.notes || '');




    const handleAddHospClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setAddHospOpen(false);
        }
    };


    const handleAddHospSubmit = (notify) => (e, reason) => {

        if (reason !== 'backdropClick') { setAddHospOpen(false) }
        e.preventDefault();


        const appendedData = { updateType: 'addHospital', newUserID: newUserID,  newHospID: newHospID, notify: notify, message: message };

        updatePatientMutation.mutate({ patientForm: selectedRow, appendedData: appendedData })

    };

    const handleHospitalSelection = (e, value) => {
        setnewUserID(value?.value);
        setnewHospID(value?.hospID);

    }




    return (<Dialog sx={{ background: 'rgba(0,0,0,0.25)' }}
        disableEscapeKeyDown
        open={addHospOpen}
        onClose={handleAddHospClose}
        PaperProps={{
            sx: {
                position: 'absolute',
                top: '10%',
                left: '10%',
                margin: 0,
                background: theme.palette.gradient.tableB,
            },
        }}
    >
         <IconButton
                    aria-label="close"
                    onClick={(e) => setAddHospOpen(false)}
                    fontSize="large"
                    sx={{
                        position: 'absolute',
                        right: '8px', top: '8px',
                        color: 'red',
                        zIndex: 1000,
                        '&:hover': { background: 'transparent' }
                    }}>
                    <CloseIcon />
                </IconButton>

        <DialogTitle sx={{
            fontWeight: 'bolder', fontSize: '1.35rem',
            pointerEvents: 'none', userSelect: 'none'
        }}>
            {TextLanguage["SelectNewHospital"][lang]}
        </DialogTitle>
        <DialogContent sx={{ background: 'transparent' }}>
            <Box component="form" sx={{
                display: 'flex', flexWrap: 'wrap',
                flexDirection: 'column', gap: '1rem',
                background: theme.palette.gradient.d,
                borderRadius: '8px', width: '100%',
                minWidth: '400px',
                padding: '1.5rem',
                border: '1px solid rgba(22, 22, 22,0.75)'
            }}>

                <Autocomplete
                    isOptionEqualToValue={(option, value) => option?.hospID === value?.hospID}
                    filterOptions={filterOptions}
                    noOptionsText={"No Matches"}
                    size={'small'}
                    selectOnFocus
                    clearOnBlur
                    value={hospitalUserListData?.data?.find(option => option?.hospID === newHospID) || null}
                    options={hospitalUserListData?.data || [{ label: 'Loading...', value: null, hospID: null }]}
                    onChange={handleHospitalSelection}
                    getOptionLabel={(option) => `${option?.hospitalName}`}
                    renderInput={(params) => <TextField {...params} label={TextLanguage["AddNewHospital"][lang]} />}
                    sx={{
                        width: '100% !important', minWidth: '270px !important',
                        '& .MuiFormControl-root': { height: '45px !important', width: '100% !important' },
                        '& .MuiInputBase-root': { height: '100%' }
                    }} />


                <Divider sx={{ width: '100%' }} />

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem', paddingInline: '0.5rem' }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '1.15rem', pointerEvents: 'none', userSelect: 'none' }}>
                                    {TextLanguage["RemoveCaseDialog"][lang]}
                                </Typography>
                                <MailIcon sx={{ color: 'lightblue',marginLeft:  'auto' }} />
                            </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem', padding: '0.5rem 1rem 1rem 0.5rem' }}>
                    <TextField
                        label={TextLanguage["MailContent"][lang]}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={5}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        sx={{ maxWidth: '500px' }} />
                </div>





            </Box>
        </DialogContent>
        <DialogActions sx={{ gap: '0.5rem', padding: '0 1rem 1rem 0' }}>
        <StyledDialogButton onClick={handleAddHospSubmit(false)}>  {TextLanguage["No"][lang]}</StyledDialogButton>
        <StyledDialogButton onClick={handleAddHospSubmit(true)}>{TextLanguage["Yes"][lang]}</StyledDialogButton>
        </DialogActions>
    </Dialog>);
};
