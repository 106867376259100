
import React, { useRef, useState, useEffect } from "react";
import TextLanguage from "../../data/TextLanguage.json";

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';


import {
    CircularProgress,
    useMediaQuery
} from '@mui/material';


import { useTheme } from '@mui/material/styles';

import { progressHeader } from "./components/progressHeader";
import { useUploadShortcutMutation } from "../../hooks/useUploadShortcutMutation";
import { useQueryClient } from "@tanstack/react-query";
import { usePatientsQuery } from "../../hooks/usePatientsQuery";
import { CompletionBar } from "./components/CompletionBar";
import { formatCaseNo } from "./ColumnFormats/formatCaseNo";
// import { formatDateAdm } from "./ColumnFormats/formatDateAdm";
import { formatDateUpload } from "./ColumnFormats/formatDateUpload";
import { formatInsuranceCompany } from "./ColumnFormats/formatInsuranceCompany";
import { formatLDMCaseNo } from "./ColumnFormats/formatLDMCaseNo";
// import { formatGOPPrice } from "./ColumnFormats/formatGOPPrice";
import { formatPatientName } from "./ColumnFormats/formatPatientName";
import { formatHospitalUserID } from "./ColumnFormats/formatHospitalUserID";

import { rowClass } from "./ColumnFormats/rowClass";
import { DataTableHeader } from "./components/DataTableHeader";
import { DataTableFooter } from "./components/DataTableFooter";
import { useSearchParams } from "react-router-dom";
import { formatGOPPriceUSD } from "./ColumnFormats/formatGOPPriceUSD";
import { formatGOPPriceTL } from "./ColumnFormats/formatGOPPriceTL";
import { userTypesRBK } from "../../data/userTypes";
import { formatGOPPriceEUR } from "./ColumnFormats/formatGOPPriceEUR";
import { StyledLoading } from "../UserSettings/StyledLoading";

export const FileShareTable = ({
    handleSort, contextMenuRef, rightselectedRow, setrightselectedRow,
    lang, setformOpen, formOpen, setFormCreateMode,
    setmodalPosition, setsnackbarStatus,
    setSelectedTab,
    viewportWidth,
    setlang,
    setselectedhospCaseUserID,
    setselectedhospCaseHospID,
    setselectedRowCaseNo,
    selectedRowCaseNo,
    searchFilter, setSearchFilter,
    setPaymentMode

}) => {


    const queryClient = useQueryClient();

    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);
    const validationData = queryClient.getQueryData(['validate']);

    const confirmedUserID = validationData?.data?.userID;
    const opMode = userTypesRBK.includes(confirmedUserID);
    const OperatorMode = ['Operator'].includes(confirmedUserID);
    const ExpertisePaymentMode = ['ExpertisePayment'].includes(confirmedUserID);
    const showHospitalColumn = (opMode || (!opMode && hospitalUserListData?.data?.some(hosp => hosp?.multipleHospital !== 'false'))) && (viewportWidth > 1560);

    const datatableRef = useRef(null);

    // useMediaQuery

    const theme = useTheme();
    const denseProgressHeader = useMediaQuery(theme.breakpoints.down('xl'));

    const [searchParams, setSearchParams] = useSearchParams({ page: 0, pageSize: 50 });

    const page = parseInt(searchParams.get('page')) || 0;
    const pageSize = parseInt(searchParams.get('pageSize')) || 50;

    const sortField = searchParams.get('sortField') || '';
    const sortOrder = searchParams.get('sortOrder') || 0;

    useEffect(() => {
        // eslint-disable-next-line
        setSearchParams(prev => {
            prev.set('page', 0);
            return prev;
        }, { replace: true });
        // eslint-disable-next-line
    }, [searchFilter, sortField, sortOrder]);

    const [filterToggle, setFilterToggle] = useState(true);

    useEffect(() => {

        if (viewportWidth < 1560 && !filterToggle) { // toggle off when window gets smaller than 1560px
            setFilterToggle(true);
        }
        // eslint-disable-next-line
    }, [viewportWidth])
    //const [patientList, setpatientList] = React.useState([]);
    //const [patientDashboard, setPatientDashboard] = React.useState([]);
    const patientsQuery = usePatientsQuery({
        page: page,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        searchFilter: searchFilter,
    })

    useEffect(() => {

        if (patientsQuery.isError && patientsQuery?.error?.response?.status !== 401) {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Patient Data`,
                    severity: 'error'
                }
            )

        }
        // eslint-disable-next-line
    }, [patientsQuery, patientsQuery.data, patientsQuery.isSuccess, patientsQuery.isError])

    const [uploadProgress, setUploadProgress] = useState(0);

    const uploadShortcutMutation = useUploadShortcutMutation({ setsnackbarStatus, setUploadProgress })

    const onRowSelect = (event) => {
        setSelectedTab("PatientCaseInformation");
        setPaymentMode(false);
        setselectedRowCaseNo(event.data?.caseNumber);
        if (event.data?.hospCases?.length === 1) {
            setselectedhospCaseUserID(event.data?.hospCases[0]?.userID);
            setselectedhospCaseHospID(event.data?.hospCases[0]?.hospID);
        } else if (event.data?.hospCases?.length > 1) {
            setselectedhospCaseUserID(null);
            setselectedhospCaseHospID(null);
        }

        setformOpen(true);
        setTimeout(() => {
            setmodalPosition('0%')
        }, 15);

        setFormCreateMode(false);


    };


    const onRowUnselect = (event) => {

        setselectedRowCaseNo(null);

    };

    const handleformOpen = () => {
        setformOpen(true);

        setTimeout(() => {
            setmodalPosition('0%')
        }, 15);

        setSelectedTab("PatientCaseInformation");

        setFormCreateMode(true);


    }


    const documentProgressBodyTemplate = (data) => {
        const hospCaseAmount = data.hospCases?.length;
        return (<>
            {data.hospCases.map((hospCase, index) => {
                /* Old progress with proforma creator
                                const docProgress = [
                                    hospCase?.files?.some(file => file?.filetype === "patientDoc") ?
                                        hospCase?.flags?.MissingDocs ? 2 : 1 : 0,

                                    hospCase?.Proforma_Date ? hospCase?.flags?.RejectedProformabyOp ? 2 : 1 : 0,

                                    hospCase?.GOP_Date ? hospCase?.flags?.RejectedGOPbyUser ? 2 : 1 : 0,

                                    hospCase?.files?.some(file => file?.filetype === "invoice") ?
                                        hospCase?.flags?.RejectedInvoicebyOp ? 2 : 1
                                        :
                                        hospCase?.GOP_Date ? 0 : -1, // -1 = no invoice, no patient doc, then download button
                                ];
                */
                const docProgress = [
                    hospCase?.files?.some(file => file?.filetype === "patientDoc") ?
                        hospCase?.flags?.MissingDocs ? 2 : 1 : 0,

                    (hospCase?.GOP_State + 2) ? (hospCase?.GOP_State + 2) : 0,

                    hospCase?.files?.some(file => file?.filetype === "proforma") ? hospCase?.flags?.RejectedProformabyOp ? 2 : 1 : 0,

                    hospCase?.files?.some(file => file?.filetype === "invoice") ?
                        hospCase?.flags?.RejectedInvoicebyOp ? 2 : 1
                        :
                        hospCase?.GOP_Date ? 0 : -1, // -1 = no invoice, no patient doc, then upload button
                ];

                return <CompletionBar
                    key={`${data?.RBKCaseNo}-${index}`}
                    uploadShortcutMutation={uploadShortcutMutation}
                    docProgress={docProgress}
                    caseNumber={data.caseNumber}
                    index={index}
                    hospCaseAmount={hospCaseAmount}
                    barHospID={hospCase.hospID}
                />

            })}


        </>);
    };


    return (
        <>

            {
                (patientsQuery.isFetching || queryClient.isFetching > 0) &&

                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <StyledLoading />
                </div>

            }


            <DataTable
                dataKey="caseNumber"
                onSort={handleSort}
                sortField={sortField}
                sortOrder={sortOrder}
                onContextMenu={(e) => contextMenuRef.current.show(e.originalEvent)}
                contextMenuSelection={rightselectedRow}
                onContextMenuSelectionChange={(e) => setrightselectedRow(e.value)}
                id='divTable'
                ref={datatableRef}
                selection={{ caseNumber: selectedRowCaseNo }}
                selectionMode="single"
                onRowUnselect={onRowUnselect}
                metaKeySelection={false}
                onRowSelect={onRowSelect}
                stripedRows
                // showGridlines

                scrollable
                scrollHeight="flex"
                rowClassName={rowClass({ opMode, confirmedUserID })}
                resizableColumns
                header={
                    <DataTableHeader
                        viewportWidth={viewportWidth}
                        lang={lang}
                        opMode={opMode}
                        filterToggle={filterToggle}
                        setFilterToggle={setFilterToggle}
                        searchFilter={searchFilter}
                        setSearchFilter={setSearchFilter}
                        patientsQuery={patientsQuery}
                        handleSort={handleSort}
                    />
                }
                footer={
                    <DataTableFooter
                        patientList={patientsQuery?.data?.data?.patientList}
                        opMode={opMode}
                        handleformOpen={handleformOpen}
                        lang={lang}
                        setSearchFilter={setSearchFilter}
                        searchFilter={searchFilter}
                        uploadShortcutMutation={uploadShortcutMutation}
                        uploadProgress={uploadProgress}
                        setUploadProgress={setUploadProgress}
                        setlang={setlang}
                        patientDashboard={patientsQuery?.data?.data?.dashboard}
                        setsnackbarStatus={setsnackbarStatus}
                        viewportWidth={viewportWidth}
                    />
                }

                size="small"

                className="DataGrid"
                value={patientsQuery?.data?.data?.patientList}
                style={{ width: '100%' }}
                tableStyle={{ width: '100%' }}
            >
                {/* <Column field="caseNumber" header={opMode ? `RBK ${TextLanguage["RefNo"][lang]}` : TextLanguage["RefNo"][lang]} headerStyle={{ width: '1%', minWidth: '90px' }}
                    sortable={!(searchFilter?.FlagMessage || searchFilter?.fUpload)}
                    key={'caseNumber-Col'}
                    body={formatCaseNo({ confirmedUserID, opMode })} />
                {
                    <Column field="LDMCaseNo" header={`LDM ${TextLanguage["RefNo"][lang]}`} headerStyle={{ width: '1%', minWidth: '100px' }}
                        sortable={!(searchFilter?.FlagMessage || searchFilter?.fUpload)}
                        key={'LDMCaseNo-Col'}
                        body={formatLDMCaseNo} />} */}

                {ExpertisePaymentMode &&
                    <Column field="caseNumber" header={opMode ? `RBK ${TextLanguage["RefNo"][lang]}` : TextLanguage["RefNo"][lang]} headerStyle={{ width: '1%', minWidth: '90px' }}
                        sortable={!(searchFilter?.FlagMessage || searchFilter?.fUpload)}
                        key={'caseNumber-Col'}
                        body={formatCaseNo({ opMode })} />}


                {ExpertisePaymentMode &&
                    <Column field="LDMCaseNo" header={`LDM ${TextLanguage["RefNo"][lang]}`} headerStyle={{ width: '1%', minWidth: '100px' }}
                        sortable={!(searchFilter?.FlagMessage || searchFilter?.fUpload)}

                        key={'LDMCaseNo-Col'}
                        body={formatLDMCaseNo} />}
                {!ExpertisePaymentMode && <Column field="caseNumber" header={`${TextLanguage["RefNo"][lang]}`} headerStyle={{ width: '1%', minWidth: '100px' }}
                    sortable={!(searchFilter?.FlagMessage || searchFilter?.fUpload)}
                    key={'DisplayCaseNo-Col'}
                    body={formatCaseNo({ confirmedUserID, opMode })} />}

                <Column field="name" header={`${TextLanguage["PatientName"][lang]}`} headerStyle={{ width: '1%', minWidth: '100px' }}
                    key={'name-Col'}
                    body={formatPatientName} />
                {opMode && searchFilter?.fUpload &&
                    <Column field="latestUploadedAt" header={TextLanguage["LatestUpload"][lang]}
                        sortable={false}
                        headerStyle={{ width: '1%', minWidth: '80px' }}
                        key={'latestUploadedAt-Col'}
                        body={formatDateUpload} />
                }


                {(opMode ? !OperatorMode : !hospitalUserListData?.data[0]?.worksWithTL) && <Column field="GOPPriceUSD" header={`GOP (USD)`}
                    sortable={false}
                    headerStyle={{ width: '80px', minWidth: '80px' }}
                    key={'GOPPriceUSD-Col'}
                    body={formatGOPPriceUSD} />}


                {(opMode ? !OperatorMode : true) && <Column field="GOPPriceTL" header={`GOP (TL)`}
                    sortable={false}
                    headerStyle={{ width: '80px', minWidth: '80px' }}
                    key={'GOPPriceUSD-Col'}
                    body={formatGOPPriceTL} />}
                {(opMode ? !OperatorMode : !hospitalUserListData?.data[0]?.worksWithTL) && <Column field="GOPPriceEUR" header={`GOP (EUR)`}
                    sortable={false}
                    headerStyle={{ width: '80px', minWidth: '80px' }}
                    key={'GOPPriceEUR-Col'}
                    body={formatGOPPriceEUR} />}

                {showHospitalColumn && hospitalUserListData?.status === 200 &&
                    <Column field="userID" header={TextLanguage["HospitalClinic"][lang]} headerStyle={{ width: '11rem', minWidth: '120px' }}
                        key={'userID-Col'}
                        body={formatHospitalUserID(hospitalUserListData?.data)} />}

                {opMode && (viewportWidth > 1650) && InsuranceCompanyListData?.status === 200 &&
                    <Column field="InsuranceCo" header={TextLanguage["InsuranceCompany"][lang]} headerStyle={{ width: '11rem', minWidth: '120px' }}
                        key={'InsuranceCo-Col'}
                        body={formatInsuranceCompany(InsuranceCompanyListData?.data)} />}

                <Column
                    style={{ width: '20%', minWidth: '0px' }}
                    key={'empty-Col'}
                    body={() => { }}
                />
                <Column field="documentProgress" header={progressHeader({ lang, searchFilter, dense: denseProgressHeader })}
                    headerStyle={{ width: '40%', minWidth: '400px' }}
                    key={'documentProgress-Col'}
                    body={documentProgressBodyTemplate} />
            </DataTable>

        </>);
}





/*
<Column field="admissionDate" header={TextLanguage["AdmissionDate"][lang]}
                    sortable={!(searchFilter?.FlagMessage || searchFilter?.fUpload)}
                    headerStyle={{ width: '5rem', minWidth: '100px' }}
                    key={'admissionDate-Col'}
                    body={formatDateAdm} />
*/
