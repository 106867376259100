
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

export const useUploadInsDataConfirmMutation = ({ setsnackbarStatus, setParsedList }) => {

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {


            return axios.post(`${config.API_URL}/operator/insuranceData`, updaterData, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {
            //queryClient.invalidateQueries({ queryKey: ['ActivePaymentList'], refetchType: 'active'}, {cancelRefetch: false });
            setParsedList([]);
            setsnackbarStatus(
                {
                    open: true,
                    message: `Data Uploaded`,
                    severity: 'success'

                }
            )
        },
        onError: (error, variables) => {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Uploading Data`,
                    severity: 'error'

                }
            )
        },
    })
}

