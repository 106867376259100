import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

const useUpdatePatientMutation = ({setformEditmodeDisplay}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ patientForm, appendedData }) => {


            return axios.post(
                `${config.API_URL}/data/updatePatient`,
                {
                    ...patientForm,
                    ...appendedData
                },
                {
                    withCredentials: true
                }
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'] , refetchType: 'active'}, {cancelRefetch: false});
            queryClient.invalidateQueries({ queryKey: ['activityLog'] , refetchType: 'active'}, {cancelRefetch: false});
            setTimeout(() => { setformEditmodeDisplay(true); }, 350);
        }
    });
}

export { useUpdatePatientMutation };