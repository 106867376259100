import React, { useState } from 'react';
import { IconButton } from '@mui/material';
// import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InfoIcon from '@mui/icons-material/Info';
// import DarkMode from '@mui/icons-material/DarkMode';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { StyledTooltip } from '../StyledTooltip';
import TextLanguage from '../../data/TextLanguage.json';
const IconMap = [
    <SettingsIcon />,
    <HelpOutline />,
    <InfoIcon />,
    <NotificationsActiveIcon />
]

const TooltipMap = [
    'UserSettings',
    'UserOnboardingTour',
    'RecentActivity',
    'Notifications'
]


export const NavBarExample = ({ highlightButton = 4, lang }) => {

    const [buttonState, setButtonState] = useState(true);

    return (<div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
        height: '400px',
        background: 'var(--color-dark-tint)',
        padding: '1rem',
    }}>
        {IconMap.map((icon, index) => {

            let handleButton = () => { };
            if (index === highlightButton) {
                handleButton = () => {
                    setButtonState(!buttonState);
                };
            }

            return (<StyledTooltip key={`nav-bar-example-${index}`} title={TextLanguage[TooltipMap[index]][lang]} placement='right'>
                <IconButton
                    onClick={handleButton}
                    sx={{ color: index !== highlightButton ? 'var(--color-text)' : 'var(--color-warning-tertiary)' }}
                >
                    {icon}
                </IconButton>
            </StyledTooltip>);
        })}

    </div>);
};
