
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';


export const useDownloadPDFSplitQuery = ({ setsnackbarStatus, setSplitPDFURL , caseNumber, selectedhospCaseUserID, selectedhospCaseHospID, displayfileIndex, filetype , selectedPages }) => {


    async function fetchSplitFile({ queryKey }) {
        const [_key, QuerySelection] = queryKey;
        const { caseNumber ,selectedPages, selectedhospCaseUserID, displayfileIndex, filetype } = QuerySelection;

        const queryString = new URLSearchParams();
        queryString.set('selectedPages', selectedPages);
        queryString.set('caseNumber', caseNumber);
        queryString.set('filetype', filetype);
        queryString.set('selectedhospCaseHospID', selectedhospCaseHospID);
        queryString.set('selectedhospCaseUserID', selectedhospCaseUserID);
        queryString.set('fileIndex', displayfileIndex);

        return await axios.get(
            `${config.API_URL}/pdf/splitPDF?${queryString.toString()}`,

            {
                withCredentials: true
            }
        );

    }



    return useQuery({
        queryKey: ['splitPDF',
            {
                selectedPages: selectedPages,
                caseNumber: caseNumber,
                filetype: filetype,
                selectedhospCaseUserID: selectedhospCaseUserID,
                selectedhospCaseHospID: selectedhospCaseHospID,
                displayfileIndex: displayfileIndex,

            }

        ],
        queryFn: fetchSplitFile,
        staleTime: Infinity,
        gcTime: 5000,
        refetchInterval: false,
        retry: 2,
        enabled: (!!selectedhospCaseUserID && !!selectedhospCaseHospID && selectedPages.length > 0),

    })






}