

import React, { useState, useReducer, useEffect } from 'react';


import { DocumentsPageComponent } from './pages/DocumentsPage/DocumentsPageComponent';
import { GOPPageComponent } from './pages/GOPPage/GOPPageComponent';
//import ProformaPageComponent from './pages/ProformaPage/ProformaPageComponent';
import { ReviewPageComponent } from './pages/ReviewPage/ReviewPageComponent';
import { PatientInfoPageComponent } from './pages/PatientInfoPage/PatientInfoPageComponent';

import { useUpdateFlagsMutation } from './hooks/useUpdateFlagsMutation';
import { useFileFetchQuery } from './hooks/useFileFetchQuery';
import { useFileTokensQuery } from './hooks/useFileTokensQuery';
//import { useMailFetchQuery } from './hooks/useMailFetchQuery';


import { FormTabSelector } from './components/FormTabSelector';
import { CommPageComponent } from './pages/CommPage/CommPageComponent';
import { usePatientsQuery } from '../../hooks/usePatientsQuery';
import LoadingSkeleton from './pages/LoadingSkeleton';
import { usePaymentListPatientQuery } from '../../hooks/usePaymentListPatientQuery';
import CreatePatientForm from './pages/PatientInfoPage/components/CreatePatientForm';

const currencies = [
    {
        value: '0$',
        label: '0 $',
    },
    {
        value: '0€',
        label: '0 €',
    },
    {
        value: '25$',
        label: '25 $',
    },
    {
        value: '30$',
        label: '30 $',
    },
    {
        value: '25€',
        label: '25 €',
    },
    {
        value: '30€',
        label: '30 €',
    },
];





const initialState = {
    RBKCaseNo: '',
    userID: '',
    userIDInsert: '',
    name: '',
    admissionDate: '',
    dob: '',
    mobilePhone: '',
    hotelRoom: '',
    hotel: '',
    policyNo: '',
    InsuranceCo: '',
    complaints: '',
    notes: '',
    franchise: '30$',
    InsurancePeriodStart: '', // (DD.MM-DD.MM)YYYY
    InsurancePeriodEnd: '',

    // Missing DATA ------

    // These have to come in from the insurance db

    // These have to come in at the final step after invoice is submitted and the case if processed
    diagnosis: '', //
    caseType: '', // outpatient ,outpatient (in clinic), inpatient
    insuranceBill: '', // = GOPPriceUSD + expertise input



    // Hotel Location - import Hotel list from db? for all users or operator only? who is creating the files?
    // Hospital/Clinic - tied to accounts => hosp list on db
    // Home Address - redundant?
    // TourOperator - pegas etc.
    // DateExamination - DD.MM.YYYY
    // Blood Pressure - Pulse?
    // Diagnosis
    // Treatment




};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };

        case 'REPLACE':

            return {
                ...action.tempval
            };
        case 'RESET':
            return {
                ...initialState
            };
        default:
            return state;
    }
}


export function PatientFormContainer({
    lang,
    opMode,
    selectedhospCaseUserID,
    selectedhospCaseHospID,
    setselectedhospCaseUserID,
    setselectedhospCaseHospID,
    setsnackbarStatus,
    formCreateMode,
    setformOpen,
    selectedRowCaseNo,
    selectedTab,
    setSelectedTab,
    paymentMode = false,
    handleModalFormClose,
    setRemoveFormOpen,
    viewportWidth
}) {

    const singlePatientQuery = usePatientsQuery({ enabled: !paymentMode, page: 0, pageSize: 1, sortField: null, sortOrder: null, searchFilter: { caseNumber: selectedRowCaseNo } })

    const singlePaymentPatientQuery = usePaymentListPatientQuery({ enabled: (paymentMode === true), caseNumber: selectedRowCaseNo, selectedhospCaseHospID: selectedhospCaseHospID });

    const selectedPatient = (paymentMode ? singlePaymentPatientQuery?.data?.data?.patientList[0] : singlePatientQuery?.data?.data?.patientList[0]);

    const [fileDisplayPdfUrl, setfileDisplayPdfUrl] = useState(null);
    const [displayfileIndex, setdisplayfileIndex] = useState(0);
    // const [onRowFileURLs, setonRowFileURLs] = useState(null);

    const [patientForm, dispatch] = useReducer(reducer, initialState);


    /*
        const handleKeyDown = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        };
    */
    const handleFieldChange = (fieldName) => (e) => {
        dispatch({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });
    };


    const fileTokensQuery = useFileTokensQuery({ selectedRowCaseNo, selectedhospCaseHospID });

    const onRowFileURLs = fileTokensQuery?.data?.data || null;





    useEffect(() => { // when selectedRow changes, update patientForm and ProformaList Hooks, If in userMode, strip the other hospitals case info

        if (!selectedPatient) { return }
        if (selectedPatient?.hospCases?.length === 1 && !paymentMode && singlePatientQuery.isSuccess) {
            if (opMode) {
                setselectedhospCaseUserID(selectedPatient?.hospCases[0]?.userID);
                setselectedhospCaseHospID(selectedPatient?.hospCases[0]?.hospID);
            } else {
                setselectedhospCaseHospID(selectedPatient?.hospCases[0]?.hospID);
            }

        }


    }, [selectedhospCaseUserID, selectedRowCaseNo,
        singlePatientQuery, singlePatientQuery?.data?.data, singlePatientQuery.isSuccess,
        selectedPatient])

    useEffect(() => {
        setdisplayfileIndex(0);
    }, [selectedTab])


    const [fetchedFileType, setfetchedFileType] = useState('all');

    const fileFetchQuery = useFileFetchQuery({
        selectedRowCaseNo,
        selectedhospCaseUserID,
        selectedhospCaseHospID,
        fetchedFileType,
        displayfileIndex,
        enabled: (!!selectedhospCaseUserID && !!selectedhospCaseHospID && onRowFileURLs?.length > 0)
    })

    const [maxPageCount, setMaxPageCount] = useState(0);

    useEffect(() => {
        if (fileFetchQuery.isSuccess) {

            // let contentType = 'application/pdf'
            const contentType = fileFetchQuery.data.headers['content-type'];

            const blob = new Blob([fileFetchQuery.data.data], { type: contentType });

            // Use the response data (blob) to display or download on the frontend
            const blobUrl = window.URL.createObjectURL(blob);
            setfileDisplayPdfUrl(blobUrl);

            contentType === 'application/pdf' && setMaxPageCount(fileFetchQuery.data.headers['x-page-count']);
        }
        if (fileFetchQuery.isError) {
            fileFetchQuery?.error?.response?.status === 409 && setfileDisplayPdfUrl("Wrong_Type")

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Files`,
                    severity: 'error'
                }
            )
        }
    }, [fileFetchQuery.data, fileFetchQuery.isSuccess, fileFetchQuery.isError])


    useEffect(() => {

        if (selectedhospCaseUserID && selectedTab === "DocumentsPage" && (onRowFileURLs?.length > 0)) {
            setfetchedFileType("all");
        }
        if (selectedhospCaseUserID && selectedTab === "Proforma" && (onRowFileURLs?.filter(file => file.filetype === "patientDoc")?.length > 0)) {
            setfetchedFileType("patientDoc");
        }
        if (opMode && selectedhospCaseUserID && selectedTab === "ReviewPage" && (onRowFileURLs?.filter(file => file.filetype === "invoice")?.length > 0)) {
            setfetchedFileType("all");
        }


    }, [selectedhospCaseUserID, onRowFileURLs, selectedTab, displayfileIndex])

    const updateFlagsMutation = useUpdateFlagsMutation({ setsnackbarStatus });



    useEffect(() => {
        if (selectedhospCaseHospID && !!selectedPatient) {
            if (opMode) { updateFlagsMutation.mutate({ flag: "UnreadMessageOp", state: false, selectedRow: selectedPatient, selectedhospCaseHospID }) }
            else { updateFlagsMutation.mutate({ flag: "UnreadMessageUser", state: false, selectedRow: selectedPatient, selectedhospCaseHospID }) }
        }

    }, [selectedPatient, selectedhospCaseHospID, opMode])

    useEffect(() => {
        return () => {
            if (opMode && !paymentMode) {
                setselectedhospCaseUserID(null);
                setselectedhospCaseHospID(null);
            }
            window.URL.revokeObjectURL(fileDisplayPdfUrl);
        }
    }, [])


    const dataIsLoaded = paymentMode ? singlePaymentPatientQuery.isSuccess : singlePatientQuery.isSuccess;
    const displayCase = !formCreateMode && dataIsLoaded;

    // ((!paymentMode && singlePatientQuery.isSuccess) || (paymentMode && singlePaymentPatientQuery.isSuccess))

    return (
        <div className="form-container">

            <FormTabSelector
                hideTabs={formCreateMode}
                paymentMode={paymentMode}
                selectedhospCaseHospID={selectedhospCaseHospID}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                lang={lang}
                selectedRow={selectedPatient}
                hideFileTab={!!onRowFileURLs ? false : true}
                handleModalFormClose={handleModalFormClose}
            />

            {formCreateMode && <CreatePatientForm
                lang={lang}
                patientForm={patientForm}
                handleFieldChange={handleFieldChange}
                currencies={currencies}
                setformOpen={setformOpen}
                setsnackbarStatus={setsnackbarStatus}
                dispatch={dispatch}
            />}


            {!formCreateMode && (paymentMode ? singlePaymentPatientQuery.isLoading : singlePatientQuery.isLoading) && selectedTab === "PatientCaseInformation" && <LoadingSkeleton />}
            {displayCase && selectedTab === "PatientCaseInformation" && (
                <PatientInfoPageComponent
                    selectedRow={selectedPatient}
                    opMode={opMode}
                    lang={lang}
                    selectedhospCaseUserID={selectedhospCaseUserID}
                    selectedhospCaseHospID={selectedhospCaseHospID}
                    setselectedhospCaseUserID={setselectedhospCaseUserID}
                    setselectedhospCaseHospID={setselectedhospCaseHospID}
                    setsnackbarStatus={setsnackbarStatus}
                    patientForm={patientForm}
                    handleFieldChange={handleFieldChange}
                    onRowFileURLs={onRowFileURLs}
                    dispatch={dispatch}
                    paymentMode={paymentMode}
                    setRemoveFormOpen={setRemoveFormOpen}
                />
            )}



            {displayCase && selectedTab === "CommPage" && (
                <CommPageComponent
                    selectedRow={selectedPatient}
                    selectedhospCaseUserID={selectedhospCaseUserID}
                    selectedhospCaseHospID={selectedhospCaseHospID}
                    setsnackbarStatus={setsnackbarStatus}
                    opMode={opMode}
                    paymentMode={paymentMode}
                />
            )}
            {displayCase && selectedTab === "DocumentsPage" && !formCreateMode && (
                <DocumentsPageComponent
                    onRowFileURLs={onRowFileURLs}
                    opMode={opMode}
                    selectedhospCaseUserID={selectedhospCaseUserID}
                    selectedhospCaseHospID={selectedhospCaseHospID}
                    displayfileIndex={displayfileIndex}
                    setdisplayfileIndex={setdisplayfileIndex}
                    updateFlagsMutation={updateFlagsMutation}
                    fileDisplayPdfUrl={fileDisplayPdfUrl}
                    setsnackbarStatus={setsnackbarStatus}
                    selectedRow={selectedPatient}
                    maxPageCount={maxPageCount}
                    fileFetchQuery={fileFetchQuery}
                    paymentMode={paymentMode}
                />
            )}
            {displayCase && selectedTab === "GOPDocumentPage" && !formCreateMode && (
                <GOPPageComponent
                    lang={lang}
                    opMode={opMode}
                    selectedhospCaseUserID={selectedhospCaseUserID}
                    selectedhospCaseHospID={selectedhospCaseHospID}
                    handleFieldChange={handleFieldChange}
                    patientForm={patientForm}
                    dispatch={dispatch}
                    selectedRow={selectedPatient}
                    setsnackbarStatus={setsnackbarStatus}
                    onRowFileURLs={onRowFileURLs}
                    displayfileIndex={displayfileIndex}
                    setdisplayfileIndex={setdisplayfileIndex}
                    updateFlagsMutation={updateFlagsMutation}
                    fileDisplayPdfUrl={fileDisplayPdfUrl}
                    fileFetchQuery={fileFetchQuery}
                    paymentMode={paymentMode}
                    viewportWidth={viewportWidth}
                />
            )}
            {opMode && !paymentMode && !formCreateMode && singlePatientQuery.isSuccess && selectedTab === "ReviewPage" && (
                <ReviewPageComponent
                    lang={lang}
                    setformOpen={setformOpen}
                    onRowFileURLs={onRowFileURLs}
                    selectedhospCaseUserID={selectedhospCaseUserID}
                    selectedhospCaseHospID={selectedhospCaseHospID}
                    displayfileIndex={displayfileIndex}
                    setdisplayfileIndex={setdisplayfileIndex}
                    fileDisplayPdfUrl={fileDisplayPdfUrl}
                    selectedRow={selectedPatient}
                    opMode={opMode}
                    setsnackbarStatus={setsnackbarStatus}
                    fileFetchQuery={fileFetchQuery}
                />
            )}


        </div>


    );
};









// useEffect(() => {
//     if (fileTokensQuery.isSuccess) {

//         if (fileTokensQuery.data.data.length === 0 || fileTokensQuery.data.data.includes("No Files Uploaded")) {
//             setonRowFileURLs(null);
//         } else {
//             setonRowFileURLs(fileTokensQuery.data.data);
//         }
//     }
//     if (fileTokensQuery.isError) {
//         setonRowFileURLs(null);

//         fileTokensQuery.error.response?.status !== 404 && setsnackbarStatus(
//             {
//                 open: true,
//                 message: `Error Fetching File Tokens`,
//                 severity: 'error'
//             }
//         )
//     }

// }, [fileTokensQuery.isSuccess, fileTokensQuery.isError, fileTokensQuery.data])
