import React from "react";
import { Typography } from '@mui/material';

export const formatHospitalUserID = (HospitalUserID) => (patientRow) => {


    if(patientRow?.hospCases?.length === 1) {
        const hospCase = patientRow?.hospCases[0];
        const label = HospitalUserID?.find((hosp) => hosp?.hospID === hospCase?.hospID) ?
        HospitalUserID?.find((hosp) => hosp?.hospID === hospCase?.hospID)?.hospitalName
        :
        HospitalUserID?.find((hosp) => hosp?.value === hospCase?.userID)?.label;
        return (
            <div key={`hospCase-ID:${patientRow?.caseNumber}`} style={{ fontWeight: 'bold' }} >
                {label}
            </div>
            )
    } else {
        return (
            patientRow?.hospCases?.map(
                (hospCase, index) => {
                    const label = HospitalUserID?.find((hosp) => hosp?.hospID === hospCase?.hospID) ?
                    HospitalUserID?.find((hosp) => hosp?.hospID === hospCase?.hospID)?.hospitalName
                    :
                    HospitalUserID?.find((hosp) => hosp?.value === hospCase?.userID)?.label;
                    return (
                        <div key={`hospCase-${index}-${patientRow?.caseNumber}`} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ color: index % 2 ? 'var(--color-text-secondary)' : 'var(--color-text)', fontWeight: 'bold', fontSize: '0.85rem' }}>{label}</Typography>
                        </div>
                    )

                }
            )
        )
    }


}



