import React from 'react';
import Skeleton from '@mui/material/Skeleton';


const LoadingSkeleton = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', gap: '4px',
         width: '100%', height: '100%' }}>

    <Skeleton animation='wave' variant="rectangular" width={450} height={73} />

            <Skeleton animation='wave' variant="rectangular" width={450} height={280} />



            <Skeleton animation='wave' variant="rectangular" width={450} height={140} />

            <Skeleton animation='wave' variant="rectangular" width={450} height={50} sx={{borderBottom:'2px solid var(--color-light2)'}} />

            <Skeleton animation='wave' variant="rectangular" width={450} height={100} />


        </div>
    );
};

export default LoadingSkeleton;