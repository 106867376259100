
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useFileTokensQuery = ({selectedRowCaseNo, selectedhospCaseHospID}) => {



    async function updateTokensFn({ queryKey }) {
        const [_key, QuerySelection] = queryKey;
        const { caseNumber , selectedhospCaseHospID } = QuerySelection;
        const queryString = new URLSearchParams();
        queryString.set('selectedhospCaseHospID', selectedhospCaseHospID);
        queryString.set('caseNumber', caseNumber);

        return await axios.get(`${config.API_URL}/data/fileTokens?${queryString.toString()}`, {
            withCredentials: true
        })

    }

    return  useQuery(
        {
            queryKey: ['fileTokens',
                {
                    caseNumber: selectedRowCaseNo ,
                    selectedhospCaseHospID: selectedhospCaseHospID
                },
            ],
            queryFn: updateTokensFn,
            enabled: (!!selectedhospCaseHospID && !!selectedRowCaseNo),
            retry: (failureCount, error) => {
                return (failureCount < 3 && error.response?.status !== 404)
             },

        }
    )




}