
import React, { useState, useEffect } from 'react';



import FileSelector from './FileSelector';
import CheckSwitch from '../pages/DocumentsPage/CheckSwitch';

import {
    Typography
} from '@mui/material';

import { PDFSplitterButton } from '../pages/DocumentsPage/PDFSplitterButton';
import { useQueryClient } from '@tanstack/react-query';

const FileSelectorHeader = ({
    switchMode = true,
    filetype = 'all',
    selectorEnabled = true,
    onRowFileURLs,
    displayfileIndex,
    setdisplayfileIndex,
    selectedhospCaseUserID,
    selectedhospCaseHospID,
    opMode,
    updateFlagsMutation = null,
    setsnackbarStatus,
    selectedRow,
    maxPageCount,
    fileFetchQuery,

}) => {

    const queryClient = useQueryClient();

    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const [FlagButtonState, setFlagButtonState] = useState(false);

    const currFiletype = onRowFileURLs ? onRowFileURLs[displayfileIndex]?.filetype : null;
    const currFlags = selectedRow?.hospCases?.find((hospCase) => hospCase.hospID === selectedhospCaseHospID)?.flags;

    useEffect(() => {

        switch (currFiletype) {
            case 'patientDoc':
                setFlagButtonState(currFlags?.MissingDocs)
                break;
            case 'proforma':
                setFlagButtonState(currFlags?.RejectedProformabyOp)
                break;
            case 'invoice':
                setFlagButtonState(currFlags?.RejectedInvoicebyOp)
                break;
            default:
                break;
        }


    }, [selectedRow, displayfileIndex, currFiletype])


    const handleDocsSwitchChange = (event) => {

        switch (currFiletype) {
            case 'patientDoc':
                setFlagButtonState(!event.target.checked);

                if (opMode && switchMode && selectedhospCaseHospID) {
                    !event.target.checked ?
                        updateFlagsMutation?.mutate({ flag: "MissingDocs", state: true, selectedRow, selectedhospCaseHospID })
                        :
                        updateFlagsMutation?.mutate({ flag: "MissingDocs", state: false, selectedRow, selectedhospCaseHospID })
                }
                break;
            case 'proforma':
                setFlagButtonState(!event.target.checked);

                if (opMode && switchMode && selectedhospCaseHospID) {
                    !event.target.checked ?
                        updateFlagsMutation?.mutate({ flag: "RejectedProformabyOp", state: true, selectedRow, selectedhospCaseHospID })
                        :
                        updateFlagsMutation?.mutate({ flag: "RejectedProformabyOp", state: false, selectedRow, selectedhospCaseHospID })
                }
                break;
            case 'invoice':
                setFlagButtonState(!event.target.checked);

                if (opMode && switchMode && selectedhospCaseHospID) {
                    !event.target.checked ?
                        updateFlagsMutation?.mutate({ flag: "RejectedInvoicebyOp", state: true, selectedRow, selectedhospCaseHospID })
                        :
                        updateFlagsMutation?.mutate({ flag: "RejectedInvoicebyOp", state: false, selectedRow, selectedhospCaseHospID })
                }
                break;

            default:
                break;
        }




    };




    return (<div className='DocsButtonControlsGroup'>

        {selectorEnabled && !!onRowFileURLs ?
            <FileSelector
                onRowFileURLs={onRowFileURLs}
                displayfileIndex={displayfileIndex}
                setdisplayfileIndex={setdisplayfileIndex}
                filetype={filetype}
                switchMode={switchMode}
                fileFetchQuery={fileFetchQuery}
            />
            : null
        }
        {hospitalUserListData?.status === 200 && <Typography variant='h4' sx={{ fontSize: '1.4rem', fontWeight: 'bold', color: 'white', marginInline: 'auto' }}>
            {hospitalUserListData?.data.find(hosp => selectedhospCaseHospID === hosp.hospID)?.hospitalName}
        </Typography>}



        {(opMode && switchMode ) ?
            <div className='DocsPageSwitchContainer'>
                { ['patientDoc','invoice', 'proforma'].includes(currFiletype) && <>
                    <Typography sx={{ color: FlagButtonState ? '#fc2c2c' : 'white' }}>
                        {`Alert`}
                    </Typography>
                    <CheckSwitch checked={!FlagButtonState} onChange={handleDocsSwitchChange} />
                    <Typography sx={{ color: FlagButtonState ? 'white' : '#95f995' }}>
                        {`Complete`}
                    </Typography>

                </>}

               {!!onRowFileURLs && <PDFSplitterButton
                    maxPageCount={maxPageCount}
                    filetype={filetype}
                    selectedhospCaseUserID={selectedhospCaseUserID}
                    selectedhospCaseHospID={selectedhospCaseHospID}
                    displayfileIndex={displayfileIndex}
                    setsnackbarStatus={setsnackbarStatus}
                    filename={onRowFileURLs[displayfileIndex].originalname}

                    caseNumber={selectedRow.caseNumber}

            />}
            </div>
            : null

        }


    </div>)



}



export default FileSelectorHeader;













