import { useMemo } from 'react';

export function usePaymentTableList({
    currentTab,
    patientList,
    activePaymentData,
    archivePaymentData,
    parsedPaymentData,
    selectedHospital,
}) {
    return useMemo(() => {
        let list = [];
        const patientColumnsCondition = [0, 1].includes(currentTab) || ( [2,3].includes(currentTab) && !!selectedHospital?.hospID );


        if (patientColumnsCondition) {
            switch (currentTab) {
                case 2:
                    list = patientList || [];
                    break;
                case 3:
                    list = patientList || [];
                    break;
                case 4:
                    list = patientList || [];
                    break;
                default:
                    list = patientList || [];
            }
        } else {
            switch (currentTab) {
                case 2:
                    list = activePaymentData?.paymentList || [];
                    break;
                case 3:
                    list = archivePaymentData?.paymentList || [];
                    break;
                case 4:
                    list = parsedPaymentData?.aggregateDateList || [];
                    break;
                default:
                    list = [];
            }
        }

        return list;
    }, [currentTab, patientList, activePaymentData, archivePaymentData, parsedPaymentData, selectedHospital, selectedHospital.hospID]);
}