import React from "react";
import { Typography } from '@mui/material';
import numeral from "numeral";

export const formatGOPPriceEUR = (patientRow) => {


    return (


        patientRow?.hospCases?.map(
            (hospCase, index) => {

                const parsedPrice = (hospCase?.GOPPriceEUR !== undefined && hospCase?.GOPPriceEUR !== 0) ? `${numeral(hospCase?.GOPPriceEUR || 0).format('0,0')} €` : '-'

                return (
                    <Typography key={`${index}-gopeur`} className='RowPriceText'>
                        {`${parsedPrice}`}
                    </Typography>
                )
            }
        )



    )
}
