
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";

export const usePaymentListPatientQuery = ({
    caseNumber, selectedhospCaseHospID, enabled=false
}) => {




    async function fetchPaymentListPatient({ queryKey }) {
        const [_key, QuerySelection] = queryKey;
        const { caseNumber, selectedhospCaseHospID } = QuerySelection;
        const queryString = new URLSearchParams();
        queryString.set('caseNumber', caseNumber);
        queryString.set('selectedhospCaseHospID', selectedhospCaseHospID);

        console.log(caseNumber, selectedhospCaseHospID, enabled)

        return await axios.get(
            `${config.API_URL}/payment/getPaymentListPatient?${queryString.toString()}`,
            { withCredentials: true }
        );

    }


    return useQuery(
        {
            queryKey: ['PaymentListPatient',

                {
                    caseNumber: caseNumber,
                    selectedhospCaseHospID: selectedhospCaseHospID
                }

            ],
            queryFn: fetchPaymentListPatient,
            enabled: (enabled),
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes
            refetchOnMount: 'always',
            refetchOnWindowFocus: true,
            retry: 1,



        }
    )




}


