import React from 'react';

import { PDFViewer } from '@react-pdf/renderer';

function PDFCreator({ selectedRow, DocComponent, hospCaseID, hospitalUserListData, selectedhospCaseHospID }) {


    return (
        <PDFViewer width={'100%'} height={'100%'} >
            <DocComponent selectedRow={selectedRow} hospCaseID={hospCaseID} hospitalUserListData={hospitalUserListData} selectedhospCaseHospID={selectedhospCaseHospID} />
        </PDFViewer>
    );
}

export default PDFCreator;
