import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

export const LoginInputField = styled(OutlinedInput)(({ theme }) => ({
    height: '100%',
    width: '100%',
    borderRadius: '8px 8px 0px 0px',
    color: 'white',
    boxShadow: 'inset 0px 0px 12px 0px rgba(0, 0, 0, 0.35)',
    background: 'transparent !important',

    '& .MuiFormControl-root:hover .MuiInput-underline::before': { borderBottom: 'none' },
    '& .MuiInput-underline:before': { border: 'none' },
    '& .MuiInput-underline:hover': { borderBottom: 'none', },
    '& label.Mui-focused': { color: '#A0AAB4', },
    '& .MuiInput-underline:after': { borderBottom: 'none', },
    '& fieldset': { border: '1px solid #ffffff1a' },
    '&:hover fieldset': { border: '1px solid #ffffff3b' },
    '&.Mui-focused fieldset': { border: '1px solid #ffffff3b' },
    '&.MuiInputBase-root': { padding: 0 },
    '& .MuiInputAdornment-root .MuiIconButton-root': { marginRight: '0.5rem' },
    '& input': {
        background: 'transparent',
        outline: 'none',
        border: 'none',
    },
    '& input:focus': {
        background: 'transparent',
        outline: 'none',
        border: 'none',
        boxShadow: 'inset 0 0 16px 2px rgba(0, 123, 255, 0.5)',
    }
}));
