export function getReducedHospList(hospList) {


   return (hospList?.filter((option) => (!option?.disabled && !!option?.value && !!option?.label))?.
   reduce((acc, hospital) => {

        const findIndex = acc?.findIndex((item) => item?.value === hospital?.value);
        if (findIndex === -1) {
            acc.push({ value: hospital?.value, label: hospital?.label });
        }
        return acc;
    },[]));
}