
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from '../../../config.js';
// import { useNavigate } from "react-router-dom";

export const useArchivePaymentListQuery = ({selectedPaymentID}) => {


    // const navigate = useNavigate();

    async function fetchArchivePayment({ queryKey }) {
        const [_key, paymentID] = queryKey;

        return await axios.get(`${config.API_URL}/payment/archivedPaymentList?paymentID=${paymentID}`, {
            withCredentials: true,
        })

    }



    return useQuery(
        {
            queryKey: ['ArchivePaymentList',  selectedPaymentID],
            enabled: selectedPaymentID !== null,
            queryFn: fetchArchivePayment,
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes

        }
    )





}


