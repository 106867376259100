import React, { useRef, useState } from 'react';
import TextLanguage from '../../../../../data/TextLanguage.json';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import UploadIcon from '@mui/icons-material/Upload';
import { BorderLinearProgress } from './BorderLinearProgress';
import { ContextMenu } from 'primereact/contextmenu';
import {
    Button,
    Typography,
    useTheme,

} from '@mui/material';
import { useUpdateFiletypeMutation } from '../hooks/useUpdateFiletypeMutation';
import { TransparentButton } from '../../../../TransparentButton';
import { useUploadMutation } from '../hooks/useUploadMutation';
import { fileUploader } from '../../../../../functions/fileUploader';
import uploadTypes from '../../../../../data/uploadTypes';
import { useDeleteFileMutation } from '../hooks/useDeleteFileMutation';
import { TimestampConverter } from '../functions/TimestampConverter';


const fileTypes = ['patientDoc', 'proforma', 'invoice', 'mailAttachment']; // 'GOP',
const flagDisplayNames = {
    patientDoc: 'MissingDocuments',
    proforma: 'ProformaError',
    invoice: 'InvoiceError',
    GOP: 'GOPError',

};
const flagNames = {
    patientDoc: 'MissingDocs',
    proforma: 'RejectedProformabyOp',
    invoice: 'RejectedInvoicebyOp',
    GOP: 'RejectedGOPbyUser',

};
const fileDisplayNames = {
    patientDoc: 'Documents',
    proforma: 'Proforma',
    invoice: 'Invoice',
    GOP: 'GOP',
    mailAttachment: 'MailAttachment',
};


/* Flags
                UnreadMessageOp: false,
                UnreadMessageUser: false,
                RejectedProformabyOp: false,
                RejectedGOPbyUser: false,
                RejectedInvoicebyOp: false,
                MissingDocs: false,
*/
export const UploadedFilesComponent = ({
    onRowFileURLs,
    selectedhospCaseUserID,
    selectedhospCaseHospID,
    selectedRow,
    opMode,
    lang,
    setsnackbarStatus,

    disabled = false

}) => {



    const [uploadFormProgress, setUploadFormProgress] = useState(0);
    const [uploadingFileType, setUploadingFileType] = useState(null);

    const uploadMutation = useUploadMutation({ setUploadFormProgress, setsnackbarStatus })

    const updateFiletypeMutation = useUpdateFiletypeMutation({ setsnackbarStatus });

    const deleteFileMutation = useDeleteFileMutation({ setsnackbarStatus });

    const [selectedFileBlobUrl, setSelectedFileBlobUrl] = useState(null);

    const handleFiletypeChange = (filetype) => {



        updateFiletypeMutation.mutate({
            caseNumber: selectedRow?.caseNumber,
            selectedhospCaseUserID: selectedhospCaseUserID,
            selectedhospCaseHospID: selectedhospCaseHospID,
            filetype: filetype,
            blobUrl: selectedFileBlobUrl.split('?')[0],

        });

    };

    const handleDeleteFile = () => {
        //console.log('delete');
        deleteFileMutation.mutate({
            caseNumber: selectedRow?.caseNumber,
            selectedhospCaseUserID: selectedhospCaseUserID,
            selectedhospCaseHospID: selectedhospCaseHospID,
            blobUrl: selectedFileBlobUrl.split('?')[0],
        });
    };


    const handleFileUploader = (filetype) => () => {
        setUploadingFileType(filetype);
        fileUploader(uploadMutation, uploadTypes, { uploadingFileType: filetype, selectedhospCaseHospID, clickedRowCaseNo: selectedRow?.caseNumber })();
    };


    const theme = useTheme();

    const contextMenuRef = useRef(null);
    const menuModel = [

        {
            label: (<Typography sx={{ color: theme.palette.text.primary, fontSize: '0.85rem', fontWeight: 'bolder', p: 0, m: 0 }}>
                {`${TextLanguage['ConvertTo'][lang]}..`}
            </Typography>),
            items: [
                {
                    label: `${TextLanguage['Documents'][lang]}`,
                    command: () => handleFiletypeChange('patientDoc'),
                },
                {
                    label: `${TextLanguage['Proforma'][lang]}`,
                    command: () => handleFiletypeChange('proforma'),
                },
                {
                    label: `${TextLanguage['Invoice'][lang]}`,
                    command: () => handleFiletypeChange('invoice'),
                },
                {
                    label: `${TextLanguage['MailAttachment'][lang]}`,
                    command: () => handleFiletypeChange('mailAttachment'),
                }]
        },
        {
            //Divider
            separator: true
        },
        {
            label: (<Typography sx={{ color: theme.palette.error.main, fontSize: '0.85rem', fontWeight: 'bolder', p: 0, m: 0 }}>
                {`${TextLanguage['DeleteFile'][lang]}..`}
            </Typography>),
            command: handleDeleteFile
        }


    ];

    const groupedFiles = onRowFileURLs?.reduce((result, file) => {
        const filetype = file['filetype'];
        // Create an array for the key if it doesn't exist
        if (!result[filetype]) { result[filetype] = [] }

        result[filetype].push(file);
        return result;
    }, {});


    return (
        <>
            {fileTypes.map((filetype, index) => {
                const isMail = (filetype === 'mailAttachment');
                if (!opMode && isMail) return null;
                const fileExists = (!!groupedFiles && groupedFiles[filetype]?.length > 0) ? true : false;

                const hospCase = selectedRow?.hospCases?.find(hospCase => hospCase.hospID === selectedhospCaseHospID);

                return (
                    <div key={`fileType-root-${index}-${filetype}`} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                        {// Display Flags Section
                            (selectedhospCaseHospID && hospCase?.flags && hospCase?.flags?.[flagNames[filetype]]) &&
                            <div className='flagContainer'>
                                <WarningRoundedIcon sx={{ color: 'red', fontSize: '1.5rem', marginRight: '0.5rem', pointerEvents: 'none', userSelect: 'none' }} />
                                <Typography sx={{ color: 'yellow', pointerEvents: 'none', userSelect: 'none' }}>
                                    {TextLanguage[flagDisplayNames[filetype]][lang]}
                                </Typography>
                            </div>
                        }

                        <div className='formUploadTitleRow'>
                            <Typography className='formUploadTextSpan' sx={{ fontSize: '1rem', fontWeight: 'bold' }} >
                                {`${TextLanguage[fileDisplayNames[filetype]][lang]}`}
                            </Typography>
                            <div className='formUploadProgressBarContainer'>
                                {(uploadMutation.isPending && uploadingFileType === filetype) &&
                                    <BorderLinearProgress variant='determinate' value={uploadFormProgress} sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} />}
                            </div>
                            {!isMail &&
                                <Button id='DocUploadButton'
                                    disabled={uploadMutation.isPending}
                                    sx={{
                                        // pointerEvents: disabled ? 'none' : 'auto'
                                    }}
                                    onClick={handleFileUploader(filetype)}
                                    className='formUploadButton'>
                                    <UploadIcon sx={{ marginRight: '2px' }} />
                                    <Typography variant="button">
                                        {TextLanguage['Upload'][lang]}
                                    </Typography>
                                </Button>}
                        </div>

                        {fileExists && <div className='formUploadTitleRow' style={{
                            padding: '0.5rem 0.5rem',
                            display: 'flex'
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                {groupedFiles[filetype]?.sort((a, b) => new Date(b?.uploadedat) - new Date(a?.uploadedat))
                                    .map((file, index) => {
                                        const fileName = file.originalname.length > 45 ? decodeURIComponent(file.originalname).slice(0, 45).concat('...') : decodeURIComponent(file.originalname);

                                        return (
                                            <div key={`fileType-div-${index}-${file?.originalname}`} style={{ display: 'flex', width: '100%', }}>
                                                {opMode && <ContextMenu key={`filecm-${index}`} model={menuModel} ref={contextMenuRef} onHide={() => { }} />}
                                                <TransparentButton
                                                    href={file.fileToken}
                                                    sx={{ borderRadius: 0, width: '100%', justifyContent: 'space-between', gap: '0.5rem' }}
                                                    onContextMenu={(e) => {
                                                        if (!opMode) return;
                                                        setSelectedFileBlobUrl(file.fileToken);
                                                        e.preventDefault();
                                                        contextMenuRef.current.show(e);
                                                    }}
                                                    className='fileLinks'>

                                                    <Typography component='span' sx={{ fontSize: '0.85rem', fontWeight: 'bold', color: 'var(--color-text)' }}>{fileName}</Typography>
                                                    <Typography component='span' sx={{ fontSize: '0.75rem', fontWeight: 'normal', color: 'var(--color-text-secondary)', textTransform: 'none' }}>{TimestampConverter(file?.uploadedat)}</Typography>

                                                </TransparentButton>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>}

                    </div>
                );
            })}
        </>
    );
};

/*
                                                                                                      <a className='fileLinks' href={file.fileToken} download target="_self" rel="noopener noreferrer">
                                                                                                          {fileName}
                                                                                                      </a>
                                                                                               */