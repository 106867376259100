import React from 'react';

import { PaymentListDataDisplay } from './PaymentListDataDisplay';
import { BreadcrumbPayment } from './BreadcrumbPayment';
import { TimestampConverter } from '../../PatientFormContainer/pages/PatientInfoPage/functions/TimestampConverter';


export const PaymentListHeader = ({
    selectedItem, setSelectedItem, // setSelectedItem = {userID: 'userID', hospID: 'hospID'}
    currentList, lang, opMode
}) => {



    const selectedPaymentList = !!(selectedItem?.hospID) ? currentList?.paymentList?.find((hosp) => hosp?.hospID === selectedItem?.hospID) : currentList;

    const parsedPaymentDate = currentList?.paymentDate ? new Date(currentList?.paymentDate) : null;
    const formatPaymentDate = TimestampConverter(parsedPaymentDate, true);


    const USDValue = selectedPaymentList?.GOPTotalUSD;
    const TLValue = selectedPaymentList?.GOPTotalTL / 100;
    const totalCount = selectedPaymentList?.totalCount;



    return (
        !!totalCount && totalCount > 0 ? <div className='paymentListHeaderWrapper' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '0.65rem 0.25rem', }}>

            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'flex-start', width: '100%', flexGrow: 1 }}>

                {formatPaymentDate && <div className='breadcrumbWrapper'>
                    <BreadcrumbPayment
                        rootLabel={formatPaymentDate}
                        selectedItem={selectedItem}
                        clearSelectedItem={() => setSelectedItem({ hospID: null, userID: null })}
                        lang={lang}

                    />
                </div>}

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'flex-start', width: '100%', flexGrow: 1 }}>

                {(selectedPaymentList) &&
                    <PaymentListDataDisplay
                        lang={lang}
                        opMode={opMode}
                        USDValue={USDValue}
                        TLValue={TLValue}
                        totalCount={totalCount}
                    />}

            </div>
        </div> : null);
}



