import React, { useState, useRef } from 'react';
import {MailDisplay} from '../PatientInfoPage/components/MailDisplay';
import { CommentsComponent } from '../PatientInfoPage/components/CommentsComponent';

import {
    Typography,
} from '@mui/material';

import { useMailFetchQuery } from '../../hooks/useMailFetchQuery';
import { mailTree } from '../PatientInfoPage/functions/mailTree';
import MailSelector from '../PatientInfoPage/components/MailSelector';
import { useQueryClient } from '@tanstack/react-query';


export const CommPageComponent = ({
    selectedRow,
    selectedhospCaseUserID,
    selectedhospCaseHospID,
    setsnackbarStatus,
    opMode,
    paymentMode
}) => {

    const queryClient = useQueryClient();



    const validateData = queryClient.getQueryData(['validate']);
    const confirmedUserID = validateData?.data?.userID;
    const AdminMode = (confirmedUserID === 'Admin');
    // const OperatorMode = (confirmedUserID === 'Operator');
    // const ExpertiseMode = (confirmedUserID === 'Expertise');
    // const ExpertisePaymentMode = (confirmedUserID === 'ExpertisePayment');

    const mailFetchQuery = useMailFetchQuery({ userID: selectedhospCaseUserID, hospID: selectedhospCaseHospID,  caseNumber: selectedRow?.caseNumber, opMode })
    const mailData = opMode && mailFetchQuery.isSuccess && mailFetchQuery?.data?.data?.messagesArray.sort((a, b) => new Date(a.date) - new Date(b.date));

    const orderedMailTreeArray = opMode && mailData?.length > 0 && mailTree(mailData);

    const [selectedMailID, setSelectedMailID] = useState(null);



    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);


    const renderMail = (opMode && mailFetchQuery?.isSuccess && orderedMailTreeArray)

    return (<div className='commPageWrapper'>


        <div className='HeaderUnderlined' style={{ justifyContent: 'center' }}>
            <Typography variant='h4' sx={{ fontSize: '1.4rem', fontWeight: 'bold', color: 'white' }}>
                {hospitalUserListData?.data.find(hosp => selectedhospCaseHospID === hosp.hospID)?.hospitalName}
            </Typography>

        </div>





        <div className='commPageContainer' style={{ width: renderMail ? '98vw': '100%', gridTemplateColumns: renderMail ? '2fr 1fr' : '1fr' }}>

            { renderMail &&
                <div className='mailSection'>
                    <MailSelector
                        selectedRow={selectedRow}
                        selectedMailID={selectedMailID}
                        setSelectedMailID={setSelectedMailID}
                        orderedMailTreeArray={orderedMailTreeArray}
                    />
                    <MailDisplay
                        selectedRow={selectedRow}
                        selectedMailID={selectedMailID}
                        setSelectedMailID={setSelectedMailID}
                        orderedMailTreeArray={orderedMailTreeArray}
                    />
                </div>}


            <CommentsComponent
                selectedRow={selectedRow}
                selectedhospCaseHospID={selectedhospCaseHospID}
                setsnackbarStatus={setsnackbarStatus}
                opMode={opMode}
                paymentMode={paymentMode}
            />
        </div>
    </div>);
}

