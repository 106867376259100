import React from 'react';
import { CircularProgress } from '@mui/material';

export const StyledLoading = () => {
    return (<>
        <svg width={0} height={0}>
            <defs>
                <linearGradient id="rbk_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="var(--color-main-theme-saturated)" />
                    <stop offset="100%" stopColor="var(--color-main-theme-secondary)" />
                </linearGradient>
            </defs>
        </svg>
        <CircularProgress thickness={4} size={50} sx={{ 'svg circle': { stroke: 'url(#rbk_gradient)' }, fontSize: '3rem' }} />
    </>);
};
