import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';




export const StyledTooltip = styled(({ className,placement, ...props }) => (
    <Tooltip
    {...props}
    arrow
    disableInteractive
    PopperProps={{popperOptions: {modifiers: [{name: 'offset',options: { offset: [0, -12] }}]}}}
    placement={placement ? placement : "right"}
    classes={{ popper: className }}

    />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'var(--color-main-theme-secondary)',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '0.85rem',
        backgroundColor: 'var(--color-main-theme-dark-transparent)',
        backdropFilter: 'blur(4px)',
    },
}));
