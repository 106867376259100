import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/tr';

import {
    Typography,
    Modal,
    Drawer
} from '@mui/material';
//import { ErrorBoundary } from "react-error-boundary";

import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';

import { PatientFormContainer } from '../components/PatientFormContainer/PatientFormContainer';

import { useExchangeRateQuery } from '../hooks/useExchangeRateQuery';
import { useValidateQuery } from '../hooks/useValidateQuery';


import TextLanguage from '../data/TextLanguage.json';

// import { useTheme } from '@mui/material/styles';


import { ContextMenu } from 'primereact/contextmenu';

import { FileShareTable } from '../components/DataTable/FileShareTable';
import RemoveConfirmationDialog from '../components/RemoveConfirmationDialog';

import useLocalStorage from '../hooks/useLocalStorage';
import { CustomSnackbar } from '../components/CustomSnackbar';
import { useQueryClient } from '@tanstack/react-query';
import { useHospitalUserListQuery } from '../hooks/useHospitalUserListQuery';
import { useInsuranceCompanyListQuery } from '../hooks/useInsuranceCompanyListQuery';

import { useSocket } from '../components/contextProviders/SocketProvider';
import RBKIcon from '../resources/icon.png';
import NavigationBar from '../components/NavigationBar';
import { ActivityLog } from '../components/PatientFormContainer/pages/PatientInfoPage/components/ActivityLog';
import { useRefreshQuery } from '../components/Login/useRefreshQuery';
import { UserOnboardModal } from '../components/UserOnboard/UserOnboardModal';

import { userTypesRBK } from '../data/userTypes';
import { UserSettings } from '../components/UserSettings/UserSettings';
import { displayCaseNo } from '../functions/displayCaseNo';

//import "primereact/resources/themes/lara-light-indigo/theme.css";

//const socket = io("https://localhost");


export function FilesharePage() {

    //const currentPage = 'Fileshare';
    //const theme = useTheme();



    const socket = useSocket();

    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();

    const opMode = userTypesRBK.includes(validateQuery.data?.data?.userID);
    const confirmedUserID = validateQuery.data?.data?.userID;

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams({ page: 0, pageSize: 50 });


    const [formOpen, setformOpen] = useState(false);
    const [formCreateMode, setFormCreateMode] = useState(false);
    const [paymentMode, setPaymentMode] = useState(false);


    const [drawerOpen, setDrawerOpen] = useState({ state: false, type: 'activityLog' });

    const toggleDrawer = ({ state, type }) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen({ state: state, type: type });
    };



    const navigate = useNavigate();
    const [searchFilter, setSearchFilter] = useState({});

    const [selectedTab, setSelectedTab] = useState("PatientCaseInformation");

    const [selectedhospCaseUserID, setselectedhospCaseUserID] = useState(null);
    const [selectedhospCaseHospID, setselectedhospCaseHospID] = useState(null);

    const [selectedRowCaseNo, setselectedRowCaseNo] = useState(null);
    const [modalPosition, setmodalPosition] = useState('-100%');


    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });


    const hospitalUserListQuery = useHospitalUserListQuery();

    const hospitalUserData = hospitalUserListQuery?.data?.data;
    // eslint-disable-next-line no-unused-vars
    const insCoListQuery = useInsuranceCompanyListQuery({ opMode });

    const invalidateDebounceRef = useRef(null);

    const [notificationState, setNotificationState] = useState(true);

    useEffect(() => {

        if (notificationState && Notification.permission !== "granted") {
            Notification.requestPermission();
        }


    }, [notificationState])


    useEffect(() => {

        socket.on('update', (msg) => {

            invalidateDebounceRef.current && clearTimeout(invalidateDebounceRef.current);
            invalidateDebounceRef.current = setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ['patients'] , refetchType: 'active'}, {cancelRefetch: false});
                queryClient.invalidateQueries({ queryKey: ['activityLog'] , refetchType: 'active'}, {cancelRefetch: false});
            }, 250);

            if (msg.type === 'CommentsUpdated') {
                let commentBy = '';
                if (msg?.value?.by?.type !== "RBK") {
                    commentBy = hospitalUserData.find(hosp => msg?.value?.by?.userID === hosp.value)?.label ?
                        `${hospitalUserData.find(hosp => msg?.value?.by?.userID === hosp.value)?.label}:` : '';
                } else {
                    commentBy = 'RBK:';
                }
                if ('Notification' in window && notificationState) {

                    Notification.requestPermission().then(function (permission) {

                        if (permission === 'granted') {

                            var notification = new Notification(`${msg?.value?.displayCaseNo}`, {
                                body: `${commentBy} ${msg?.value?.comment} `,
                                icon: RBKIcon,
                                silent: true
                            });


                            notification.onclick = function () {
                                // Create event to focus open relevant Case + Tab
                                // Open Modal with given info
                                const { missingDocs, missingProforma, missingGOP, missingInvoice, FlagError, ...updatedSearchFilter } = searchFilter;
                                setSearchFilter({ ...updatedSearchFilter, FlagMessage: true });
                                handleSort({ sortOrder: 0, sortField: null });

                                setSelectedTab("PatientCaseInformation");
                                setPaymentMode(false);
                                setselectedRowCaseNo(msg?.value?.caseNumber);
                                (msg?.value?.by?.type !== 'RBK') && setselectedhospCaseUserID(msg?.value?.by?.userID);
                                (msg?.value?.by?.type !== 'RBK') && setselectedhospCaseHospID(msg?.value?.by?.hospID);
                                setformOpen(true);
                                setTimeout(() => {
                                    setmodalPosition('0%')
                                }, 15);
                                setFormCreateMode(false);


                                window.focus();
                            };

                        } else {
                            console.log('Notification permission denied');
                        }
                    });
                } else {

                    console.log('Browser does not support notifications');
                }
            } else if (msg.type === 'NewMessage') {

                if ('Notification' in window && notificationState) {

                    Notification.requestPermission().then(function (permission) {

                        if (permission === 'granted') {

                            var notification = new Notification(`+${msg?.value?.from}`, {
                                body: `${msg?.value?.content}`,
                                icon: RBKIcon,
                                silent: true
                            });

                            notification.onclick = function () {
                                navigate('/chat');
                                window.focus();
                            };

                        } else {
                            console.log('Notification permission denied');
                        }
                    });
                } else {
                    console.log('Browser does not support notifications');
                }
            } else if (msg.type === 'FileUpload' || msg.type === 'FileDelete') {
                queryClient.invalidateQueries({ queryKey: ['fileTokens', { caseNumber: msg?.caseNumber, selectedhospCaseHospID: msg?.hospID }], refetchType: 'active'}, {cancelRefetch: false });
                queryClient.invalidateQueries({ queryKey: ['fileFetch', { caseNumber: msg?.caseNumber, selectedhospCaseHospID: msg?.hospID }] , refetchType: 'active'}, {cancelRefetch: false});

            }


        }).on('mail', (msg) => {
            queryClient.invalidateQueries({ queryKey: ['mailFetch', { caseNumber: msg?.caseNumber, userID: msg?.userID, hospID: msg?.hospID }] , refetchType: 'active'}, {cancelRefetch: false});

        })


        return () => { socket.off('update') }

        // eslint-disable-next-line
    }, [socket])




    // Context Menu need to fix InsuranceStart and InsuranceEnd, add diagnosis and caseType for the processing stage
    const contextMenuRef = useRef(null);
    const [rightselectedRow, setrightselectedRow] = useState(null);


    const menuModel = opMode ? [
        { label: 'Copy', command: () => copyToClipboard(rightselectedRow) },

        { separator: true },

        {
            label:
                <Typography variant='button' sx={{ color: 'red', fontSize: '1rem', fontWeight: 'bolder', p: 0, m: 0 }}>
                    {TextLanguage["Remove"][lang]}
                </Typography>
            ,
            command: () => removeCase(rightselectedRow)
        },

    ]
        :
        [
            { label: 'Copy', command: () => copyToClipboard(rightselectedRow) }
        ];

    const copyToClipboard = (rowData) => {
        const dobParsedDate = new Date(rowData?.dob);
        const dobDay = dobParsedDate.getDate().toString().padStart(2, '0');
        const dobMonth = (dobParsedDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const dobYear = dobParsedDate.getFullYear();
        const dobReformattedDate = `${dobDay}/${dobMonth}/${dobYear}`
        const clipboardData = opMode ? `${displayCaseNo(rowData)}\t${rowData?.name}\t${dobReformattedDate}\t${rowData?.franchise}\t${hospitalUserData.find(hosp => rowData?.userID === hosp.value)?.label}`
            :
            `${displayCaseNo(rowData)}\t${rowData?.name}\t${dobReformattedDate}\t${rowData?.franchise}\t`
        navigator.clipboard.writeText(clipboardData);
    }

    const removeCase = async (selection) => {

        setRemoveFormOpen({ state: true, selectedRow: selection, selectedhospCaseUserID: null, selectedhospCaseHospID: null });
    }

    // Context Menu End

    useEffect(() => {

        if (refreshQuery.isError && refreshQuery.failureCount >= 3 ) {
            console.log('Refresh Token Expired');
            queryClient.clear()
            navigate('/');
        } else if (refreshQuery.isError && refreshQuery.error?.response?.status === 403) {
            console.log('Refresh Token Expired');
            queryClient.clear()
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshQuery, refreshQuery.isError, refreshQuery.failureCount, refreshQuery.error])

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    // eslint-disable-next-line no-unused-vars
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    const queryClient = useQueryClient();

    useEffect(() => {

        // queryClient.invalidateQueries({ queryKey: ['patients'] });
        // queryClient.invalidateQueries({ queryKey: ['validate'] });
        // queryClient.invalidateQueries({ queryKey: ['activityLog'] });

        const handleResize = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (userTypesRBK.includes(confirmedUserID)) {
            setselectedhospCaseUserID(null);
            setselectedhospCaseHospID(null);
        } else {
           setselectedhospCaseUserID(confirmedUserID);

        }
    }, [confirmedUserID])

    const handleSort = (event) => {
        setSearchParams(prev => {
            prev.set('sortField', event.sortField);
            prev.set('sortOrder', event.sortOrder);
            return prev;
        }, { replace: true });
    }

    const handleModalFormClose = () => {
        setformOpen(false);
        setmodalPosition('-100%')
        setFormCreateMode(false);
        setselectedRowCaseNo(null);
        setPaymentMode(false);

        opMode && setselectedhospCaseUserID(null);
        opMode && setselectedhospCaseHospID(null);
    };





    const exchangeRateQuery = useExchangeRateQuery({ opMode });

    useEffect(() => {
        if (exchangeRateQuery.isError && exchangeRateQuery?.error?.response?.status !== 401) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Exchange Rate`,
                    severity: 'error'
                }
            )

        }

    }, [exchangeRateQuery, exchangeRateQuery.data, exchangeRateQuery.isError])

    useEffect(() => {
        if (!formOpen && opMode) { // triggered on close
            setselectedhospCaseUserID(null)
            setselectedhospCaseHospID(null)

        }

    }, [formOpen, opMode]);

    const modalWidthSelector = (selectedTab === 'PatientCaseInformation') ? // First Page?
        (selectedhospCaseUserID ? // OpMode, Picked a case?
            { width: 'auto', maxWidth: '100vw' } :
            { width: 'auto', maxWidth: '100vw', })
        :
        { width: 'auto' }


    const [removeFormOpen, setRemoveFormOpen] = useState({ state: false, selectedRow: null, selectedhospCaseUserID: null, selectedhospCaseHospID: null });



    const [openUserOnboard, setOpenUserOnboard] = useState(false);


    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

            <div className="App-main">


                <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>

                    <NavigationBar
                        currentPage='fileshare'
                        lang={lang}
                        opMode={opMode}
                        notificationState={notificationState}
                        setNotificationState={setNotificationState}
                        toggleDrawer={toggleDrawer}
                        viewportWidth={viewportWidth}
                        setOpenUserOnboard={setOpenUserOnboard}
                    />

                    <div className='dataTableContainer'>


                        <UserOnboardModal
                            openUserOnboard={openUserOnboard}
                            setOpenUserOnboard={setOpenUserOnboard}
                            lang={lang}
                            setlang={setlang}
                        />




                        <ContextMenu model={menuModel} ref={contextMenuRef} onHide={() => setrightselectedRow(null)} />


                        {validateQuery.isSuccess && <FileShareTable
                            viewportWidth={viewportWidth}
                            setlang={setlang}
                            handleSort={handleSort}
                            contextMenuRef={contextMenuRef}
                            rightselectedRow={rightselectedRow}
                            setrightselectedRow={setrightselectedRow}
                            selectedRowCaseNo={selectedRowCaseNo}
                            setselectedRowCaseNo={setselectedRowCaseNo}
                            setselectedhospCaseUserID={setselectedhospCaseUserID}
                            setselectedhospCaseHospID={setselectedhospCaseHospID}
                            lang={lang}
                            setformOpen={setformOpen}
                            formOpen={formOpen}
                            setFormCreateMode={setFormCreateMode}
                            setmodalPosition={setmodalPosition}
                            searchFilter={searchFilter}
                            setSearchFilter={setSearchFilter}
                            setSelectedTab={setSelectedTab}
                            setsnackbarStatus={setsnackbarStatus}
                            setPaymentMode={setPaymentMode}
                        />}



                    </div>
                </div>



                <Modal
                    open={formOpen}
                    onClose={handleModalFormClose}
                >
                    <div className="modal-container"
                        style={{
                            ...modalWidthSelector, transform: 'translateY(-50%)',
                            left: `${modalPosition}`, transition: '0.5s ease-in-out 0s !important',
                            height: '100vh', overflowY: 'visible', maxWidth: '100vw'
                        }}>




                        {formOpen && (selectedRowCaseNo || formCreateMode) &&
                            <PatientFormContainer
                                lang={lang}
                                opMode={opMode}
                                confirmedUserID={confirmedUserID}
                                selectedhospCaseUserID={selectedhospCaseUserID}
                                selectedhospCaseHospID={selectedhospCaseHospID}
                                setselectedhospCaseUserID={setselectedhospCaseUserID}
                                setselectedhospCaseHospID={setselectedhospCaseHospID}
                                setsnackbarStatus={setsnackbarStatus}
                                formCreateMode={formCreateMode}
                                setformOpen={setformOpen}
                                selectedRowCaseNo={selectedRowCaseNo}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                paymentMode={paymentMode}
                                handleModalFormClose={handleModalFormClose}
                                setRemoveFormOpen={setRemoveFormOpen}
                                viewportWidth={viewportWidth}
                            />
                        }



                    </div>
                </Modal>

                <RemoveConfirmationDialog
                    lang={lang}
                    removeFormOpen={removeFormOpen}
                    setRemoveFormOpen={setRemoveFormOpen}
                    setsnackbarStatus={setsnackbarStatus}
                />

                <Drawer
                    anchor='left'
                    open={drawerOpen?.state === true}
                    onClose={toggleDrawer({ state: false, type: drawerOpen?.type })}

                >
                    <div style={{ minWidth: '340px', width: '25vw', maxWidth: '500px', height: '100%' }}>

                        {drawerOpen?.type === 'activityLog' ? <ActivityLog
                            setformOpen={setformOpen}
                            setselectedRowCaseNo={setselectedRowCaseNo}
                            setselectedhospCaseUserID={setselectedhospCaseUserID}
                            setselectedhospCaseHospID={setselectedhospCaseHospID}
                            setSelectedTab={setSelectedTab}
                            setmodalPosition={setmodalPosition}
                            setFormCreateMode={setFormCreateMode}
                            mainActivityLog={true}
                            lang={lang}
                            setPaymentMode={setPaymentMode}
                        />
                            : <div>
                                <UserSettings
                                    lang={lang}
                                    setsnackbarStatus={setsnackbarStatus}
                                />
                            </div>

                        }



                    </div>
                </Drawer>


            </div>

        </LocalizationProvider>










    );
}








