
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from '../../../config.js';
import { useNavigate } from "react-router-dom";

export const useArchivePaymentListGroupQuery = ({selectedFilterUserID}) => {


    const navigate = useNavigate();

    async function fetchArchivePayment({ queryKey }) {
        const [_key, selectedFilterUserID] = queryKey;

        return await axios.get(`${config.API_URL}/payment/archivedPaymentListGroup?selectedFilterUserID=${selectedFilterUserID}`, {
            withCredentials: true,
        })

    }



    return useQuery(
        {
            queryKey: ['ArchivePaymentListGroup', selectedFilterUserID],
            queryFn: fetchArchivePayment,
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes

        }
    )





}


