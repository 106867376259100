

import { rgb } from 'd3-color';
import { scaleSequential } from 'd3-scale';
import { interpolateBlues } from 'd3-scale-chromatic';
// interpolateWarm, interpolateCividis, interpolatePlasma, interpolatePiYG ,interpolateCool,
const colorScale = scaleSequential(t => {
    const color = interpolateBlues(t);
    return rgb(color).formatHex();
  }).domain([1, 0]);




export function NivoChartDataGenerator(DailyList) {

    if (!DailyList || DailyList.length === 0) {
        return [];
    }
        //let firstColumnName = Object.keys(DailyList[0])[0]; // Pick out label column string
        const seriesDaily = DailyList
            .map((record, index) => {
                const data = record["data"];
                const Sum = record["Sum"];
                const color = colorScale(index / DailyList.length);
                const id = record["Name"];


                return { id, color, data, Sum };
            });





        const seriesPie = DailyList
            .map((record, index) => {
                const color = colorScale(index / DailyList.length);
                const id = record["Name"];
                const label = record["Name"];
                const value = record["Sum"];


                return { id, label, color, value };
            });

           const seriesSum = DailyList.reduce((acc, record) => {

              return acc + parseInt(record["Sum"]);
            }, 0);


        return [seriesDaily, seriesPie, seriesSum];

    }