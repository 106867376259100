export const translations = {
    introduction: {
        enUS: "Introduction",
        trTR: "Giriş",
        ruRU: "Введение"
    },
    welcomeMessage: {
        enUS: "Welcome to the RBK Assistance App. This application is designed to help you manage and track various tasks efficiently. It provides a comprehensive interface for handling patient information, file sharing, communications and more.",
        trTR: "RBK Assistance Uygulamasına hoş geldiniz. Bu uygulama, çeşitli görevleri verimli bir şekilde yönetmenize ve takip etmenize yardımcı olmak için tasarlanmıştır. Hasta bilgilerini, dosya paylaşımını ve iletişimi yönetmek için kapsamlı bir arayüz sağlar.",
        ruRU: "Добро пожаловать в приложение RBK Assistance. Это приложение предназначено для того, чтобы помочь вам эффективно управлять и отслеживать различные задачи. Оно предоставляет комплексный интерфейс для работы с информацией о пациентах, обменом файлами, уведомлениями и многим другим."
    },
    features: {
        enUS: "Features",
        trTR: "Özellikler",
        ruRU: "Функции"
    },
    patientInfoManagement: {
        enUS: "Patient Information Management",
        trTR: "Hasta Bilgi Yönetimi",
        ruRU: "Управление информацией о пациентах"
    },
    patientForm: {
        enUS: "Patient Form: Allows you to create, view, and edit patient information. You can manage details such as name, phone number, date of birth, admission date, and more.",
        trTR: "Hasta Formu: Hasta bilgilerini oluşturmanıza, görüntülemenize ve düzenlemenize olanak tanır. Ad, telefon numarası, doğum tarihi, kabul tarihi ve daha fazlası gibi ayrıntıları yönetebilirsiniz.",
        ruRU: "Форма пациента: позволяет создавать, просматривать и редактировать информацию о пациенте. Вы можете управлять такими данными, как имя, номер телефона, дата рождения, дата поступления и многое другое."
    },
    searchFilter: {
        enUS: "Search and Filter: Easily search and filter patient records based on various criteria.",
        trTR: "Arama ve Filtreleme: Hasta kayıtlarını çeşitli kriterlere göre kolayca arayın ve filtreleyin.",
        ruRU: "Поиск и фильтрация: легко ищите и фильтруйте записи пациентов по различным критериям."
    },
    fileSharing: {
        enUS: "File Sharing",
        trTR: "Dosya Paylaşımı",
        ruRU: "Обмен файлами"
    },
    fileShareTable: {
        enUS: "FileShare Table: A table that displays all the files related to patient cases. You can upload, download, and delete files as needed.",
        trTR: "Dosya Paylaşım Tablosu: Hasta vakalarıyla ilgili tüm dosyaları görüntüleyen bir tablo. Gerekli dosyaları yükleyebilir, indirebilir ve silebilirsiniz.",
        ruRU: "Таблица обмена файлами: таблица, отображающая все файлы, связанные с делами пациентов. Вы можете загружать, скачивать и удалять файлы по мере необходимости."
    },
    fileUploadNotifications: {
        enUS: "File Upload Notifications: Receive notifications when new files are uploaded or deleted.",
        trTR: "Dosya Yükleme Bildirimleri: Yeni dosyalar yüklendiğinde veya silindiğinde bildirim alın.",
        ruRU: "Уведомления о загрузке файлов: получайте уведомления, когда новые файлы загружаются или удаляются."
    },
    automaticMailManagement: {
        enUS: "Automatic Mail Management",
        trTR: "Otomatik Posta Yönetimi",
        ruRU: "Автоматическое управление почтой"
    },
    automaticMail: {
        enUS: "An automatic mail will be sent when the case is created, with the format “NEW PATIENT / PATIENT NAME / REF NO”.",
        trTR: "Vaka oluşturulduğunda, “NEW PATIENT / HASTA ADI / REFERANS NO” formatında otomatik bir posta gönderilecektir.",
        ruRU: "Автоматическое письмо будет отправлено при создании дела в формате “NEW PATIENT / ИМЯ ПАЦИЕНТА / НОМЕР ССЫЛКИ”."
    },
    messaging: {
        enUS: "To ensure that all messages are processed correctly, make sure that all messages sent to the patient include this mail in its reply.",
        trTR: "Postaların sisteme işlenebilmesi için hasta adına yapılan tüm mesajlaşmaların bu postayı yanıtında içerdiginden emin olun",
        ruRU: "Чтобы гарантировать правильную обработку всех сообщений, убедитесь, что все сообщения, отправленные пациенту, включают это письмо в ответе."
    },
    howToUse: {
        enUS: "How to Use",
        trTR: "Nasıl Kullanılır",
        ruRU: "Как использовать"
    },
    managingPatientInfo: {
        enUS: "Managing Patient Information",
        trTR: "Hasta Bilgilerini Yönetme",
        ruRU: "Управление информацией о пациентах"
    },
    clickPatientCase: {
        enUS: "Click on a patient case to view detailed information. Use the form to edit or update patient details.",
        trTR: "Hasta vakasına tıklayarak ayrıntılı bilgileri görüntüleyin. Formu kullanarak hasta bilgilerini düzenleyin veya güncelleyin.",
        ruRU: "Нажмите на дело пациента, чтобы просмотреть подробную информацию. Используйте форму для редактирования или обновления данных пациента."
    },
    referenceTypes: {
        enUS: "The format of the reference may change depending on the insurance company. Pay attention to the prefix of the reference number (RBK### / TUR 202#-###) when including it in documents",
        trTR: "Referansın formatı sigorta şirketine bağlı olarak değişebilir. Belgelerde yer alırken referans numarasının ön ekine dikkat edin (RBK### / TUR 202#-###)",
        ruRU: "Формат ссылки может изменяться в зависимости от страховой компании. Обратите внимание на префикс номера ссылки RBK### / TUR 202#-### при включении его в документы"
    },
    fileSharingFeature: {
        enUS: "File Sharing",
        trTR: "Dosya Paylaşımı",
        ruRU: "Обмен файлами"
    },
    uploadFiles: {
        enUS: "Upload files related to patient cases using the file upload feature. Download or view files within the browser as needed.",
        trTR: "Hasta vakalarıyla ilgili dosyaları dosya yükleme özelliğini kullanarak yükleyin. Gerekli dosyaları tarayıcıda indirin veya görüntüleyin.",
        ruRU: "Загружайте файлы, связанные с делами пациентов, используя функцию загрузки файлов. Скачивайте или просматривайте файлы в браузере по мере необходимости."
    },
    fileUploadShortcuts: {
        enUS: "Files can either be uploaded within the form, or through the shortcuts inside the table row.",
        trTR: "Dosyalar form içinde veya tablo satırındaki kısayollar aracılığıyla yüklenebilir.",
        ruRU: "Файлы можно загружать либо в форме, либо через ярлыки внутри строки таблицы."
    },
    exportExcel: {
        enUS: "The displayed rows can be exported as an excel file with a button located in the table footer.",
        trTR: "Görüntülenen satırlar, tablo altbilgisinde bulunan bir düğme ile excel dosyası olarak dışa aktarılabilir.",
        ruRU: "Отображаемые строки можно экспортировать в файл Excel с помощью кнопки, расположенной в нижнем колонтитуле таблицы."
    },
    filterButtons: {
        enUS: "The buttons on the top right of the page display total counts based on how many files require which step and also function as filters on click.",
        trTR: "Sayfanın sağ üst köşesindeki düğmeler, hangi adımın kaç dosya gerektirdiğine göre toplam sayıları görüntüler ve tıklama ile filtre olarak işlev görür.",
        ruRU: "Кнопки в правом верхнем углу страницы отображают общее количество файлов, требующих выполнения каждого шага, и также функционируют как фильтры при нажатии."
    },
    patientFormFeature: {
        enUS: "Patient Form",
        trTR: "Hasta Formu",
        ruRU: "Форма пациента"
    },
    accessTabs: {
        enUS: "Access Related Information through various tabs.",
        trTR: "Çeşitli sekmeler aracılığıyla ilgili bilgilere erişin.",
        ruRU: "Доступ к связанной информации через различные вкладки."
    },
    patientInfo: {
        enUS: "Patient Information: View patient info, download or upload files, view related activity.",
        trTR: "Hasta Bilgileri: Hasta bilgilerini görüntüleyin, dosyaları indirin veya yükleyin, ilgili etkinlikleri görüntüleyin.",
        ruRU: "Информация о пациенте: просматривайте информацию о пациенте, загружайте или загружайте файлы, просматривайте связанные действия."
    },
    messages: {
        enUS: "Messages: Communicate directly through the app or view emails related to the patient.",
        trTR: "Mesajlar: Uygulama aracılığıyla doğrudan iletişim kurun veya hasta ile ilgili e-postaları görüntüleyin.",
        ruRU: "Сообщения: общайтесь напрямую через приложение или просматривайте электронные письма, связанные с пациентом."
    },
    documentPreview: {
        enUS: "Document: Preview documents directly within the browser (Ensure the PDF Documents setting is enabled in browser settings).",
        trTR: "Belge: Belgeleri doğrudan tarayıcıda önizleyin (Tarayıcı ayarlarında PDF Belgeleri ayarının etkin olduğundan emin olun).",
        ruRU: "Документ: предварительный просмотр документов прямо в браузере (убедитесь, что в настройках браузера включена настройка PDF-документов)."
    },
    gop: {
        enUS: "GOP: View and download the GOP Document, deny or accept the given GOP amount (uploading the invoice automatically approves GOP as well).",
        trTR: "GOP: GOP Belgesini görüntüleyin ve indirin, verilen GOP miktarını reddedin veya kabul edin (faturayı yüklemek GOP'u otomatik olarak onaylar).",
        ruRU: "GOP: просматривайте и загружайте документ GOP, отклоняйте или принимайте указанную сумму GOP (загрузка счета автоматически подтверждает GOP)."
    },
    userSettings:{
        enUS: "User Settings",
        trTR: "Kullanıcı Ayarları",
        ruRU: "Настройки пользователя"
    },
    authapp: {
        enUS: "Authentication App: Use an authentication app like Google Authenticator to enable two-factor authentication for added security.",
        trTR: "Kimlik Doğrulama Uygulaması: Daha fazla güvenlik için Google Authenticator gibi bir kimlik doğrulama uygulamasını kullanarak iki faktörlü kimlik doğrulamayı etkinleştirin.",
        ruRU: "Приложение аутентификации: используйте приложение аутентификации, такое как Google Authenticator, чтобы включить двухфакторную аутентификацию для повышения безопасности."
    },
    mailSetting: {
        enUS: "Manage where automated emails are sent by updating your email address.",
        trTR: "E-posta adresinizi güncelleyerek otomatik e-postaların nereye gönderileceğini yönetin.",
        ruRU: "Управляйте тем, куда отправляются автоматические электронные письма, обновив свой адрес электронной почты."
    },











    receivingNotifications: {
        enUS: "Receiving Notifications",
        trTR: "Bildirim Alma",
        ruRU: "Получение уведомлений"
    },
    browserNotifications: {
        enUS: "Browser Notifications: Receive browser notifications for important events such as new messages, and file uploads or GOP updates.",
        trTR: "Tarayıcı Bildirimleri: Yeni mesajlar, dosya yüklemeleri veya GOP güncellemeleri gibi önemli olaylar için tarayıcı bildirimleri alın.",
        ruRU: "Уведомления браузера: получайте уведомления браузера о важных событиях, таких как новые сообщения, загрузка файлов или обновления GOP."
    },
    enableNotifications: {
        enUS: "Ensure that browser notifications are enabled to receive real-time updates.",
        trTR: "Gerçek zamanlı güncellemeleri almak için tarayıcı bildirimlerinin etkin olduğundan emin olun.",
        ruRU: "Убедитесь, что уведомления браузера включены, чтобы получать обновления в реальном времени."
    },
    viewingActivityLog: {
        enUS: "Viewing Activity Log",
        trTR: "Etkinlik Günlüğünü Görüntüleme",
        ruRU: "Просмотр журнала активности"
    },
    openActivityLog: {
        enUS: "Open the activity log drawer to view a detailed log of all changes related to patient cases. Clicking a particular item will open the related case.",
        trTR: "Hasta vakalarıyla ilgili tüm değişikliklerin ayrıntılı günlüğünü görüntülemek için etkinlik günlüğü çekmecesini açın. Belirli bir öğeye tıklamak ilgili vakayı açacaktır.",
        ruRU: "Откройте ящик журнала активности, чтобы просмотреть подробный журнал всех изменений, связанных с делами пациентов. Нажатие на конкретный элемент откроет соответствующее дело."
    },
    switchingLanguages: {
        enUS: "Switching Languages",
        trTR: "Dilleri Değiştirme",
        ruRU: "Смена языков"
    },
    useLanguageSelector: {
        enUS: "Use the language selector to switch between different languages supported by the app.",
        trTR: "Uygulama tarafından desteklenen farklı diller arasında geçiş yapmak için dil seçiciyi kullanın.",
        ruRU: "Используйте селектор языка, чтобы переключаться между различными языками, поддерживаемыми приложением."
    }
};
