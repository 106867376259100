import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

export const useUpdateFiletypeMutation = ({setsnackbarStatus}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ caseNumber, selectedhospCaseUserID,selectedhospCaseHospID, filetype, blobUrl }) => {


            return axios.post(
                `${config.API_URL}/data/updateFiletype`,
                { caseNumber, selectedhospCaseUserID,selectedhospCaseHospID, filetype, blobUrl },
                {
                    withCredentials: true
                }
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'], refetchType: 'active'}, {cancelRefetch: false });
            queryClient.invalidateQueries({ queryKey: ['activityLog'] , refetchType: 'active'}, {cancelRefetch: false});
            setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ['fileTokens'], refetchType: 'active'}, {cancelRefetch: false });
            }, 1000);
        },
        onError: (error) => {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Deleting File`,
                    severity: 'error'

                }
            )
        }
    });
}

