import React, { useState } from 'react';
import {
    Typography,
    Modal,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CompletionBar } from '../DataTable/components/CompletionBar';
import { GOPStateSelector } from '../PatientFormContainer/pages/GOPPage/components/GOPStateSelector';
import { UploadedFilesComponent } from '../PatientFormContainer/pages/PatientInfoPage/components/UploadedFilesComponent';

import { PatientListExcelButton } from '../DataTable/components/PatientListExcelButton';
import { DashboardFilterButtons } from '../DataTable/components/DashboardFilterButtons';

import NotificationImageA from '../../resources/NotificationExampleP1.png';
import NotificationImageB from '../../resources/NotificationExampleP2.png';

// import googleSettingImage1 from '../../resources/google_setting_image_1.png';
// import googleSettingImage2 from '../../resources/google_setting_image_2.png';
// import googleSettingImage3 from '../../resources/google_setting_image_3.png';
import googleSettingImage4 from '../../resources/google_setting_image_4.png';


import { ActivityLogItem } from '../PatientFormContainer/pages/PatientInfoPage/components/ActivityLogItem';
import { LanguageSelector } from '../LanguageSelector';
import { CommentsComponent } from '../PatientFormContainer/pages/PatientInfoPage/components/CommentsComponent';
import { translations } from './translations';
import { exampleData, exampleList, exampleLogList } from './exampleData';
import { ExampleTable, tableRowStyle } from './ExampleTable.jsx';
import { NavBarExample } from './NavBarExample.jsx';
import { UserSettings } from '../UserSettings/UserSettings.jsx';

const highlightText = (text, highlight) => {
    if (!highlight) return text;
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{ color: 'var(--color-main-theme-light)', fontWeight: 'bolder' }}>{part}</span>
        ) : (
            part
        )
    );
};
const highlightTextQuotes = (text, highlight) => {
    if (!highlight) return text;

    const parts = text.split(new RegExp(`${highlight}`, 'gi'));

    return parts.map((part, index) =>
        (index === 1) ? (
            <span key={index} style={{ color: 'var(--color-main-theme-light)', fontWeight: 'bolder' }}>{part}</span>
        ) : (
            part
        )
    );
};
const titleStyle = {
    color: 'var(--color-main-theme-light)',
    fontWeight: 'bold'
};
const h2Style = {

    fontWeight: 'bold'
};
const pStyle = {
    fontSize: '1rem'
};
const compContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0',
    padding: '1.5rem',
    background: 'var(--color-gray-tint1)',
    borderRadius: '12px',
    border: '1px solid var(--color-gray-tint)',
    boxShadow: 'inset 0px 0px 12px 2px var(--color-dark-shadow)',
    minWidth: '500px',
    maxWidth: '80vw'
};


const filterMap = {
    "missingDocs": "Docs",
    "missingGOP": "GOP",
    "missingProforma": "Proforma",
    "missingInvoice": "Invoice",
    "FlagMessage": "Sort By New Messages",
    "FlagError": "Filter By Warning",
}

export const UserOnboardModal = ({ openUserOnboard, setOpenUserOnboard, lang, setlang }) => {

    // const [animate, setAnimate] = useState(false);


    const [exampleSearchFilter, setExampleSearchFilter] = useState({});

    const filterString = (filter) => {
        let filterString = '';
        for (const key in filter) {
            if (filter[key] !== '' && key.includes('missing')) {
                filterString += `Filter by ${filter[key] ? 'Missing' : 'Uploaded'} ${filterMap[key] || key} `;
            } else if (filter[key] !== '') {
                filterString += `${filterMap[key]}`;
            }
        }
        return filterString;
    }

    const [state, setState] = useState(1);

    return (
        <Modal
            open={openUserOnboard}
            onClose={() => setOpenUserOnboard(false)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2rem 4rem'
            }}
        >
            <div className='userOnboardModalContainer'>

                <Typography sx={{ mt: 2, ...titleStyle }}>
                    {translations['introduction'][lang]}
                </Typography>
                <Typography>
                    {translations['welcomeMessage'][lang]}
                </Typography>
                <Typography sx={{ mt: 2, ...titleStyle }}>
                    {translations['features'][lang]}
                </Typography>
                <Typography component="div">
                    <ol style={{ padding: '0.5rem' }}>
                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['patientInfoManagement'][lang]}</Typography>
                            <ul>
                                <li>{translations['patientForm'][lang]}</li>
                                <li>{translations['searchFilter'][lang]}</li>
                            </ul>
                        </li>
                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['fileSharing'][lang]}</Typography>
                            <ul>
                                <li>{translations['fileShareTable'][lang]}</li>
                                <li>{translations['fileUploadNotifications'][lang]}</li>
                            </ul>
                        </li>
                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['automaticMailManagement'][lang]}</Typography>
                            <ul>{ }
                                <li>{highlightTextQuotes(translations['automaticMail'][lang], '“(.*?)”')}</li>
                                <li>{translations['messaging'][lang]}</li>
                            </ul>
                        </li>
                    </ol>
                </Typography>
                <Typography sx={{ mt: 2, ...titleStyle }}>
                    {translations['howToUse'][lang]}
                </Typography>
                <Typography component="div">
                    <ol>
                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['switchingLanguages'][lang]}</Typography>
                            <ul>
                                <li>{translations['useLanguageSelector'][lang]}</li>
                                <div style={{ ...compContainerStyle, flexDirection: 'row', gap: '2rem', justifyContent: 'flex-start', paddingInline: '3rem' }}>
                                    <LanguageSelector
                                        sx={{ marginLeft: 'none' }}
                                        lang={lang}
                                        setlang={setlang}
                                    />
                                </div>

                            </ul>
                        </li>
                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['managingPatientInfo'][lang]}</Typography>
                            <ul>
                                <li>{translations['clickPatientCase'][lang]}</li>
                            </ul>
                            <ul>
                                <li>{highlightText(translations['referenceTypes'][lang], 'RBK### / TUR 202#-###')}</li>
                            </ul>
                        </li>
                        <div
                            style={{
                                ...compContainerStyle
                            }}
                        >
                            <ExampleTable lang={lang} />
                        </div>


                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['fileSharingFeature'][lang]}</Typography>
                            <ul>
                                <li>{translations['uploadFiles'][lang]}</li>
                                <li>{translations['fileUploadShortcuts'][lang]}</li>

                                <div style={{
                                    ...compContainerStyle, gap: '2rem'
                                }}>
                                    <div style={{ width: '100%' }}>
                                        <UploadedFilesComponent
                                            key={'onboard-files-comp'}
                                            onRowFileURLs={exampleData.hospCases[0].files}
                                            selectedhospCaseUserID={'21321'}
                                            selectedhospCaseHospID={'123456789012345678901234'}
                                            selectedRow={exampleData}
                                            opMode={false}
                                            lang={lang}
                                            setsnackbarStatus={() => { }}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className='p-datatable p-datatable-selectable' style={{ width: '100%' }}>
                                        <table className='p-datatable-table' style={{ width: '100%', borderCollapse: 'collapse' }}>

                                            <tbody className='p-datatable-tbody'>

                                                <tr className='p-datatable-row defaultRow'>

                                                    <td style={{ ...tableRowStyle, minWidth: '400px', width: '100%' }}>
                                                        <CompletionBar
                                                            key={`${exampleData?.RBKCaseNo}-onboard-gop`}
                                                            uploadShortcutMutation={() => { }}
                                                            docProgress={[0, 0, 0, 0]}
                                                            RBKCaseNo={exampleData.RBKCaseNo}
                                                            caseNumber={exampleData.caseNumber}
                                                            index={0}
                                                            hospCaseAmount={1}
                                                            barUserID={'10083'}

                                                        /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>


                                <li>{translations['exportExcel'][lang]}</li>
                                <div
                                    style={{
                                        ...compContainerStyle, flexDirection: 'row'
                                    }}
                                >
                                    <div className='maindataTableFooter dataTableHeaderBottom dataTableHeaderButtonGroup' style={{
                                        width: '100%',
                                        padding: '10px 0px 10px 20px',

                                    }}>
                                        <PatientListExcelButton
                                            lang={lang}
                                            patientList={exampleList}
                                            opMode={false}
                                            nameOverride={'Patient List Example'}
                                            worksWithTL={true}
                                        />
                                    </div>
                                </div>

                                <li>{translations['filterButtons'][lang]}</li>
                                <div style={{ ...compContainerStyle, flexDirection: 'row', gap: '1rem' }}>
                                    <Typography sx={{ ...pStyle, mr: 'auto', fontWeight: 'bold' }}>
                                        {`${filterString(exampleSearchFilter) || 'No Filters'}`}
                                    </Typography>
                                    <div className='PipelineFilterRow'>

                                        <DashboardFilterButtons
                                            searchFilter={exampleSearchFilter}
                                            setSearchFilter={setExampleSearchFilter}
                                            patientsQuery={{
                                                isFetching: false,
                                                isSuccess: true,
                                                data: {
                                                    data: {
                                                        dashboard: {
                                                            "totalPatientCount": 27,
                                                            "missingDocsCount": 22,
                                                            "missingGOPCount": 10,
                                                            "missingProformaCount": 7,
                                                            "missingInvoiceCount": 8,
                                                            "newMessageCount": 5
                                                        }
                                                    }
                                                }
                                            }}
                                            lang={lang}
                                            opMode={false}
                                            viewportWidth={2000}
                                        />
                                    </div>

                                </div>

                            </ul>
                        </li>
                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['patientFormFeature'][lang]}</Typography>
                            <ul>
                                <li>{translations['accessTabs'][lang]}</li>
                                <li>{translations['patientInfo'][lang]}</li>
                                <li>{translations['messages'][lang]}</li>
                                <div style={{
                                    ...compContainerStyle, flexDirection: 'row', gap: '2rem', padding: '2rem', justifyContent: 'flex-start'
                                }}>
                                    <div style={{ width: '100%', height: '400px', maxWidth: '620px', border: '2px solid var(--color-dark-shadow)', boxShadow: '0px 0px 12px 4px var(--color-dark-shadow)' }}>
                                        <CommentsComponent
                                            selectedRow={exampleData}
                                            selectedhospCaseUserID={'10083'}
                                            selectedhospCaseHospID={'123456789012345678901234'}
                                            setsnackbarStatus={() => { }}
                                            opMode={false}
                                            paymentMode={false}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <li>{translations['documentPreview'][lang]}</li>

                                <div style={{
                                    ...compContainerStyle, flexDirection: 'row'
                                }}>
                                    <img alt="Notification Example" src={googleSettingImage4} style={{ width: '100%', boxShadow: '4px 4px 12px 4px var(--color-dark-shadow)' }} />
                                </div>



                                <li>{translations['gop'][lang]}</li>

                                <div style={{ ...compContainerStyle, flexDirection: 'row' }}>
                                    <GOPStateSelector
                                        lang={lang}
                                        selectedhospCaseUserID={null}
                                        selectedhospCaseHospID={null}
                                        RBKCaseNo={null}
                                        setsnackbarStatus={() => { }}
                                        initialValue={1}
                                        demoMode={true}
                                        setState={setState}
                                        state={state}
                                    />


                                    <CompletionBar
                                        key={`${exampleData?.RBKCaseNo}-onboard-gop`}
                                        uploadShortcutMutation={() => { }}
                                        docProgress={[1, state, 0, 0]}
                                        RBKCaseNo={exampleData.RBKCaseNo}
                                        caseNumber={exampleData.caseNumber}
                                        index={0}
                                        hospCaseAmount={1}
                                        barUserID={'10083'}

                                    />
                                </div>

                            </ul>
                        </li>
                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['userSettings'][lang]}</Typography>

                            <ul>

                                <li>{translations['authapp'][lang]}</li>

                                <div style={{
                                    ...compContainerStyle, flexDirection: 'row', gap: '2rem', padding: '2rem', justifyContent: 'flex-start'
                                }}>
                                    <NavBarExample
                                        highlightButton={0}
                                        lang={lang}
                                    />
                                    <UserSettings
                                        lang={lang}
                                        setsnackbarStatus={() => { }}
                                        demoMode={1}
                                    />


                                </div>
                                <li>{translations['mailSetting'][lang]}</li>

                                <div style={{
                                    ...compContainerStyle, flexDirection: 'row', gap: '2rem', padding: '2rem', justifyContent: 'flex-start'
                                }}>
                                    <NavBarExample
                                        highlightButton={0}
                                        lang={lang}
                                    />
                                    <UserSettings
                                        lang={lang}
                                        setsnackbarStatus={() => { }}
                                        demoMode={2}
                                    />


                                </div>

                            </ul>
                        </li>
                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['receivingNotifications'][lang]}</Typography>

                            <ul>

                                <li>{translations['enableNotifications'][lang]}</li>

                                <div style={{
                                    ...compContainerStyle, flexDirection: 'row', gap: '2rem', padding: '2rem', justifyContent: 'flex-start'
                                }}>
                                    <NavBarExample
                                        highlightButton={3}
                                        lang={lang}
                                    />
                                    <img alt="Notification Example" src={NotificationImageA} style={{ height: '400px', boxShadow: '4px 4px 12px 4px var(--color-dark-shadow)' }} />


                                </div>
                                <li>{translations['browserNotifications'][lang]}</li>
                                <div style={{
                                    ...compContainerStyle, flexDirection: 'row', gap: '2rem', padding: '2rem', justifyContent: 'center'
                                }}>

                                    <img alt="Notification Example" src={NotificationImageB} style={{ width: '50%', }} />

                                </div>

                            </ul>
                        </li>

                        <li>
                            <Typography sx={{ ...h2Style }}>{translations['viewingActivityLog'][lang]}</Typography>
                            <ul>
                                <li>{translations['openActivityLog'][lang]}</li>

                                <div style={{ ...compContainerStyle, flexDirection: 'row', gap: '2rem', justifyContent: 'flex-start' }}>
                                    <NavBarExample
                                        highlightButton={2}
                                        lang={lang}
                                    />

                                    <div style={{ width: '100%', maxWidth: '400px' }}>
                                        {exampleLogList.map((log, index) => {

                                            return <ActivityLogItem
                                                key={`onboard-activity-log-${index}`}
                                                log={log}
                                                index={index}
                                                hospitalLabel={'Hastane'}
                                                mainActivityLog={false}
                                                disableClick={true}
                                            />

                                        })}

                                    </div>


                                </div>
                            </ul>
                        </li>

                    </ol>
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenUserOnboard(false)}
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        color: 'var(--color-error)',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        </Modal>
    )
};



