import { Snackbar, Typography } from '@mui/material';
import React from 'react';

import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';

const Alert = styled(MuiAlert)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? '#50575ed4' : '#40454ad4',
    '& .MuiAlert-icon': {
        fontSize: '1.5rem',
        marginRight: '0.5rem',

    },
    '&.MuiPaper-root': {
        outline: '2px solid rgb(128, 128, 128)',
        boxShadow: '0px 0px 16px 4px rgb(0 0 0), inset 0px 0px 12px -4px rgb(0 0 0 / 75%);',
    },

    '& .MuiAlert-message': {
        fontSize: '1rem',
        fontWeight: 'bolder',
        color: 'white'
    },
    '&.MuiAlert-filledSuccess': {
        backgroundColor: 'green',
        color:'#01bc01',
        outline:'none',
        borderLeft: '4px solid #01bc01',

    },
    '&.MuiAlert-filledError': {
        backgroundColor: 'rgb(114,7,7)',
        color:'rgb(168, 11, 11)',
        outline:'none',
        borderLeft: '4px solid rgb(168, 11, 11)',


    },
    '&.MuiAlert-filledWarning': {
        backgroundColor: 'rgb(255, 146, 0)',
        color:'rgb(255, 146, 0)',
        outline:'none',
        borderLeft: '4px solid rgb(255, 146, 11)',
    },
    '&.MuiAlert-root': {

        paddingBlock:'0.75rem',
        borderRadius: '8px 8px 0px 0px',
        backgroundColor: 'rgba(39, 44, 48, 0.75)',
        backdropFilter: 'blur(6px)',
        marginBottom:'-16px',

    },



}));

export const CustomSnackbar = ({snackbarStatus, setsnackbarStatus}) => {
    const styleSettings = snackbarStatus?.selectable ? { } : { pointerEvents: 'none', userSelect:'none' };

    return (<Snackbar open={snackbarStatus?.open} autoHideDuration={snackbarStatus?.duration || 3500} onClose={(event, reason) => {
        if (reason === 'clickaway') { return; };
        setsnackbarStatus({ ...snackbarStatus, open: false });
    }}>
        <Alert variant='filled' onClose={(event, reason) => { if (reason === 'clickaway') { return; }; setsnackbarStatus({ ...snackbarStatus, open: false }); }} severity={snackbarStatus?.severity} sx={{ width: '100%' }}>
            <Typography sx={{ ...styleSettings }}>{snackbarStatus?.message}</Typography>
        </Alert>
    </Snackbar>);
}
