import {
    Button, Typography,
    Modal,
    Box,
    IconButton,
    CircularProgress
} from '@mui/material';
import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import Slider from '@mui/material/Slider';
import TextLanguage from '../../data/TextLanguage.json';

import { interpolateColor } from '../../functions/interpolateColor';
import { useActivePaymentListAggrQuery } from './hooks/useActivePaymentListAggrQuery';
import { useCreatePaymentListMutation } from './hooks/useCreatePaymentListMutation';
import { useQueryClient } from '@tanstack/react-query';

import numeral from 'numeral';
// import { getReducedHospList } from '../functions/getReducedHospList';

const PaymentDateSlider = styled(Slider)({


    height: 10,
    '& .MuiSlider-track': {
        border: '1px solid #909dad8f',
        background: '#0f64b2a6',
        backdropFilter: 'blur(24px)',

    },
    '& .MuiSlider-rail': {
        background: 'linear-gradient(90deg, #eb0400 0%, #00eb37 100%)',
        outline: '4px solid rgba(8, 8, 8, 0.425)',
        boxShadow: '0 0 16px 2px rgba(0, 0, 0 ,0.8), inset 0 0 8px 0px rgba(0, 0, 0 ,0.8)',

    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        zIndex: 15,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        zIndex: 5,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -65%) rotate(-45deg) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -65%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

export const PaymentListCreator = ({
    lang,
    paymentListModalOpen, setPaymentListModalOpen,
    modalPosition,
    setmodalPosition,


    setsnackbarStatus
}) => {


    const queryClient = useQueryClient();

    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const hospitalFilterList = hospitalUserListData?.data;
    // getReducedHospList(hospitalUserListData?.data)?.sort((a, b) => a?.label?.localeCompare(b?.label));

    const createPaymentListMutation = useCreatePaymentListMutation({ setsnackbarStatus, setPaymentListModalOpen, setmodalPosition });

    const handleModalFormClose = () => {
        setPaymentListModalOpen(false);
        setmodalPosition('100%')
    };

    const [selectedHospitalList, setSelectedHospitalList] = useState([]);
    const handleCheckboxSelect = (hospID) => (e) => {
        console.log(e.target.checked);
        if (e.target.checked && !selectedHospitalList.includes(hospID)) {
            setSelectedHospitalList([...selectedHospitalList, hospID]);
        } else if (!e.target.checked && selectedHospitalList.includes(hospID)) {
            setSelectedHospitalList(selectedHospitalList?.filter((id) => id !== hospID))
        }

    };
    const handleSelectAll = () => {
        const allHospIDs = activePaymentAggrData?.aggrByHosp?.map((hospitalEntry) => hospitalEntry.hospID);

        setSelectedHospitalList(allHospIDs)
    }
    const handleDeselectAll = () => {
        setSelectedHospitalList([])
    }
    const handleCreatePaymentList = async () => {

        const updaterData = { hospIDArray: selectedHospitalList, dueDateValue: selectedDueDate };
        createPaymentListMutation.mutate({ updaterData: updaterData });

    }


    const [sliderValue, setSliderValue] = useState(30);
    const [selectedDueDate, setSelectedDueDate] = useState(30);
    const sliderDebounceRef = React.useRef(null);
    const handleSliderChange = (e, newValue) => { // debounce this
        setSliderValue(newValue);
        if (sliderDebounceRef.current) {
            clearTimeout(sliderDebounceRef.current);
        }
        sliderDebounceRef.current = setTimeout(() => {
            setSelectedDueDate(newValue);
        }, 500);

    }

    const activePaymentAggrQuery = useActivePaymentListAggrQuery({
        hospIDArray: selectedHospitalList,
        dueDateValue: selectedDueDate
    });
    const [activePaymentAggrData, setActivePaymentAggrData] = useState([])
    useEffect(() => {
        if (activePaymentAggrQuery.isSuccess) {
            setActivePaymentAggrData(activePaymentAggrQuery.data?.data)
        }


    }, [activePaymentAggrQuery.data, activePaymentAggrQuery.isSuccess, activePaymentAggrQuery.isFetching])


    const totalTL = activePaymentAggrData?.totals?.GOPTotalTL;

    // format by adding , between every 3 digits and 2 digits after comma
    const parsedTLPrice = numeral(totalTL / 100).format('0,0.00').toUpperCase();

    return (<Modal
        onClose={handleModalFormClose}
        open={paymentListModalOpen}
    >
        <div className="modal-container"
            style={{
                width: 'auto', transform: 'translateY(-50%)',
                left: `${modalPosition}`, transition: '0.5s ease-in-out 0s !important',
                maxWidth: '100vw' , position: 'relative'
            }}>
            <IconButton
                aria-label="close"
                onClick={handleModalFormClose}
                fontSize="large"
                sx={{
                    position: 'absolute', right: 42, top: 32,
                    color: 'red',
                    zIndex: 1000,
                    borderRadius: '8px',
                    '&:hover': { background: 'transparent' }
                }}
            >
                <CloseIcon />
            </IconButton>
            <div className='form-container'>
                <div className='paymentListCreatorContainer'>

                    <div className='paymentListCreatorHeader'>
                        <div className='paymentListCreatorHeaderTop'>
                            <Typography style={{ color: 'white', fontSize: '1.25rem', fontWeight: 'bold' }}>{`${TextLanguage["TotalPatients"][lang]}: ${activePaymentAggrData?.totals?.totalCount ? activePaymentAggrData?.totals?.totalCount : 0}`}</Typography>
                            <Typography style={{ color: 'white', fontSize: '1.25rem', fontWeight: 'bold' }}>
                                {`${TextLanguage["DueDate"][lang]}: ${selectedDueDate} ${TextLanguage["Days"][lang]}`}

                            </Typography>
                            <Typography style={{ color: 'white', fontSize: '1.25rem', fontWeight: 'bold' }}>{`${TextLanguage["TotalPrice"][lang]}: ${activePaymentAggrData?.totals?.GOPTotalTL ? `${parsedTLPrice} ₺` : `0.00 ₺`}`}</Typography>
                        </div>
                        <div className='paymentListCreatorHeaderBottom'>
                            <div style={{ width: '6rem', justifyContent: 'flex-end', display: 'flex' }}>
                                <CircularProgress size={'2rem'} style={{ color: 'lightblue', display: activePaymentAggrQuery.isFetching ? 'inline-block' : 'none' }} />
                            </div>
                            <div className='paymentListCreatorSliderContainer' >
                                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', width: '100%', maxWidth:'60px', textAlign: 'center' }}>
                                    {`${selectedDueDate} ${TextLanguage["Days"][lang]}`}
                                </Typography>
                                <PaymentDateSlider
                                    sx={{
                                        '& .MuiSlider-valueLabel': {
                                            backgroundColor: `${interpolateColor(sliderValue, 10, 60, "hsl(0, 100%, 44%)", "hsl(98, 99%, 44%)")} !important`,
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }
                                    }}
                                    value={sliderValue}
                                    onChange={handleSliderChange}
                                    valueLabelDisplay="auto"
                                    defaultValue={30}
                                    max={60}
                                    min={10}
                                />
                            </div>
                            <Button sx={{ width: '100%', borderRadius: '0px', maxWidth: '10rem' }} onClick={handleSelectAll}>
                                {TextLanguage["SelectAll"][lang]}
                            </Button>
                            <Button sx={{ width: '100%', borderRadius: '0px', maxWidth: '10rem' }} onClick={handleDeselectAll}>
                                {TextLanguage["DeselectAll"][lang]}
                            </Button>
                            <Button sx={{ width: '100%', borderRadius: '0px', maxWidth: '6rem' }} onClick={handleCreatePaymentList}>
                                {TextLanguage["Create"][lang]}
                            </Button>
                        </div>

                    </div>



                    {hospitalFilterList &&
                    hospitalFilterList?.filter((hospitalEntry) => activePaymentAggrData?.aggrByHosp?.find((hosp) => { return (hosp.hospID === hospitalEntry.hospID) }))
                    .sort((a, b) => {
                        const totalA = activePaymentAggrData?.aggrByHosp?.find((hosp) => { return (hosp.hospID === a.hospID) })?.totalCount;
                        const totalB = activePaymentAggrData?.aggrByHosp?.find((hosp) => { return (hosp.hospID === b.hospID) })?.totalCount;
                        return totalB - totalA;
                    })
                    .map((hospitalEntry, index) => {
                        const totalsObject = activePaymentAggrData?.aggrByHosp?.find((hosp) => { return (hosp.hospID === hospitalEntry.hospID) }) ?
                            activePaymentAggrData?.aggrByHosp?.find((hosp) => { return (hosp.hospID === hospitalEntry.hospID) })
                            : {
                                hospID: hospitalEntry.hospID,
                                GOPTotalTL: 0,
                                totalCount: 0,
                                GOPTotalUSD: 0
                            };

                        const parsedPrice = numeral(totalsObject?.GOPTotalTL / 100).format('0,0.00');


                        return (


                            <div key={`paymentListCreatorHospitalElement-${index}-key`} className='paymentListCreatorHospitalElement'>
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{'& .Mui-checked': {color: 'rgba(33,194,27,0.7)'}}}
                                        control={
                                            <Checkbox
                                                disabled={!totalsObject?.totalCount}
                                                checked={totalsObject?.totalCount > 0 && selectedHospitalList.includes(hospitalEntry.hospID)}
                                                onChange={handleCheckboxSelect(hospitalEntry.hospID)}
                                                sx={{
                                                    color: 'rgb(49,80,125)',
                                                    '&.Mui-checked': {
                                                        color: 'rgba(33,194,27,0.7)',
                                                    },
                                                }}
                                            />}
                                        label={hospitalEntry.hospitalName} />
                                </FormGroup>
                                <div className='paymentListCreatorHospitalElementTableInfo'>

                                    <Typography sx={{
                                        borderLeft: '2px solid rgba(165, 165, 165, 0.3)',
                                        height: '100%', paddingLeft: '1rem',
                                        color: (totalsObject?.totalCount > 0 && selectedHospitalList.includes(hospitalEntry.hospID)) ? 'rgb(118, 248, 114)' :
                                            !totalsObject?.totalCount ? 'gray' : 'white'
                                    }}>

                                        {`${totalsObject?.totalCount}`}
                                    </Typography>

                                    <Typography sx={{
                                        borderLeft: '2px solid rgba(165, 165, 165, 0.3)',
                                        height: '100%', paddingLeft: '1rem',
                                        color: (totalsObject?.totalCount > 0 && selectedHospitalList.includes(hospitalEntry.hospID)) ? 'rgb(118, 248, 114)' :
                                            !totalsObject?.totalCount ? 'gray' : 'white'
                                    }}
                                    >
                                        {`${parsedPrice} ₺`}
                                    </Typography>
                                </div>

                            </div>
                        );
                    })}
                </div>
            </div>
        </div>

    </Modal>);
}
