import React, { useState } from 'react';
import { PatientEditForm } from './components/PatientEditForm';
import { PatientInfoComponent } from './components/PatientInfoComponent';





export const PatientInfoPageComponent = ({
    selectedRow,
    opMode,
    lang,
    selectedhospCaseUserID,
    selectedhospCaseHospID,
    setselectedhospCaseUserID,
    setselectedhospCaseHospID,
    setsnackbarStatus,
    patientForm,
    dispatch,
    handleFieldChange,
    paymentMode,
    onRowFileURLs,
    setRemoveFormOpen
}) => {


    const currencies = [
        {
            value: '0$',
            label: '0 $',
        },
        {
            value: '0€',
            label: '0 €',
        },
        {
            value: '25$',
            label: '25 $',
        },
        {
            value: '30$',
            label: '30 $',
        },
        {
            value: '25€',
            label: '25 €',
        },
        {
            value: '30€',
            label: '30 €',
        },
    ];










    const [formEditmodeDisplay, setformEditmodeDisplay] = useState(true); // in display mode show the retrieved values





    return (<>



        {

            (
                formEditmodeDisplay ?
                    <PatientInfoComponent
                        selectedRow={selectedRow}
                        opMode={opMode}
                        setformEditmodeDisplay={setformEditmodeDisplay}
                        lang={lang}
                        selectedhospCaseUserID={selectedhospCaseUserID}
                        selectedhospCaseHospID={selectedhospCaseHospID}
                        setselectedhospCaseUserID={setselectedhospCaseUserID}
                        setselectedhospCaseHospID={setselectedhospCaseHospID}
                        setsnackbarStatus={setsnackbarStatus}
                        onRowFileURLs={onRowFileURLs}
                        patientForm={patientForm}
                        paymentMode={paymentMode}
                        setRemoveFormOpen={setRemoveFormOpen}
                    />
                    : // EDIT MODE - FORM

                    <PatientEditForm
                        dispatch={dispatch}
                        setformEditmodeDisplay={setformEditmodeDisplay}
                        selectedRow={selectedRow}
                        opMode={opMode}
                        lang={lang}
                        patientForm={patientForm}
                        handleFieldChange={handleFieldChange}
                        currencies={currencies}
                    />
            )
}

    </>);
}



