import React, { useEffect, useRef, useState, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/tr';

import numeral from 'numeral';

import { useNavigate, useSearchParams } from "react-router-dom";

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
// Custom Components

// Custom Functions

// Data (JSON)


import { useTheme } from '@mui/material/styles';


import { useQueryClient } from '@tanstack/react-query';


import useLocalStorage from '../hooks/useLocalStorage';

import { useValidateQuery } from '../hooks/useValidateQuery';

import { CustomSnackbar } from '../components/CustomSnackbar';

import NavigationBar from '../components/NavigationBar';

import { styled } from '@mui/material/styles';

import { useRefreshQuery } from '../components/Login/useRefreshQuery';
import { DataTable } from 'primereact/datatable';

import { Column } from 'primereact/column';
import { rowClass } from '../components/DataTable/ColumnFormats/rowClass';
import { TextField, Typography } from '@mui/material';


import { DataTableInsuranceHeader } from '../components/PatientFormContainer/pages/InsurancePage/DataTableInsuranceHeader';
import { useInsuredPatientListQuery } from '../components/PatientFormContainer/pages/InsurancePage/hooks/useInsuredPatientListQuery';
import { LanguageSelector } from '../components/LanguageSelector';
import Paginator from '../components/DataTable/Paginator';

import { userTypesRBK } from '../data/userTypes';

//import "primereact/resources/themes/lara-light-indigo/theme.css";


//core
const FormField = styled(TextField)(({ theme }) => ({
    color: theme.palette.text.primary,
    background: 'none',
    borderRadius: 0,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    width: '100%',
    maxWidth: '800px',

    '&:hover, &.Mui-focusVisible': {

        background: 'none',
        borderRadius: 0,
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
    },
    '.MuiInputBase-formControl': {
        paddingInline: '0.25rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },

    '.MuiInputBase-inputMultiline': {
        paddingInline: '0.5rem 0.5rem !important',
        paddingBlock: '0.25rem !important'
    },
    '.MuiInputBase-root': {
        '&:before': {
            borderBottom: 'none !important'
        }
    },
    '.MuiSelect-select': {
        border: 'none !important',
        paddingInline: '0.5rem 2.5rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },
    '.MuiFormHelperText-root': { color: theme.palette.text.primary },
}));

function remToPx(rem) {
    return (rem * parseFloat(getComputedStyle(document.documentElement).fontSize))
}


const policyDateBody = (rowData) => {

    const dateStart = new Date(rowData.policyStart);
    const dateStartOneDayBefore = new Date(dateStart);
    dateStartOneDayBefore.setDate(dateStart.getDate() - 1);


    const dateEnd = new Date(rowData.policyEnd);
    const dateEndOneDayBefore = new Date(dateEnd);
    dateEndOneDayBefore.setDate(dateEnd.getDate() - 1);

    return (
        <Typography>
            {`${dateStartOneDayBefore.getDate()}/${dateStartOneDayBefore.getMonth() + 1}/${dateStartOneDayBefore.getFullYear()} - ${dateEndOneDayBefore.getDate()}/${dateEndOneDayBefore.getMonth() + 1}/${dateEndOneDayBefore.getFullYear()}`}
        </Typography>
    )
}
const dobBody = (rowData) => {

    const date = new Date(rowData.dob);
    const oneDayBefore = new Date(date);
    oneDayBefore.setDate(date.getDate() - 1);

    return (
        <Typography>
            {`${oneDayBefore.getDate()}/${oneDayBefore.getMonth() + 1}/${oneDayBefore.getFullYear()}`}
        </Typography>
    )
}


export function InsurancePage() {

    const theme = useTheme();

    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    const queryClient = useQueryClient();


    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);



    useEffect(() => {

        queryClient.invalidateQueries({ queryKey: ['patients'] , refetchType: 'active'}, {cancelRefetch: false});
        queryClient.invalidateQueries({ queryKey: ['validate'] , refetchType: 'active'}, {cancelRefetch: false});
        queryClient.invalidateQueries({ queryKey: ['activityLog'] , refetchType: 'active'}, {cancelRefetch: false});

        const handleResize = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])


    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const [parsedList, setParsedList] = useState([]);

    const navigate = useNavigate();

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();

    const opMode = userTypesRBK.includes(validateQuery.data?.data?.userID);
    const confirmedUserID = validateQuery.data?.data?.userID;


    useEffect(() => {
        if (refreshQuery.isError && refreshQuery.failureCount >= 3 ) {
            console.log('Refresh Token Expired');
            queryClient.clear();
            navigate('/');
        } else if (refreshQuery.isError && refreshQuery.error?.response?.status === 403) {
            console.log('Refresh Token Expired');
            queryClient.clear();
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshQuery, refreshQuery.isError, refreshQuery.failureCount])




    useEffect(() => {
        if (validateQuery.isSuccess) {
            if (!userTypesRBK.includes(validateQuery.data.data?.userID)) {
                navigate("/");
            }
        }
    }, [validateQuery.data, validateQuery.isSuccess, validateQuery.isError])

    const datatableRef = useRef(null);

    const [selectedInsData, setSelectedInsData] = useState('VUSO');
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get('page')) || 0;
    const pageSize = parseInt(searchParams.get('pageSize')) || 50;

    const insuredPatientListQuery = useInsuredPatientListQuery({ insuranceCo: selectedInsData, page: page, limit: pageSize });

    const [uploadMode, setUploadMode] = useState(false);




    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

            <div className="App-main">

                <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />

                <NavigationBar
                    currentPage='insuranceInfo'
                    lang={lang}
                    opMode={opMode}
                    viewportWidth={viewportWidth}
                />

                <div className='dashboardPageContainer' >

                    <DataTable

                        id='divTable'
                        ref={datatableRef}

                        stripedRows
                        scrollable
                        scrollHeight="flex"
                        rowClassName={rowClass({ opMode, confirmedUserID })}
                        resizableColumns
                        header={
                            <DataTableInsuranceHeader
                                setParsedList={setParsedList}
                                setsnackbarStatus={setsnackbarStatus}
                                selectedInsData={selectedInsData}
                                setSelectedInsData={setSelectedInsData}
                                uploadMode={uploadMode}
                                setUploadMode={setUploadMode}
                            />
                        }
                        footer={
                            <div className='maindataTableFooter' style={{justifyContent:'flex-end'}}>
                                <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>

                                    <LanguageSelector lang={lang} setlang={setlang} />

                                    <Paginator
                                        component="div"
                                        count={insuredPatientListQuery?.data?.data?.totalCount || 0}
                                        rowsPerPageOptions={[50, 100, 150]} />

                                </div>
                            </div>
                        }
                        showGridlines
                        size="small"
                        dataKey="passFull"

                        className="DataGrid"
                        value={

                            uploadMode ? parsedList : insuredPatientListQuery?.data?.data?.patientList || []

                        }
                        style={{ width: '100%' }}
                        tableStyle={{ width: '100%' }}
                        emptyMessage="Upload an Excel File to Display Data"

                    >
                        {uploadMode && <Column key={'foundEntryCol'} field={'foundEntry'}
                            header={"New Entry?"} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }}
                            sortable={true}
                            body={(rowData) => <>{!rowData?.foundEntry ? <CheckIcon sx={{ color: theme.palette.successColor.main }} /> : <ClearIcon sx={{ color: theme.palette.errorColor.main }} />} </>}
                        />}

                        <Column key={'NameCol'} field={'Name'}
                            header={"Name"} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }}
                            sortable={true}
                            body={(rowData) => <Typography>{rowData?.Name}</Typography>}
                        />

                          <Column key={'policyNoCol'} field={'policyNo'}
                            header={"Policy No"} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }}
                            sortable={true}
                            body={(rowData) => <Typography>{rowData?.policyNo}</Typography>}
                        />
                          {selectedInsData === 'VUSO' && <Column key={'insAddConditionsCol'} field={'insAddConditions'}
                            header={"insAddConditions"} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }}
                            sortable={true}
                            body={(rowData) => <Typography>{rowData?.insAddConditions}</Typography>}
                        />}
                       {selectedInsData === 'Donaris_VIG' && <Column key={'country'} field={'country'}
                            header={"Country"} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }}
                            sortable={true}
                            body={(rowData) => <Typography>{rowData?.country}</Typography>}
                        />}


                          <Column key={'coverageAmountCol'} field={'coverageAmount'}
                            header={"coverageAmount"} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }}
                            sortable={true}
                            body={(rowData) => <Typography>{`${numeral((rowData?.coverageAmount)).format('0,0.00')

                            } ${rowData.insPremiumCurrency === 'EUR' ? '€' : '$'}`}</Typography>}
                        />



                        <Column key={'policyDateCol'} field={'policyStart'}
                            header={"Policy Validity"} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }}
                            sortable={true}
                            body={policyDateBody}
                        />
                        <Column key={'dobCol'} field={'dob'}
                            header={"Date of Birth"} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }}
                            sortable={true}
                            body={dobBody}
                        />


                        {Object.keys(parsedList[0] || {}).map((col, i) => {
                            if (['Name', 'dob', 'passType', 'passNo', 'InsuranceCo', 'policyStart', 'policyEnd', 'foundEntry'].includes(col)) {
                                return null
                            }
                            return (<Column key={i} field={col} header={col} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }} // wtf is going on with the width??
                                sortable={true}
                                body={(rowData) => <Typography>{rowData[col]}</Typography>}
                            />)
                        }
                        )

                        }

                    </DataTable>

                </div>


            </div>

        </LocalizationProvider>










    );
}


