import React, { useState, useEffect } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useTheme
} from '@mui/material';

import { useDownloadPDFSplitQuery } from '../../../../hooks/useDownloadPDFSplitQuery';

function base64ToUint8Array(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}




export function generateRange(n) {
    return Array.from({ length: n }, (_, i) => i);
}


export const PDFSplitterButton = ({
    maxPageCount,

    filetype,
    selectedhospCaseUserID,

    displayfileIndex,
    setsnackbarStatus,
    filename,

    selectedhospCaseHospID,
    caseNumber,
}) => {


    const theme = useTheme();

    const [selectedPages, setSelectedPages] = useState([]);


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [splitPDFURL, setSplitPDFURL] = useState(null);
    const downloadPDFSplitQuery = useDownloadPDFSplitQuery({
        setsnackbarStatus,
        caseNumber,
        selectedhospCaseUserID,
        selectedhospCaseHospID,
        displayfileIndex,
        filetype,
        setSplitPDFURL,
        selectedPages
    });

    useEffect(() => {
        if (downloadPDFSplitQuery.isSuccess && downloadPDFSplitQuery.data.data) {
            const bytes = base64ToUint8Array(downloadPDFSplitQuery.data.data);
            const blob = new Blob([bytes], { type: 'application/pdf' });
            const blobUrl = window.URL.createObjectURL(blob);
            setSplitPDFURL(blobUrl);


        } else if (downloadPDFSplitQuery.isError) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Uploading File`,
                    severity: 'error'

                }
            )
        }

        return () => {
            window.URL.revokeObjectURL(splitPDFURL);
        }
    }, [downloadPDFSplitQuery.isSuccess, downloadPDFSplitQuery.isError, downloadPDFSplitQuery.data,])



    const handleFileDownloaderButton = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCheckboxSelect = (index) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        const newSelectedPages = [...selectedPages];
        if (newSelectedPages.includes(index)) {
            newSelectedPages.splice(newSelectedPages.indexOf(index), 1);
        } else {
            newSelectedPages.push(index);
        }
        newSelectedPages.sort((a, b) => a - b);
        setSelectedPages(newSelectedPages);
    };
    const handleSelectAll = () => {
        setSelectedPages(generateRange(maxPageCount));
    };
    const handleDeselectAll = () => {
        setSelectedPages([]);
    };

    const handleSplitPDFDownload = () => {
        const a = document.createElement('a');
        a.href = splitPDFURL;

        a.download = `Page_${selectedPages.map(num => num + 1)}_${filename}`;
        a.click();
        handleClose();

    }





    return (<>

        <IconButton
            sx={{ width: '60px', color: 'white' }}
            disableRipple
            disableFocusRipple
            onClick={handleFileDownloaderButton}
        >
            <FileDownloadIcon />
        </IconButton>
        <Menu
            sx={{
                '& .MuiMenu-paper': {
                    backgroundColor: 'rgb(16, 16, 16)',
                    color: 'white',
                    borderRadius: '5px',
                },
                '& .MuiMenuItem-root': {
                    color: 'white',
                    padding: '0px 12px',
                },
                '& .MuiMenuItem-root:hover': {
                    backgroundColor: 'rgba(49,80,125,0.2)',
                },
                '& .MuiBackdrop-root': { background: 'transparent', backdropFilter: 'none' },
            }}

            id="pdfDownload-Menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem sx={{ paddingBlock: '8px !important' }}
                disabled={!splitPDFURL || downloadPDFSplitQuery.isFetching} onClick={handleSplitPDFDownload}>

                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', gap:'0.5rem'}}>
                    <Typography>{`Download`}</Typography>
                    {downloadPDFSplitQuery.isFetching && <CircularProgress sx={{color: theme.palette.mainColor.Sat}} size={16} />}
                </div>
            </MenuItem>

            <MenuItem sx={{ paddingBlock: '8px !important' }} onClick={handleSelectAll}>
                Select All
            </MenuItem>
            <MenuItem sx={{ paddingBlock: '8px !important' }} onClick={handleDeselectAll}>
                Deselect All
            </MenuItem>
            {generateRange(maxPageCount).map((index) => {
                return (<MenuItem sx={{ fontSize: '0.2rem !important' }} onClick={handleCheckboxSelect(index)}>
                    <FormGroup>
                        <FormControlLabel
                            sx={{
                                '& .Mui-checked': {
                                    color: 'rgba(33,194,27,0.7)',
                                },
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '1rem',
                                },
                            }}
                            control={<Checkbox

                                checked={selectedPages.includes(index)}

                                sx={{
                                    color: 'rgb(49,80,125)',
                                    '&.Mui-checked': {
                                        color: 'rgba(33,194,27,0.7)',
                                    },
                                }} />} label={`Page ${index + 1}`} />
                    </FormGroup>
                </MenuItem>);
            })}
        </Menu>
    </>);
}
