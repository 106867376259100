

import React, { useState,useEffect } from 'react';

import FileSelectorHeader from '../../components/FileSelectorHeader';


import numeral from 'numeral';
import TextLanguage from '../../../../data/TextLanguage.json';


import {
    TextField,
    Button,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
    InputAdornment,

} from '@mui/material';


import CustomListItem from '../../components/CustomListItem';


import { useReviewCaseMutation } from './hooks/useReviewCaseMutation';
import { useQueryClient } from '@tanstack/react-query';
import { DatePicker } from '@mui/x-date-pickers';

import dayjs from 'dayjs';
import { displayCaseNo } from '../../../../functions/displayCaseNo';

export const ReviewPageComponent = ({
    lang,
    setformOpen,
    onRowFileURLs,
    selectedhospCaseUserID,
    selectedhospCaseHospID,
    displayfileIndex,
    setdisplayfileIndex,
    fileDisplayPdfUrl,
    selectedRow,
    opMode,
    setsnackbarStatus,
    fileFetchQuery
}) => {
    // selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseUserID)?.Invoice_Date
    // If no invoice date is available, Allow closing through 0 GOP Pipeline

    const queryClient = useQueryClient();
    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const isLDM = InsuranceCompanyListData?.data
        .filter((insCo) => insCo?.type === "LDM")
        .map((insCo) => insCo?.value)
        .includes(selectedRow.InsuranceCo);


    useEffect(() => {
        const invoiceIndex = onRowFileURLs?.findIndex((file) => file.filetype === 'invoice');
        if (invoiceIndex !== -1) {
            setdisplayfileIndex(invoiceIndex);
        }
    }, [])



    const [invoiceNo, setInvoiceNo] = useState(null);


    const [caseType, setCaseType] = useState(null);
    const [diagnosis, setDiagnosis] = useState('');
    const [serviceFee, setServiceFee] = useState(0);

    const reviewCaseMutation = useReviewCaseMutation({ setsnackbarStatus: setsnackbarStatus, setformOpen: setformOpen });

    const handleReviewConfirmSubmit = async () => {

        const invDate = dateTargetInvoiceDate?.$d ? new Date(dateTargetInvoiceDate?.$d) : new Date();

        const updaterData = {
            caseType: caseType,
            caseNumber: selectedRow?.caseNumber,
            selectedhospCaseHospID: selectedhospCaseHospID,
            diagnosis: diagnosis,
            serviceFee: serviceFee,
            invoiceDate: invDate,
            invoiceNo: invoiceNo
        }
        console.log(updaterData);
        reviewCaseMutation.mutate({ updaterData: updaterData })
    };


    const handleCloseCaseSubmit = async () => {
        const updaterData = {
            caseType: 'Closed',
            caseNumber: selectedRow?.caseNumber,
            selectedhospCaseHospID: selectedhospCaseHospID,
            serviceFee: serviceFee,
            invoiceDate: new Date(Date.now()),
            invoiceNo: 'Closed'
        }
        console.log(updaterData);
        reviewCaseMutation.mutate({ updaterData: updaterData })
    }

    const [dateTargetInvoiceDate, setdateTargetInvoiceDate] = useState(dayjs());
    const hospCase = selectedRow?.hospCases?.find((hospCase) => hospCase.hospID === selectedhospCaseHospID);

    const closeCaseDisabled = (!!hospCase?.GOPPriceUSD) ? true : false;

    return (
        (

            <div className='ReviewPageContainer'>
                <div className='ReviewPageListContainer'>

                <div className='DocsButtonControlsGroup'/>



                    <CustomListItem index={1} label={TextLanguage["RefNo"][lang]} value={displayCaseNo(selectedRow)} />
                    <CustomListItem index={1} label={TextLanguage["HospitalClinic"][lang]} value={hospitalUserListData?.data?.find((hosp) => hosp.hospID === selectedhospCaseHospID)?.hospitalName} />
                    <CustomListItem index={2} label={TextLanguage["InvoiceUploadDate"][lang]} value={hospCase?.Invoice_Date?.split('T')[0] || 'N/A'} />
                    <CustomListItem index={3} label={`${TextLanguage["GOPPrice"][lang]} (USD)`} value={`${numeral(hospCase?.GOPPriceUSD || 0).format('0,0')} $`} />
                    <CustomListItem index={4} label={`${TextLanguage["GOPPrice"][lang]} (TL)`} value={`${numeral((hospCase?.GOPPriceTL / 100) || 0).format('0,0.00')} ₺`} />
                    <CustomListItem index={5} label={`${TextLanguage["Franchise"][lang]}`} value={`${selectedRow?.franchise}`} />

                    <div className='ReviewPageFormContainer'>

                        {
                            !isLDM && <>
                                <div className='formRowReview' style={{ marginTop: '2rem' }}>
                                    <FormControl sx={{ minWidth: 150, width: '100%', maxWidth: '100%' }}>
                                        <InputLabel
                                            id="select-helper-label"
                                            sx={{ color: 'white' }}
                                            shrink={true}
                                        >
                                            {TextLanguage["CaseType"][lang]}
                                        </InputLabel>
                                        <Select
                                            sx={{
                                                width: '100%'
                                            }}
                                            value={caseType}
                                            onChange={(e) => { setCaseType(e.target.value) }}
                                        >
                                            <MenuItem key={`Outpatient-MenuItem`} value={'Outpatient'}>
                                                Outpatient
                                            </MenuItem>
                                            <MenuItem key={`Outpatient-(In Clinic)-MenuItem`} value={'Outpatient (In Clinic)'}>
                                                Outpatient (In Clinic)
                                            </MenuItem>
                                            <MenuItem key={`Inpatient-MenuItem`} value={'Inpatient'}>
                                                Inpatient
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='formRowReview'>
                                    <TextField
                                        label={TextLanguage["Diagnosis"][lang]}
                                        multiline
                                        rows={2}
                                        value={diagnosis}
                                        onChange={(e) => { setDiagnosis(e.target.value) }}
                                    />
                                </div>
                                <div className='formRowReview'>
                                    <TextField
                                        type="number"
                                        label={TextLanguage["ServiceFee"][lang]}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        value={serviceFee}
                                        onChange={(e) => { setServiceFee(e.target.value) }}
                                    />
                                </div>
                            </>
                        }

                        <div className='formRowReview'>
                            <TextField
                                type="number"
                                label={TextLanguage["InvoiceNo"][lang]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={invoiceNo}
                                onChange={(e) => { setInvoiceNo(e.target.value) }}
                            />

                        </div>
                        <div className='formRowReview'>
                            <DatePicker
                                sx={{ width: '100%', border: 'none' }}
                                label={TextLanguage["InvoiceDate"][lang]}
                                value={dateTargetInvoiceDate}
                                onChange={(date) => setdateTargetInvoiceDate(date)}
                                renderInput={(props) => <TextField {...props} />} />

                        </div>

                        <div className='formRowReview'>

                            <Button
                                sx={{
                                    height: '2.5rem', width: '100px', fontSize: '1rem', background: 'rgb(0, 107, 0) !important', borderRadius: '4px',
                                    '&:hover': { // button hover override
                                        outline: '2px solid green',
                                        boxShadow: '0px 0px 8px 2px rgba(14, 225, 14, 0.65)',
                                        background: 'rgb(0, 107, 0) !important',
                                        filter: 'brightness(1.25) saturate(1.25)',
                                    },


                                }}
                                onClick={handleReviewConfirmSubmit}
                            >
                                {TextLanguage["Approve"][lang]}
                            </Button>
                            <Button
                                disabled={closeCaseDisabled}
                                sx={{
                                     height: '2.5rem', width: '150px', fontSize: '1rem', background: 'var(--color-error-dark) !important', borderRadius: '4px',
                                    '&:hover': { // button hover override
                                        outline: '2px solid rgba(223, 92, 92 ,0.9)',
                                        boxShadow: '0px 0px 8px 2px rgba(227, 2, 2, 0.5)',
                                        background: 'var(--color-error) !important',
                                        filter: 'brightness(1.25) saturate(1.25)',
                                    },
                                }}
                                onClick={handleCloseCaseSubmit}
                            >
                                {TextLanguage["CloseCase"][lang]}
                            </Button>
                        </div>
                    </div>

                </div>



                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%', height: '100%' }}>

                    <FileSelectorHeader
                        onRowFileURLs={onRowFileURLs}
                        displayfileIndex={displayfileIndex}
                        setdisplayfileIndex={setdisplayfileIndex}
                        selectedhospCaseUserID={selectedhospCaseUserID}
                        selectedhospCaseHospID={selectedhospCaseHospID}
                        opMode={opMode}
                        switchMode={false}
                        filetype={'all'}
                        fileFetchQuery={fileFetchQuery}
                    />
                    <div className='ProformapdfContainer' style={{ width: '50vw' }}>
                        <iframe id='pdf-invoice' title="PDF Viewer Invoice" width="100%" height="580px" src={fileDisplayPdfUrl}></iframe>
                    </div>
                </div>

            </div>)
    )



}
