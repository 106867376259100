import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

const useUpdatePatientGOPMutation = ({setsnackbarStatus, paymentMode=false}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {


            return axios.post(
                `${config.API_URL}/data/updateGOP`,
                updaterData,
                {
                    withCredentials: true
                }
            )
        },
        onSuccess: (data, variables) => {

            !paymentMode && queryClient.invalidateQueries({ queryKey: ['patients'], refetchType: 'active'}, {cancelRefetch: false })
            paymentMode && queryClient.invalidateQueries({ queryKey: ['PaymentListPatient'], refetchType: 'active'}, {cancelRefetch: false });
            paymentMode && queryClient.invalidateQueries({ queryKey: ['PaymentListPatients'], refetchType: 'active'}, {cancelRefetch: false });


            !paymentMode ? queryClient.invalidateQueries({ queryKey: ['activityLog'], refetchType: 'active'}, {cancelRefetch: false }) : queryClient.invalidateQueries({ queryKey: ['activityLogPayment'], refetchType: 'active'}, {cancelRefetch: false });
            setsnackbarStatus({ open: true, message: "Updated GOP", severity: 'success' })

           // handleReplaceForm(data.data)

        },
        onError: (error) => {
            if (error?.response?.status === 409 ){
                setsnackbarStatus({ open: true, message: "GOP Price Already Set", severity: 'error' })
            } else {
                setsnackbarStatus({ open: true, message: "Error Updating GOP", severity: 'error' })
            }


        }
    });

}

export { useUpdatePatientGOPMutation };