
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const usePaymentListQuery = ({
    opMode,
    page,
    pageSize,
    //sortField,
    //sortOrder,
    searchFilter


}) => {


    async function fetchPaymentListPatients({ queryKey }) {
        const [_key, filtersData] = queryKey;
        return await axios.post(
            `${config.API_URL}/payment/getPaymentListPatients`,
            filtersData,  // Pass the filtersData directly as the second argument
            {
                withCredentials: true
            }
        );

    }


    return useQuery(
        {
            queryKey: ['PaymentListPatients',

                {
                    page: page,
                    pageSize: pageSize,
                    fNo: searchFilter?.caseNumber,
                    fadmDateS: searchFilter?.admissionDateStart,
                    fadmDateE: searchFilter?.admissionDateEnd,
                    fName: searchFilter?.name,
                    fLDMCaseNo: searchFilter?.LDMCaseNo,
                    fLDMCaseNoInt: searchFilter?.LDMCaseNoInt,
                    fLDMCaseNoYear: searchFilter?.LDMCaseNoInt ? searchFilter?.LDMCaseNoYear : null,
                    fHospID: searchFilter?.hospID,
                    fInsCo: searchFilter?.InsCo,
                    fprocessed: searchFilter?.processed,
                    fActivePaymentList: searchFilter?.onActivePaymentList,

                }

            ],
            queryFn: fetchPaymentListPatients,
            enabled: (!!opMode),
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes



        }
    )




}


