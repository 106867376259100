
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useActivateTwoFactorToggleMutation = ({setsnackbarStatus, setShowQR}) => {

     const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => {
            return axios.get(`${config.API_URL}/operator/enable2FA`, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['userSettings'], refetchType: 'active'}, {cancelRefetch: false });
            if (data.status === 200) {
                setsnackbarStatus({ open: true, message: 'Enter code from app to complete setup', severity: 'success' });
                setShowQR({state: true, code: data.data?.qrCodeUrl});
            } else if (data.status === 204) {
                setsnackbarStatus({ open: true, message: '2FA Removed', severity: 'success' });
                setShowQR({state: false, code: null});
            }
        },
        onError: (error, variables) => {
            setsnackbarStatus({ open: true, message: 'Invalid Code', severity: 'error' });
        }
    });
}
