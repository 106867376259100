import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/tr';

import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate, useSearchParams } from "react-router-dom";

import { useExchangeRateQuery } from '../hooks/useExchangeRateQuery';

// Data (JSON)

import TextLanguage from '../data/TextLanguage.json';

import { useTheme } from '@mui/material/styles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { ContextMenu } from 'primereact/contextmenu';

import { useQueryClient } from '@tanstack/react-query';

// Column Formats

import { formatCaseNo } from "../components/DataTable/ColumnFormats/formatCaseNo";
import { formatDateAdm } from "../components/DataTable/ColumnFormats/formatDateAdm";
import { formatInsuranceCompany } from "../components/DataTable/ColumnFormats/formatInsuranceCompany";

import { formatGOPPriceGroup } from '../components/DataTable/ColumnFormats/formatGOPPriceGroup';

import { formatPatientName } from "../components/DataTable/ColumnFormats/formatPatientName";
import { formatHospitalUserIDPayments } from "../components/DataTable/ColumnFormats/formatHospitalUserIDPayments";
import { formatHospitalUserID } from '../components/DataTable/ColumnFormats/formatHospitalUserID';
import { formatDateDeadline } from '../components/DataTable/ColumnFormats/formatDateDeadline';

import { formatGOPPriceGroupPayments } from '../components/DataTable/ColumnFormats/formatGOPPriceGroupPayments';

import { formatAvgUSDPayments } from '../components/DataTable/ColumnFormats/formatAvgUSDPayments';
import { formatAvgTLPayments } from '../components/DataTable/ColumnFormats/formatAvgTLPayments';
import { formatPatientCountPayments } from '../components/DataTable/ColumnFormats/formatPatientCountPayments';
import { GOPPriceGroupHeader } from '../components/DataTable/ColumnHeaders/GOPPriceGroupHeader';

import { rowClass } from '../components/DataTable/ColumnFormats/rowClass';

import useLocalStorage from '../hooks/useLocalStorage';
import { usePaymentListQuery } from '../components/Payment/hooks/usePaymentListQuery';
import { useValidateQuery } from '../hooks/useValidateQuery';
import { useActivePaymentListQuery } from '../components/Payment/hooks/useActivePaymentListQuery';
import { CustomSnackbar } from '../components/CustomSnackbar';
import { DataTablePaymentFooter } from '../components/DataTable/components/DataTablePaymentFooter';
import { DataTablePaymentHeader } from '../components/DataTable/components/DataTablePaymentHeader';
import { useHospitalUserListQuery } from '../hooks/useHospitalUserListQuery';
import { usePaymentTableList } from '../components/Payment/hooks/usePaymentTableList';

import { useArchivePaymentListQuery } from '../components/Payment/hooks/useArchivePaymentListQuery';
import { useArchivePaymentListGroupQuery } from '../components/Payment/hooks/useArchivePaymentListGroupQuery';
import { PaymentListCreator } from '../components/Payment/PaymentListCreator';
// import { useUploadReceiptMutation } from '../components/Payment/hooks/useUploadReceiptMutation';
import FileDownload from '@mui/icons-material/FileDownload';

import { useParsedPaymentListGroupQuery } from '../components/Payment/hooks/useParsedPaymentListGroupQuery';



import NavigationBar from '../components/NavigationBar';
import { useArchivedHospPatientsQuery } from '../components/Payment/hooks/useArchivedHospPatientsQuery';
import { useHospitalTokenListQuery } from '../hooks/useHospitalTokenListQuery';

//import "primereact/resources/themes/lara-light-indigo/theme.css";

//core

import { styled } from '@mui/material/styles';
import { Drawer, Modal, Typography } from '@mui/material';
import { useInsuranceCompanyListQuery } from '../hooks/useInsuranceCompanyListQuery';

import { useRefreshQuery } from '../components/Login/useRefreshQuery';
import { useParsedPaymentListHospQuery } from '../components/Payment/hooks/useParsedPaymentListHospQuery';
// import { displayCaseNo } from '../functions/displayCaseNo';
import { PatientFormContainer } from '../components/PatientFormContainer/PatientFormContainer';

// import Decimal from 'decimal.js';

import { userTypesRBK } from '../data/userTypes';
import { formatDateInvoice } from '../components/DataTable/ColumnFormats/formatDateInvoice';

import { ActivityLog } from '../components/PatientFormContainer/pages/PatientInfoPage/components/ActivityLog';
import { UserSettings } from '../components/UserSettings/UserSettings';

import { receiptUploadButtonTemplate } from '../components/Payment/templates/receiptUploadButtonTemplate';
import { invoiceButtonTemplate } from '../components/Payment/templates/invoiceButtonTemplate';

export const DownloadButton = styled(Button)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    minWidth: '25px',
    minHeight: '25px',
    padding: '5px',
    margin: '0px',

    background: 'rgb(30, 94, 28)',
    border: 'none',
    outline: '1px solid #787878',
    boxShadow: 'none',
    color: 'white',
    borderRadius: '0px',
    gap: '0.25rem',
    '&:hover': {
        background: 'rgb(30, 137, 26)',
        boxShadow: 'none',
        outline: '1px solid white',
        border: 'none',
    },


}));


export function PaymentPage() {

    const theme = useTheme();

    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    const queryClient = useQueryClient();

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();

    const opMode = userTypesRBK.includes(validateQuery.data?.data?.userID);
    const confirmedUserID = validateQuery.data?.data?.userID;


    useEffect(() => {

        const handleResize = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])


    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const hospitalUserListQuery = useHospitalUserListQuery();
    const insCoListQuery = useInsuranceCompanyListQuery({ opMode });
    const hospitalTokenListQuery = useHospitalTokenListQuery();

    // Context Menu need to fix InsuranceStart and InsuranceEnd, add diagnosis and caseType for the processing stage
    const contextMenuRef = useRef(null);



    const [selectedRows, setselectedRows] = useState(null);




    // Context Menu End

    const navigate = useNavigate();
    const [searchFilter, setSearchFilter] = useState({});

    const [searchParams, setSearchParams] = useSearchParams({ page: 0, pageSize: 50, currentTab: 0 });

    const page = parseInt(searchParams.get('page')) || 0;
    const pageSize = parseInt(searchParams.get('pageSize')) || 50;
    const currentTab = parseInt(searchParams.get('currentTab')) || 0;

    // Paginator ---

    const [selectedHospital, setselectedHospital] = useState({ userID: null, hospID: null });

    useEffect(() => {
        if (refreshQuery.isError && refreshQuery.failureCount >= 3) {
            console.log('Refresh Token Expired');
            queryClient.clear();
            navigate('/');
        } else if (refreshQuery.isError && refreshQuery.error?.response?.status === 403) {
            console.log('Refresh Token Expired');
            queryClient.clear();
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshQuery, refreshQuery.isError, refreshQuery.failureCount])


    useEffect(() => {
        if (validateQuery.isSuccess) {
            if (!userTypesRBK.includes(validateQuery.data.data?.userID)) {
                setSearchParams(prev => {
                    prev.set('currentTab', 3);
                    return prev;
                });
                // setselectedArchiveHospID(validateQuery.data.data?.userID);
                // const userHospData = hospitalUserListQuery.data?.data;
                // if (userHospData.length > 1) {
                //     setselectedHospital({userID: validateQuery.data.data?.userID, hospID: null});
                // }
                // setselectedHospital({userID: validateQuery.data.data?.userID, hospID: validateQuery.data.data?.userID});
            }
        }
    }, [validateQuery, validateQuery.isSuccess, validateQuery.data])


    const PaymentListPatientsQuery = usePaymentListQuery({
        opMode: opMode,
        page: page,
        pageSize: pageSize,
        searchFilter: searchFilter,
    });

    const [activePaymentList, setActivePaymentList] = useState([]);
    const [activePaymentState, setActivePaymentState] = useState(false);

    const exchangeRateQuery = useExchangeRateQuery({ opMode });

    useEffect(() => {
        if (exchangeRateQuery.isError && exchangeRateQuery?.error?.response?.status !== 401) {
            setsnackbarStatus({ open: true, message: `Error Fetching Exchange Rate`, severity: 'error' })
        }
    }, [exchangeRateQuery.data, exchangeRateQuery.isError]);


    const datatableRef = useRef(null);

    const [filterToggle, setFilterToggle] = useState(true);

    const ActivePaymentQuery = useActivePaymentListQuery({ opMode });


    useEffect(() => {
        if (ActivePaymentQuery.isSuccess) {
            if (ActivePaymentQuery?.data?.status === 200 && ActivePaymentQuery?.data?.data?.paymentList) {
                setActivePaymentState(ActivePaymentQuery?.data?.data?.paymentList.every((entry) => { return (entry?.receiptData) }));
                setActivePaymentList(ActivePaymentQuery?.data?.data?.paymentList);
            } else if (activePaymentState && ActivePaymentQuery?.data?.status === 204) { // no active paymentList, disable complete button
                setActivePaymentState(false);
                setActivePaymentList([]);
            }
        }
        if (ActivePaymentQuery.isError) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Active List`,
                    severity: 'error'
                }
            )
        }
    }, [ActivePaymentQuery])

    useEffect(() => {
        setselectedRows([]);
        setselectedHospital({ userID: null, hospID: null });
        if (!opMode && currentTab !== 3) {
            setSearchParams(prev => {
                prev.set('currentTab', 3);
                return prev;
            });
        }

        switch (currentTab) {
            case 0:
                setSearchFilter({ ...searchFilter, processed: false });
                queryClient.invalidateQueries({ queryKey: ['PaymentListPatients'], refetchType: 'active' }, { cancelRefetch: false });
                break;
            case 1:
                setSearchFilter({ ...searchFilter, processed: true, onActivePaymentList: false });
                queryClient.invalidateQueries({ queryKey: ['PaymentListPatients'], refetchType: 'active' }, { cancelRefetch: false });
                break;
            case 2:
                queryClient.invalidateQueries({ queryKey: ['ActivePaymentList'], refetchType: 'active' }, { cancelRefetch: false });
                break;
            case 3:
                queryClient.invalidateQueries({ queryKey: ['ActivePaymentList'], refetchType: 'active' }, { cancelRefetch: false });
                break;
            default:
                break;

        }

    }, [currentTab]) // on   currentTab change, update filters



    const parsedPaymentListGroupQuery = useParsedPaymentListGroupQuery({ opMode, enabled: currentTab === 4 });
    const [parsedSelectedHospital, setparsedSelectedHospital] = useState('');
    const parsedPaymentListHospQuery = useParsedPaymentListHospQuery({ selectedHospital: parsedSelectedHospital, opMode, enabled: currentTab === 4 });

    useEffect(() => {
        if (parsedPaymentListGroupQuery.isSuccess && parsedPaymentListGroupQuery?.data?.data?.hospitalData[0]?.hospital) {
            setparsedSelectedHospital(parsedPaymentListGroupQuery?.data?.data?.hospitalData[0]?.hospital);
        }
    }, [parsedPaymentListGroupQuery.data, parsedPaymentListGroupQuery.isSuccess, parsedPaymentListGroupQuery.isError])

    const [selectedPaymentID, setselectedPaymentID] = useState(null);
    const archivePaymentListQuery = useArchivePaymentListQuery({ selectedPaymentID: selectedPaymentID });
    const [selectedFilterUserID, setselectedFilterUserID] = useState(null);
    const archivePaymentListGroupQuery = useArchivePaymentListGroupQuery({ selectedFilterUserID });




    useEffect(() => {
        const paymentList = archivePaymentListGroupQuery?.data?.data;
        if (archivePaymentListGroupQuery.isSuccess &&
            (paymentList && paymentList?.length > 0)) {
            setselectedPaymentID(paymentList[paymentList?.length - 1]?.paymentID);
        }


    }, [archivePaymentListGroupQuery.data, archivePaymentListGroupQuery.isSuccess, archivePaymentListGroupQuery.isError])




    const [paymentListModalOpen, setPaymentListModalOpen] = useState(false);
    const [modalPosition, setmodalPosition] = useState('-100%');

    const archivedPatientsQuery = useArchivedHospPatientsQuery({ paymentID: selectedPaymentID, hospID: selectedHospital.hospID, enabled: currentTab === 3 });

    useEffect(() => { setselectedHospital({ userID: null, hospID: null }) }, [selectedPaymentID])


    const patientList = useMemo(() => {
        if ([0, 1].includes(currentTab) && PaymentListPatientsQuery.isSuccess) {
            return PaymentListPatientsQuery.data?.data?.patientList || [];
        } else if (currentTab === 2 && selectedHospital?.hospID && selectedHospital?.userID && ActivePaymentQuery.isSuccess) {
            const paymentList = ActivePaymentQuery.data?.data?.paymentList;
            let selectedPaymentList = null;
            selectedPaymentList = paymentList?.find(payment => (payment?.hospID === selectedHospital?.hospID));
            if (!selectedPaymentList) { selectedPaymentList = paymentList?.find(payment => (payment?.userID === selectedHospital?.userID) ) }

            if(selectedPaymentList?.multipleHospital === 'singlePayment'){
                return (ActivePaymentQuery.data?.data?.patients.filter(patient => patient?.hospCases[0]?.userID === selectedHospital?.userID) || []);
            } else {
                return (ActivePaymentQuery.data?.data?.patients.filter(patient => patient?.hospCases[0]?.hospID === selectedHospital?.hospID) || []);
            }


        } else if (currentTab === 2 && PaymentListPatientsQuery.isSuccess && selectedHospital?.hospID === null) {
            return [];
        } else if (currentTab === 3 && archivedPatientsQuery.isSuccess) {
            return archivedPatientsQuery.data?.data || [];
        } else {
            return [];
        }
    }, [
        currentTab,
        PaymentListPatientsQuery.data,
        PaymentListPatientsQuery.isSuccess,
        PaymentListPatientsQuery.data?.data?.patientList,
        ActivePaymentQuery.data?.data?.paymentList,
        ActivePaymentQuery.data?.data?.patients,
        archivedPatientsQuery.data?.data,
        archivedPatientsQuery.isSuccess,
        ActivePaymentQuery.data,
        ActivePaymentQuery.isSuccess,
        selectedHospital,
        selectedHospital?.hospID,
        selectedHospital?.userID
    ]);


    const patientColumnsCondition = [0, 1].includes(currentTab) || ( [2,3].includes(currentTab) && selectedHospital?.hospID );
    const hospitalColumnsCondition = (currentTab === 2 && !(selectedHospital?.hospID)) || (currentTab === 3 && !(selectedHospital?.hospID))


    const currentListValue = usePaymentTableList({
        currentTab,
        patientList, // patientList derivedPatientList
        activePaymentData: ActivePaymentQuery?.data?.data,
        archivePaymentData: archivePaymentListQuery?.data?.data,
        parsedPaymentData: parsedPaymentListHospQuery?.data?.data,
        selectedHospital,
    })


    const [selectedRowCaseNo, setselectedRowCaseNo] = useState(null);
    const [selectedhospCaseUserID, setselectedhospCaseUserID] = useState(null);
    const [selectedhospCaseHospID, setselectedhospCaseHospID] = useState(null);
    const [selectedTab, setSelectedTab] = useState('PatientCaseInformation');
    const [formOpen, setformOpen] = useState(false);
    const [formCreateMode, setFormCreateMode] = useState(false);
    const [paymentMode, setPaymentMode] = useState(false);

    const handleModalFormClose = () => {

        setformOpen(false);
        setmodalPosition('-100%')
        setFormCreateMode(false);
        setselectedRowCaseNo(null);
        setPaymentMode(false);

        opMode && setselectedhospCaseUserID(null);
        opMode && setselectedhospCaseHospID(null);

    };

    const handleHospitalSelect = useCallback((e) => {
        setselectedHospital({ userID: e.data.userID, hospID: e.data.hospID });
    }, []);


    // Handler for Patient row select (currentTab 0 or 1)
    const handlePatientRowSelect = useCallback((e) => {
        setSelectedTab("PatientCaseInformation");
        setPaymentMode(true);
        setselectedRowCaseNo(e.data?.caseNumber);
        if (e.data?.hospCases?.length === 1) {
            setselectedhospCaseUserID(e.data?.hospCases[0]?.userID);
            setselectedhospCaseHospID(e.data?.hospCases[0]?.hospID);
        } else if (e.data?.hospCases?.length > 1) {
            setselectedhospCaseUserID(null);
            setselectedhospCaseHospID(null);
        }
        setformOpen(true);
        setTimeout(() => {
            setmodalPosition('0%');
        }, 15);
        setFormCreateMode(false);
    }, []);
    const onRowSelect = useMemo(() => {
        if ([2,3].includes(currentTab) && !selectedHospital?.hospID) {
            return handleHospitalSelect;
        } else if ([0, 1].includes(currentTab)) {
            return handlePatientRowSelect;
        }
        return null;
    }, [currentTab, selectedHospital,selectedHospital?.hospID, handleHospitalSelect, handlePatientRowSelect]);


    const onRowUnselect = (event) => { setselectedRowCaseNo(null) };


    const [drawerOpen, setDrawerOpen] = useState({ state: false, type: 'activityLog' });

    const toggleDrawer = ({ state, type }) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen({ state: state, type: type });
    };



    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

            <div className="App-main">

                <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />

                {opMode && <PaymentListCreator
                    lang={lang}
                    setsnackbarStatus={setsnackbarStatus}
                    paymentListModalOpen={paymentListModalOpen}
                    setPaymentListModalOpen={setPaymentListModalOpen}
                    modalPosition={modalPosition}
                    setmodalPosition={setmodalPosition}

                />}

                <Modal
                    open={formOpen}
                    onClose={handleModalFormClose}
                >
                    <div className="modal-container"
                        style={{
                            width: 'auto',
                            transform: 'translateY(-50%)',
                            left: `${modalPosition}`,
                            transition: '0.5s ease-in-out 0s !important',
                            height: '100vh',
                            overflowY: 'visible',
                            maxWidth: '100vw'
                        }}

                    >

                        {formOpen && (selectedRowCaseNo || formCreateMode) &&
                            <PatientFormContainer
                                lang={lang}
                                opMode={opMode}
                                confirmedUserID={confirmedUserID}
                                selectedhospCaseUserID={selectedhospCaseUserID}
                                selectedhospCaseHospID={selectedhospCaseHospID}
                                setselectedhospCaseUserID={setselectedhospCaseUserID}
                                setselectedhospCaseHospID={setselectedhospCaseHospID}
                                setsnackbarStatus={setsnackbarStatus}
                                formCreateMode={formCreateMode}
                                setformOpen={setformOpen}
                                selectedRowCaseNo={selectedRowCaseNo}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                paymentMode={paymentMode}
                                handleModalFormClose={handleModalFormClose}
                            />
                        }



                    </div>
                </Modal>





                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>

                    <NavigationBar
                        currentPage='payment'
                        lang={lang}
                        opMode={opMode}

                        toggleDrawer={toggleDrawer}
                        viewportWidth={viewportWidth}

                        archivePaymentListGroupQuery={archivePaymentListGroupQuery}
                        setselectedPaymentID={setselectedPaymentID}
                        selectedPaymentID={selectedPaymentID}

                        selectedFilterUserID={selectedFilterUserID}
                        setselectedFilterUserID={setselectedFilterUserID}
                        disablePaymentList={ActivePaymentQuery.isSuccess && (ActivePaymentQuery?.data?.status === 204)}

                    />



                    <div className='dataTableContainer'>

                        <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={PaymentListPatientsQuery.isFetching || ActivePaymentQuery.isFetching}  >
                            <CircularProgress size={'4rem'} />
                        </Backdrop>

                        {/* {(patientColumnsCondition) && <ContextMenu model={menuModel} ref={contextMenuRef} onHide={() => setrightselectedRow(null)} />} */}



                        <DataTable
                            key={`Table-${currentTab}-${selectedHospital?.hospID || 'main'}`}

                            // onContextMenu={(e) => contextMenuRef.current.show(e.originalEvent)}
                            // contextMenuSelection={rightselectedRow}
                            // onContextMenuSelectionChange={(e) => { (patientColumnsCondition) && setrightselectedRow(e.value); }}
                            // onSelectionChange={(e) => (patientColumnsCondition) && setselectedRows(e.value)}
                            // selection={selectedRows}
                            // selectionMode='single'
                            // metaKeySelection={true}
                            // dragSelection

                            id='divTable'
                            ref={datatableRef}

                            // showGridlines

                            size="small"
                            dataKey={patientColumnsCondition ? "caseNumber" : "hospID"}
                            className="DataGrid"
                            value={currentListValue}
                            selection={{ caseNumber: selectedRowCaseNo }}
                            selectionMode="single"
                            onRowUnselect={onRowUnselect}
                            metaKeySelection={false}
                            onRowSelect={onRowSelect}

                            stripedRows
                            scrollable
                            rowClassName={rowClass}
                            resizableColumns

                            // onRowSelect={
                            //     (currentTab === 2 && !selectedActiveHospitalID) ? // ActivePaymentList
                            //         (e) => {
                            //             setselectedActiveHospitalID(e.data.userID);
                            //         }
                            //         : (currentTab === 3 && !selectedArchiveHospitalID) ? // Archive List - Hospital

                            //             (e) => {
                            //                 setselectedArchiveHospitalID(e.data.userID);
                            //             }
                            //             : // Patient
                            //             null
                            // }

                            header={
                                 <DataTablePaymentHeader
                                    setmodalPosition={setmodalPosition}
                                    setPaymentListModalOpen={setPaymentListModalOpen}
                                    selectedRows={selectedRows}
                                    setActivePaymentList={setActivePaymentList}
                                    setsnackbarStatus={setsnackbarStatus}
                                    setFilterToggle={setFilterToggle}
                                    filterToggle={filterToggle}
                                    lang={lang}
                                    setSearchFilter={setSearchFilter}
                                    searchFilter={searchFilter}

                                    ActivePaymentQuery={ActivePaymentQuery}
                                    activePaymentState={activePaymentState}

                                    archivePaymentListQuery={archivePaymentListQuery}

                                    parsedPaymentListGroupQuery={parsedPaymentListGroupQuery}
                                    parsedPaymentListHospQuery={parsedPaymentListHospQuery}
                                    parsedSelectedHospital={parsedSelectedHospital}
                                    setparsedSelectedHospital={setparsedSelectedHospital}

                                    // selectedArchiveHospitalID={selectedArchiveHospID}
                                    // selectedActiveHospitalID={selectedActiveUserID}

                                    // setselectedArchiveHospitalID={setselectedArchiveHospID}
                                    // setselectedActiveHospitalID={setselectedActiveUserID}

                                    selectedHospital={selectedHospital}
                                    setselectedHospital={setselectedHospital}

                                    opMode={opMode}

                                />
                            }
                            footer={
                                <DataTablePaymentFooter
                                    selectedPaymentID={selectedPaymentID}
                                    patientDashboard={PaymentListPatientsQuery?.data?.data?.dashboard || { totalPatientCount: 0, totalGOPUSD: 0, totalGOPTL: 0 }}

                                    lang={lang}
                                    setlang={setlang}
                                    parsedPaymentListGroupQuery={parsedPaymentListGroupQuery}
                                    parsedPaymentListHospQuery={parsedPaymentListHospQuery}
                                    parsedSelectedHospital={parsedSelectedHospital}
                                    setparsedSelectedHospital={setparsedSelectedHospital}

                                    opMode={opMode}
                                    viewportWidth={viewportWidth}
                                />
                            }


                            tableStyle={{ width: 'auto', minWidth: '50rem' }}
                        >

                            {/*
                            {patientColumnsCondition &&
                                <Column field="caseNumber" header={`RBK ${TextLanguage["RefNo"][lang]}`}
                                    style={{ width: '2%', minWidth: '110px !important' }}
                                    key={'caseNumber-Col'}
                                    body={formatCaseNo({ confirmedUserID, opMode: true, enableBadge: false })} />
                            }
                             {patientColumnsCondition &&
                                <Column field="LDMCaseNo" header={`LDM ${TextLanguage["RefNo"][lang]}`}
                                    style={{ width: '4%', minWidth: '90px' }}
                                    key={'LDMCaseNo-Col'}
                                    body={formatLDMCaseNo}

                                />
                            } */}

                            {patientColumnsCondition &&
                                <Column field="RBKCaseNo" header={`${TextLanguage["RefNo"][lang]}`}
                                    style={{ width: '4%', minWidth: '90px' }}
                                    key={'displayCaseNo-Col'}
                                    body={formatCaseNo({ confirmedUserID, opMode: true, enableBadge: false })}

                                />
                            }

                            {patientColumnsCondition &&
                                <Column field="GOPPriceUSD" header={GOPPriceGroupHeader({ lang })}
                                    headerStyle={{ width: '0.01%', minWidth: '110px !important' }}
                                    style={{ width: '0.01%', minWidth: '380px' }}
                                    key={'GOPPriceUSD-Col'}
                                    body={formatGOPPriceGroup} />
                            }

                            {patientColumnsCondition &&
                                <Column field="name" header={`${TextLanguage["PatientName"][lang]}`}
                                    style={{ width: '4%', minWidth: '100px' }}
                                    key={'name-Col'}
                                    body={formatPatientName}
                                />
                            }
                            {/* {patientColumnsCondition && viewportWidth > 1650 &&
                                <Column field="admissionDate" header={`${TextLanguage["AdmissionDate"][lang]}`}
                                    style={{ width: '4%', minWidth: '100px' }}
                                    key={'admissionDate-Col'}
                                    body={formatDateAdm} />
                            } */}
                            {patientColumnsCondition && [0, 1].includes(currentTab) &&
                                <Column field="InvoiceDate" header={`${TextLanguage["PaymentDeadline"][lang]}`}
                                    style={{ width: '4%', minWidth: '100px' }}
                                    key={'InvoiceDate-Col'}
                                    body={formatDateDeadline({ lang })} />
                            }
                            {patientColumnsCondition && [3].includes(currentTab) &&
                                <Column field="InvoiceDate" header={`${TextLanguage["InvoiceDate"][lang]}`}
                                    style={{ width: '4%', minWidth: '100px' }}
                                    key={'InvoiceDate-Col'}
                                    body={formatDateInvoice} />
                            }
                            {patientColumnsCondition && viewportWidth > 1740 && opMode &&
                                <Column field="InsuranceCo" header={`${TextLanguage["InsuranceCompany"][lang]}`}
                                    style={{ width: '5%', minWidth: '100px' }}
                                    key={'InsuranceCo-Col'}
                                    body={formatInsuranceCompany(insCoListQuery?.data?.data)} />
                            }
                            {patientColumnsCondition && currentTab !== 3 && viewportWidth > 1500 &&
                                <Column field="hospCases.userID" header={`${TextLanguage["HospitalClinic"][lang]}`}
                                    style={{ width: '9%', minWidth: '100px' }}
                                    key={'userID-Col'}
                                    body={formatHospitalUserID(hospitalUserListQuery?.data?.data)} />
                            }
                            {patientColumnsCondition && [2, 3].includes(currentTab) &&
                                <Column field="hospCases.files" header={`${TextLanguage["Invoice"][lang]}`}
                                    style={{ width: '1%', minWidth: '100px', maxWidth: '300px' }}
                                    key={'files-Col'}
                                    body={invoiceButtonTemplate(hospitalTokenListQuery?.data?.data, lang)} />
                            }

                            {hospitalColumnsCondition &&
                                <Column field="userID" header={`${TextLanguage["HospitalClinic"][lang]}`}
                                    style={{ width: '9%', minWidth: '100px' }}
                                    sortable
                                    key={'Hosp-userID-Col'}
                                    body={formatHospitalUserIDPayments(hospitalUserListQuery?.data?.data)} />
                            }
                            {hospitalColumnsCondition &&
                                <Column field="GOPPriceUSD" header={`${TextLanguage["GOPPrice"][lang]} (USD)`}
                                    style={{ width: '2%', minWidth: '100px' }}
                                    key={'Hosp-GOPPriceUSD-Col'}
                                    body={formatGOPPriceGroupPayments} />
                            }

                            {
                            /*
                            hospitalColumnsCondition &&
                                <Column field="GOPPriceUSD" header={`Avg ${TextLanguage["GOPPrice"][lang]} (USD)`}
                                    style={{ width: '2%', minWidth: '100px' }}
                                    key={'Hosp-GOPPriceUSDAvg-Col'}
                                    body={formatAvgUSDPayments} />
                            */
                            }
                            {hospitalColumnsCondition &&
                                <Column field="totalCount" header={`${TextLanguage["TotalPatients"][lang]}`}
                                    style={{ width: '1%', minWidth: '3rem' }}
                                    key={'Hosp-totalCount-Col'}
                                    body={formatPatientCountPayments} />
                            }

                            {hospitalColumnsCondition &&
                                <Column field="Receipt" header={TextLanguage["Receipt"][lang]}
                                    style={{ width: '1%', minWidth: '3rem' }}
                                    key={'Hosp-Receipt-Col'}
                                    body={receiptUploadButtonTemplate(hospitalTokenListQuery?.data?.data, setsnackbarStatus, lang)} />
                            }

                            {currentTab === 4 &&
                                <Column field="hospCaseRow.paymentListDate" header={`Odeme Tarihi`}
                                    style={{ width: '0.1%', minWidth: '2rem' }}
                                    key={'Payment-paymentListDate-Col'}
                                    body={(hospCaseRow) => {
                                        return (
                                            <Typography className='RowCountText'>
                                                {`${hospCaseRow.paymentListDate}`}
                                            </Typography>
                                        );
                                    }} />

                            }
                            {currentTab === 4 &&
                                <Column field="totalCount" header={`${TextLanguage["TotalPatients"][lang]}`}
                                    style={{ width: '0.1%', minWidth: '2rem' }}
                                    key={'Payment-totalCount-Col'}
                                    body={formatPatientCountPayments} />

                            }



                            {currentTab === 4 &&
                                <Column header={`${TextLanguage["GOPPrice"][lang]}`}
                                    style={{ width: '0.1%', minWidth: '100px' }}
                                    key={'Payment-GOPPriceGroup-Col'}
                                    body={formatGOPPriceGroupPayments} />

                            }

                            <Column
                                style={{ width: '2%', minWidth: '10px' }}
                                key={'empty-Col'}
                                body={() => { }}
                            />

                        </DataTable>


                    </div>
                </div>

                <Drawer
                    anchor='left'
                    open={drawerOpen?.state === true}
                    onClose={toggleDrawer({ state: false, type: drawerOpen?.type })}

                >
                    <div style={{ minWidth: '340px', width: '25vw', maxWidth: '500px', height: '100%' }}>

                        {drawerOpen?.type === 'activityLog' ? <ActivityLog
                            setformOpen={setformOpen}
                            setselectedRowCaseNo={setselectedRowCaseNo}
                            setselectedhospCaseUserID={setselectedhospCaseUserID}
                            setselectedhospCaseHospID={setselectedhospCaseHospID}
                            setSelectedTab={setSelectedTab}
                            setmodalPosition={setmodalPosition}
                            setFormCreateMode={setFormCreateMode}
                            mainActivityLog={true}
                            lang={lang}
                            setPaymentMode={setPaymentMode}
                        />
                            : <div>
                                <UserSettings
                                    lang={lang}
                                    setsnackbarStatus={setsnackbarStatus}
                                />
                            </div>

                        }

                    </div>
                </Drawer>

            </div>

        </LocalizationProvider>

    );
}


