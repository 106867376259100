export const rowClass = ({opMode, confirmedUserID}) => {

return (data) => {

    return {
        'defaultRow': true,
        'dataTableNewMessageRow': opMode ?
            data?.hospCases?.some(hospCase => hospCase?.flags?.UnreadMessageOp)
            :
            data?.hospCases?.some(hospCase => hospCase?.flags?.UnreadMessageUser)
        ,
        'dataTableRejectedRow': opMode ?
            (data?.hospCases?.some(hospCase => hospCase?.flags?.RejectedGOPbyUser) || data?.hospCases?.some(hospCase => hospCase?.GOP_State === -1))
            :
            (
                data?.hospCases?.some(hospCase => hospCase?.flags?.RejectedInvoicebyOp)
                ||
                data?.hospCases?.some(hospCase => hospCase?.flags?.RejectedProformabyOp)
                ||
                data?.hospCases?.some(hospCase => hospCase?.flags?.MissingDocs)

            )
    }
}

}