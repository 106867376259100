import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextLanguage from '../data/TextLanguage.json';
import useLocalStorage from '../hooks/useLocalStorage';

import CompLogo from '../resources/RBKLogo.png';


import { CountdownDisplay } from '../components/Login/CountdownDisplay';
import { CustomSnackbar } from '../components/CustomSnackbar';
import { useLoginMutation } from '../components/Login/useLoginMutation';
import { useVerificationMutation } from '../components/Login/useVerificationMutation';
import { LoginInputField } from '../components/Login/LoginInputField';
import { useQueryClient } from '@tanstack/react-query';

export function LoginPage() {

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.clear();
    }, [])


    const [username, setName] = useState('');

    const [password, setPassword] = useState('');

    const [verificationCode, setVerificationCode] = useState(null);

    const [showPassword, setShowPassword] = useState(false);

    const [lang] = useLocalStorage('lang', 'enUS');

    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });


    const handleClickShowPassword = () => { setShowPassword((show) => !show) }



    const handleLoginSubmit = () => {
        loginMutation.mutate({ username, password });

    };

    const [verificationTime, setVerificationTime] = useState(null);
    const [awaitingVerification, setAwaitingVerification] = useState(false);
    const loginMutation = useLoginMutation({ setsnackbarStatus, setAwaitingVerification, setVerificationTime });
    const verificationMutation = useVerificationMutation({ setsnackbarStatus });


    const handleVerificationSubmit = () => {
        verificationMutation.mutate({ username, verificationCode });
    }



    return (


        <div className='LoginPageContainer'>

            <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />

            <div className="LoginBox" >
                <div className='LoginTopContainer'>


                    {!awaitingVerification ?
                        <>

                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink sx={{ color: 'rgb(125,188,255)', fontWeight: 'bold' }} htmlFor="outlined">{TextLanguage["Username"][lang]}</InputLabel>
                                <LoginInputField
                                    id='usernameInput'
                                    value={username || ''}
                                    onChange={(e) => setName(e.target.value)}
                                    error={loginMutation.isError}
                                    type={'text'}
                                    label="Username"
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink sx={{ color: 'rgb(125,188,255)', fontWeight: 'bold' }} htmlFor="outlined-adornment-password">{TextLanguage["Password"][lang]}</InputLabel>
                                <LoginInputField
                                    value={password || ''} onChange={(e) => setPassword(e.target.value)}
                                    id='passwordInput'
                                    error={loginMutation.isError}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={(e) => { e.preventDefault() }}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </>
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '1rem', width: '100%' }}>

                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel sx={{ color: 'rgb(125,188,255)', fontWeight: 'bold' }} htmlFor="outlined">{"Verification Code"}</InputLabel>
                                <LoginInputField
                                    id='verificationInput'
                                    value={verificationCode || ''}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    error={verificationMutation.isError}
                                    type={'text'}
                                    label="Verification Code"
                                />
                            </FormControl>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <CountdownDisplay
                                        initialCount={verificationTime}
                                        timer={300}
                                        setAwaitingVerification={setAwaitingVerification}

                                    />
                            </div>


                        </div>


                    }

                    {!awaitingVerification ?
                        <Button
                        data-testid='loginSubmit'
                         sx={{ color: 'white', background: 'rgb(49,80,125)', borderRadius: 2, height: 56, width: '100%' }} onClick={handleLoginSubmit}>
                            <Typography sx={{ fontWeight: 700, fontSize: '1.4rem',  textTransform:'none' }}>{TextLanguage["Login"][lang]}</Typography>
                        </Button>
                        :
                        <Button
                        data-testid='verificationSubmit'
                         sx={{ color: 'white', background: 'rgb(49,80,125)', borderRadius: 2, height: 56, width: '100%' }} onClick={handleVerificationSubmit}>
                            <Typography sx={{ fontWeight: 700, fontSize: '1.4rem',  textTransform:'none' }}>{TextLanguage["Login"][lang]}</Typography>
                        </Button>

                    }

                </div>

                <div className='LoginLogoContainer'>
                    <img src={CompLogo} alt="CompLogo" style={{ height: '2.4rem', width: 'auto' }} />
                </div>
            </div>

        </div >
    );
}

