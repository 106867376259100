import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {  TextField,Button } from '@mui/material';
 import CancelIcon from '@mui/icons-material/Cancel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const DateFilter = ({ onFilter, startLabel, endLabel }) => {
    const [selectedDateStart, setselectedDateStart] = useState(null);
    const [selectedDateEnd, setselectedDateEnd] = useState(null);






    const handleDateChangeStart = (newDate) => {
        if(newDate === null || newDate === undefined){
            setselectedDateEnd(null);
            onFilter({
                admissionDateStart: null
            });
            return;

        }
        setselectedDateStart(newDate);
        onFilter({
            admissionDateStart: newDate.$d
        });
    };

    const handleDateChangeEnd = (newDate) => {
        if(newDate === null || newDate === undefined){
            setselectedDateEnd(null);
            onFilter({
                admissionDateEnd: null
            });
            return;
        }
        setselectedDateEnd(newDate);
        onFilter({
            admissionDateEnd: newDate.$d
        });
    };

    return (
        <div className='CustomFilterContainer'>
            <DatePicker

                label={startLabel}
                value={selectedDateStart}
                onChange={handleDateChangeStart}
                sx={{ width: '12rem' , minWidth: '12rem' }}
                TextFieldComponent={TextField}

            />
            <div>
                <ChevronRightIcon />
            </div>
            <DatePicker
                label={endLabel}
                value={selectedDateEnd}
                sx={{

                    width: '10rem', minWidth: '10rem', '& .MuiInputBase-root': {
                        background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 36%) 60%)',
                        width: '10rem',
                        maxWidth: '10rem',



                    }
                }}
                onChange={handleDateChangeEnd}
                TextFieldComponent={TextField}

            />
           {(selectedDateEnd || selectedDateStart) && <Button
                disableRipple
                    onClick={() => {
                        setselectedDateStart(null);
                        setselectedDateEnd(null);
                        onFilter({
                            admissionDateStart: null,
                            admissionDateEnd: null
                        });
                    }

                    } sx={{
                        background: 'transparent',
                        borderRadius: '0px',
                        border: 'none',
                        marginLeft: '0px',
                        zIndex: 100,
                        '&:hover': {
                            border: 'none',
                            outline: 'none',
                            boxShadow: 'none',
                            background: 'transparent',
                            filter: 'none'
                        },
                    }}>
                    <CancelIcon />
                </Button>}

        </div>
    );
};

export default DateFilter;

/*
endAdornment={  <Button
                    onClick={() => {
                        setselectedDateStart(null);
                        setselectedDateEnd(null);
                        onFilter({
                            admissionDateStart: null,
                            admissionDateEnd: null
                        });
                    }

                    } sx={{

                        background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 36%) 60%)',
                        height: '56px !important',
                        left: '38px',
                        borderRadius: '0px',
                        minWidth: '50px !important',
                        border: '1px solid #7c7e86',
                        borderLeft: 'none',
                        width: '50px !important',
                        zIndex: 100,
                        '&:hover': {
                            border: '1px solid #fff',
                            outline: 'none',
                            borderLeft: 'none',
                            boxShadow: 'none',
                            background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 36%) 60%)',
                            filter: 'none'
                        },
                    }}>
                    <CancelIcon />
                </Button>}
*/