import React from "react";
import TextLanguage from "../../../data/TextLanguage.json";
import FolderIcon from '@mui/icons-material/Folder';
import FolderOffIcon from '@mui/icons-material/FolderOff';

export const progressHeader = ({ lang, searchFilter, dense }) => () => {

        return (<>

            <div className='progressHeaderDiv'>
                <span className='progressHeaderSpan'>{TextLanguage["Documents"][lang]}

                    {searchFilter?.missingDocs !== undefined ?
                        searchFilter?.missingDocs ?
                            <FolderOffIcon sx={{ fontSize: '1.25rem', color: '#ebc505', position: 'absolute', top: '48%', left: 'calc(50% + 4.5ch)', transform: 'translate(-50%,-50%)' }} />
                            :
                            <FolderIcon sx={{ fontSize: '1.25rem', color: 'rgb(125,188,255)', position: 'absolute', top: '48%', left: 'calc(50% + 4.5ch)', transform: 'translate(-50%,-50%)' }} />
                        : null}
                </span>



                <span className='progressHeaderSpan'>{TextLanguage["GOP"][lang]}

                    {searchFilter?.missingGOP !== undefined ?
                        searchFilter?.missingGOP ?
                            <FolderOffIcon sx={{ fontSize: '1.25rem', color: '#ebc505', position: 'absolute', top: '48%', left: 'calc(50% + 3ch)', transform: 'translate(-50%,-50%)' }} />
                            :
                            <FolderIcon sx={{ fontSize: '1.25rem', color: 'rgb(125,188,255)', position: 'absolute', top: '48%', left: 'calc(50% + 3ch)', transform: 'translate(-50%,-50%)' }} />
                        : null}
                </span>
                <span className='progressHeaderSpan'>{TextLanguage["Proforma"][lang]}

                    {searchFilter?.missingProforma !== undefined ?
                        searchFilter?.missingProforma ?
                            <FolderOffIcon sx={{ fontSize: '1.25rem', color: '#ebc505', position: 'absolute', top: '48%', left: 'calc(50% + 4.25ch)', transform: 'translate(-50%,-50%)' }} />
                            :
                            <FolderIcon sx={{ fontSize: '1.25rem', color: 'rgb(125,188,255)', position: 'absolute', top: '48%', left: 'calc(50% + 4.25ch)', transform: 'translate(-50%,-50%)' }} />
                        : null}
                </span>
                <span className='progressHeaderSpan' style={{ borderRight: 'none' }}>{TextLanguage["Invoice"][lang]}

                    {searchFilter?.missingInvoice !== undefined ?
                        searchFilter?.missingInvoice ?
                            <FolderOffIcon sx={{ fontSize: '1.25rem', color: '#ebc505', position: 'absolute', top: '48%', left: 'calc(50% + 4ch)', transform: 'translate(-50%,-50%)' }} />
                            :
                            <FolderIcon sx={{ fontSize: '1.25rem', color: 'rgb(125,188,255)', position: 'absolute', top: '48%', left: 'calc(50% + 4ch)', transform: 'translate(-50%,-50%)' }} />
                        : null}
                </span>
            </div>


        </>);

    };

