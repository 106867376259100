import React from 'react';
import { TablePagination } from '@mui/material/';
import { styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';

const Paginator = ({ totalPatientCount = 0, rowsPerPageOptions, ...restProps }) => {
    const Paginator = styled(TablePagination)({
        display: 'flex',
        padding: '2px !important',
        margin: '0',
        height: '100%',
        borderRadius: '0px 8px 8px 0px',
        border: '1px #4e4e4e solid',
        borderLeft: '2px rgb(84 89 96 / 28%) solid',
        background: 'linear-gradient(0deg, #2f2f2fc2, #5455617a)',
        boxShadow: 'inset 0px 0px 10px 2px rgba(0,0,0,0.25)',
        color: 'white',
        '& .MuiTablePagination-caption': {
            background: 'white',
            color: 'white',
        },
        '& .MuiIconButton-root': {
            color: 'white',
        },
        '& .MuiTablePagination-root': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            alignContent: 'center',
            overflow: 'hidden !important',
        },
        '& .MuiTablePagination-select': {
            background: 'rgba(22, 22, 22, 0.5)',
            borderRadius: '4px',
            outline: '2px rgba(222, 222, 222, 0.55) solid',
            color: 'white',
        },

        '& .MuiTablePagination-selectIcon': {
            color: 'white',
        },
        '& .MuiTablePagination-actions': {
            color: 'white',
        },

        '& .MuiTablePagination-input': {
            color: 'white',
        },
        '& .MuiTablePagination-spacer': {
            color: 'white',
        },



    });

    const [searchParams, setSearchParams] = useSearchParams();

    const page = parseInt(searchParams.get('page')) || 0;
    const pageSize = parseInt(searchParams.get('pageSize')) || 50;


    const onPageChange = (e, newPage) => {
        setSearchParams(prev => {
            prev.set('page', newPage);
            return prev;
        }, { replace: true });
    }
    const handleChangeRowsPerPage = (e) => {

        //setPageSize(parseInt(e.target.value, 10));
        //setPage(0);
        setSearchParams(prev => {
            prev.set('page', 0);
            prev.set('pageSize', e.target.value);
            return prev;
        }, { replace: true });
    };


    return (


        <Paginator
            count={totalPatientCount ? totalPatientCount : 0}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            {...restProps}
            component="div"
            rowsPerPageOptions={rowsPerPageOptions}


        />






    );

};

export default Paginator;