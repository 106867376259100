import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

export const useReviewCaseMutation = ({ setsnackbarStatus, setformOpen }) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {

            return axios.post(
                `${config.API_URL}/data/reviewCase`,
                updaterData,
                {
                    withCredentials: true,
                }
            )
        },
        onError: (error) => {
            console.log(error);
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Reviewing Case`,
                    severity: 'error'
                }
            )
        },
        onSuccess: (data, variables) => { // data in data?.config?.data? comes as a string, not an object!!
            setformOpen(false);

            setsnackbarStatus(
                {
                    open: true,
                    message: `Moved to Payment Page`,
                    severity: 'success'
                }
            )
            queryClient.invalidateQueries({ queryKey: ['patients'], refetchType: 'active'}, {cancelRefetch: false });
            queryClient.invalidateQueries({ queryKey: ['activityLog'], refetchType: 'active'}, {cancelRefetch: false });
        }
    });

}
