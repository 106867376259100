import React, { useState } from 'react';
import { Typography } from '@mui/material';
import TextLanguage from '../../../data/TextLanguage.json';

import uploadTypes from '../../../data/uploadTypes';

import { DownloadButton } from "../../../pages/PaymentPage";

import FileDownload from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { fileUploader } from "../../../functions/fileUploader";
import { useUploadReceiptMutation } from "../hooks/useUploadReceiptMutation";

export const receiptUploadButtonTemplate = (tokenList, setsnackbarStatus, lang) => (data, index) => {


    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadReceiptMutation = useUploadReceiptMutation({ setsnackbarStatus, setUploadProgress })

    const sasToken = tokenList?.find(hosp => data?.userID === hosp.userID)?.sasToken;
    const fileURL = `${data?.receiptData?.blobUrl}?${sasToken}`;

    return (
        data?.receiptData ?
            <DownloadButton key={`DownloadReceiptButton-${index}`} href={fileURL} className='DocDownloadButton'
                disableRipple={true}
                sx={{ background: 'rgb(30, 94, 28)', '&:hover': { background: 'rgb(30, 137, 26)' } }}
            >
                <FileDownload htmlColor='white' sx={{ width: '25px', height: '25px' }} />
                <Typography>
                    {TextLanguage['Download'][lang]}
                </Typography>
            </DownloadButton>
            :
            <DownloadButton key={`UploadReceiptButton-${index}`} onClick={fileUploader(uploadReceiptMutation, uploadTypes, { selectedhospCaseHospID: data?.hospID, selectedhospCaseUserID: data?.userID })} className='DocDownloadButton'
                disableRipple={true}
                sx={{ background: '#0c4eb045', '&:hover': { background: '#0062f5a1' } }}
            >
                <FileUploadIcon htmlColor='white' sx={{ width: '25px', height: '25px' }} />
                {TextLanguage['Upload'][lang]}
            </DownloadButton>
    )
};