import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

export const useRemoveCaseMutation = ({setsnackbarStatus}) => {

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {

            return axios.post(
                `${config.API_URL}/data/removeCase`,
                updaterData,
                {
                    withCredentials: true
                }
            )
        },
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['patients'] , refetchType: 'active'}, {cancelRefetch: false});
            queryClient.invalidateQueries({ queryKey: ['activityLog'], refetchType: 'active'}, {cancelRefetch: false });

            setsnackbarStatus(
                {
                    open: true,
                    message: `Removed`,
                    severity: 'success'
                }
            );


        },
        onError: (error, variables) => {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Removing Case`,
                    severity: 'error'
                }
            );


        }
    })





}