import React from "react";
import { Typography } from '@mui/material';

export const formatDateInvoice =  (patientRow) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.2rem' }}>
            {patientRow?.hospCases?.map((hospCase, index) => {

                const parsedInvDate = new Date(hospCase.Invoice_Date);
                const day = parsedInvDate.getDate().toString().padStart(2, '0');
                const month = (parsedInvDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const year = parsedInvDate.getFullYear();
                const reformattedDate = `${day}/${month}/${year}`

                return (
                    <div key={`${index}-dateDeadline`} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
                        <Typography sx={{ fontSize: '1rem' }}>
                            {reformattedDate}
                        </Typography>

                    </div>
                )


            })}
        </div>
    );


};
