import { Typography } from '@mui/material';
import React from 'react';
import TextLanguage from '../../../data/TextLanguage.json';
import { useQueryClient } from '@tanstack/react-query';
import numeral from 'numeral';

const DividerElement = () => (<svg height="35" width="6">
    <line x1="0" y1="0" x2="0" y2="50" stroke="#78787887" strokeWidth="6" />
</svg>)

const typographyStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    pointerEvents: 'none',
    userSelect: 'none',
    fontSize: '0.85rem !important',
    fontWeight: 'bold',
}
const textContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    fontWeight: 'bolder',
    fontSize: '1.5rem',
    gap: '0.5rem',
    padding: '0 0.5rem',
}
export const PaymentListDataDisplay = ({
    lang,
    opMode,
    totalCount,
    USDValue,
    TLValue,






}) => {


    const queryClient = useQueryClient();

    const exchangeRateData = queryClient.getQueryData(['exchangeRate']);
    const exchangeRate = exchangeRateData?.data?.sellingPrice;

    const usdToPay = TLValue / exchangeRate;
    const exrDiff = USDValue - usdToPay;
    const parsedEXRDiff = numeral(exrDiff).format('0,0');
    const parsedEXRDiffTL = numeral(exrDiff * exchangeRate).format('0,0.00');

    const validateData = queryClient.getQueryData(['validate']);
    const confirmedUserID = validateData?.data?.userID;

    const userList = queryClient.getQueryData(['hospitalUserList']);
    const userData = userList?.data?.find((user) => user.userID === confirmedUserID);
    const worksWithTL = userData?.worksWithTL;

    const AdminMode = (confirmedUserID === 'Admin');

    const avgExrRate = parseFloat((TLValue / USDValue).toFixed(2));

    const parsedUSDPrice = numeral(USDValue).format('0,0');
    const parsedPriceTL = numeral(TLValue).format('0,0.00');

    const parsedAvgUSDPrice = numeral(USDValue / totalCount).format('0,0');
    const parsedAvgTLPrice = numeral(TLValue / totalCount).format('0,0.00');




    return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: 0 }}>

        <div style={{ padding: '0 1.25rem 0 0.75rem' }}>
            <div style={{
                ...textContainerStyle
            }}

            >
                <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                    {`${(totalCount || 0)} ${parseInt(totalCount) > 1 ? TextLanguage["Patients"][lang] : TextLanguage["Patient"][lang]} `}
                </Typography>
                {<>
                    <DividerElement />
                    <Typography style={{ ...typographyStyle, color: 'white' }}>
                        {`${TextLanguage["Total"][lang]}`}
                    </Typography>
                    {!worksWithTL && <>
                        <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                            {parsedUSDPrice ? `$${parsedUSDPrice}` : `$0`}
                        </Typography>
                        <Typography style={{ ...typographyStyle, color: 'white', }}>
                            {`-`}
                        </Typography>
                    </>}
                    <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                        {parsedPriceTL ? `${parsedPriceTL} ₺` : '0 ₺'}
                    </Typography>


                </>}

                {opMode &&
                    <>
                        <DividerElement />
                        <Typography style={{ ...typographyStyle, color: 'white', }}>
                            {`${TextLanguage["Avg"][lang]}`}
                        </Typography>
                        <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                            {`$${parsedAvgUSDPrice ? parsedAvgUSDPrice : '0'}`}
                        </Typography>

                        <Typography style={{ ...typographyStyle, color: 'white', }}>
                            {`-`}
                        </Typography>
                        <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                            {parsedAvgTLPrice ? `${parsedAvgTLPrice} ₺` : '0 ₺'}
                        </Typography>
                    </>}
            </div>
        </div>
        {opMode && <>

            <DividerElement />

            <div style={{ padding: '0 1.25rem' }}>
                <Typography sx={{ ...typographyStyle, color: ((parseFloat((TLValue) / USDValue).toFixed(2) - exchangeRate < 0)) ? 'lightgreen' : 'red' }}
                >{`${TextLanguage["AvgExrRate"][lang]}: ${avgExrRate}`}</Typography>
                <Typography sx={{ ...typographyStyle, color: 'var(--color-text)' }}
                >{`${TextLanguage["CurrentExrRate"][lang]}: ${parseFloat(exchangeRate).toFixed(2)}`}
                </Typography>
            </div>

            {AdminMode && <>
                <DividerElement />
                <div style={{ ...textContainerStyle }}>
                    <Typography style={{ ...typographyStyle, color: 'white' }}>
                        {`${TextLanguage["ExrProfit"][lang]}`}
                    </Typography>
                    <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                        {parsedEXRDiff ? `$${parsedEXRDiff}` : `$0`}
                    </Typography>
                    <Typography style={{ ...typographyStyle, color: 'white', }}>
                        {`-`}
                    </Typography>
                    <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                        {parsedEXRDiffTL ? `${parsedEXRDiffTL} ₺` : '0 ₺'}
                    </Typography>
                </div>
            </>}
        </>}

    </div>
    );
}
