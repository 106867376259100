
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config';
import { useNavigate } from 'react-router-dom';


export const useLoginMutation = ({setsnackbarStatus, setAwaitingVerification, setVerificationTime}) => {

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation({

        mutationFn: ({username, password}) => {

            return axios.post(
                `${config.API_URL}/login/`,
                {
                    username: username,
                    password: password,
                    appName: 'FileshareApp'
                },

                {
                    withCredentials: true
                }

            );
        },
        onSuccess: (data, variables, context) => {
            if (data.status === 206) {
                setAwaitingVerification(true)
                setVerificationTime(new Date(Date.now()))
            } else if (data.status === 200) {
                queryClient.invalidateQueries(); // { queryKey: [] }

                navigate("/fileshare");
            }
        },
        onError: (error, variables, context) => {

            if( error?.response?.status === 429 ) {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Too many requests. Please try again later.`,
                        severity: 'error'
                    }
                )
            }
        }
    })




}