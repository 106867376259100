import React from "react";
import { Typography, Badge } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import MailIcon from '@mui/icons-material/Mail';
import CommentIcon from '@mui/icons-material/Comment';
import { displayCaseNo } from "../../../functions/displayCaseNo";

export const formatCaseNo = ({ opMode, enableBadge = true }) => {
    return (patientRow) => {
        const messagesExist = patientRow?.hospCases?.some(hospCase => hospCase.comments?.length > 0)

        let newMessageCount = 2;

        if (messagesExist) {
            if (opMode) {
                patientRow?.hospCases?.forEach(hospCase => {
                    for (let i = hospCase?.comments?.length - 1; i >= 0; i--) {
                        if (hospCase?.comments[i].by.type !== 'RBK') {
                            break;
                        } else { newMessageCount++; }
                    }
                });
            } else {
                patientRow?.hospCases?.forEach(hospCase => {
                    for (let i = hospCase?.comments?.length - 1; i >= 0; i--) {
                        if (hospCase?.comments[i].by.type === 'RBK') {
                            break;
                        } else { newMessageCount++; }
                    }
                });
            }

        }
        return (

            patientRow?.caseNumber && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', justifyContent: 'flex-start' }}>
                    <Typography sx={{ marginRight: '15px', fontSize: '1rem' }}>
                        {`${displayCaseNo(patientRow)}`}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem', justifyContent: 'flex-end' }}>

                    {(opMode && enableBadge) &&
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            {
                                (patientRow?.mail?.sort((a, b) => new Date(b.date) - new Date(a.date))[0].mailRead === false) &&
                                <Badge badgeContent={patientRow?.mail?.length} size='small' max={99} sx={{ transform: 'scale(0.95) translateY(4px)' }} color='success'
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MailIcon className='newMessageFlag' sx={{ transform: 'translateY(1px)' }} />
                                </Badge>
                            }
                        </div>
                    }

                    {
                        // htmlColor='lightgreen'
                    }


                    {enableBadge &&
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {opMode ?
                                patientRow?.hospCases?.some(hospCase => hospCase.flags?.RejectedGOPbyUser) &&
                                <ErrorIcon sx={{ mr: '5px' }} htmlColor='#ff9900' />
                                :
                                patientRow?.hospCases?.some(hospCase => hospCase?.flags?.RejectedProformabyOp || hospCase?.flags?.RejectedInvoicebyOp || hospCase?.flags?.MissingDocs) &&
                                <ErrorIcon sx={{ mr: '5px' }} htmlColor='#ff9900' />

                            }
                            {opMode ?
                                patientRow?.hospCases?.some(hospCase => hospCase.flags?.UnreadMessageOp) ?
                                    <Badge badgeContent={newMessageCount} size='small' max={9} sx={{ transform: 'scale(0.95) translateY(4px)' }} color='error'
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <CommentIcon className='newMessageFlag' sx={{ transform: 'translateY(1px)' }} htmlColor='lightgreen' />
                                    </Badge>
                                    :
                                    patientRow?.hospCases?.some(hospCase => hospCase.comments?.length > 0) &&
                                    <CommentIcon sx={{ transform: 'translateY(1px)' }} htmlColor='white' />


                                :
                                patientRow?.hospCases?.some(hospCase => hospCase.flags?.UnreadMessageUser) ?
                                    <Badge badgeContent={newMessageCount} max={9} sx={{ transform: 'scale(0.95)' }} color='error'
                                        size='small'
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <CommentIcon className='newMessageFlag' sx={{ transform: 'translateY(1px)' }} htmlColor='lightgreen' />
                                    </Badge>
                                    :
                                    patientRow?.hospCases?.some(hospCase => hospCase.comments?.length > 0) && <CommentIcon sx={{ transform: 'translateY(1px)' }} htmlColor='white' />

                            }
                        </div>
                    }




                </div>




            </div>


        );
    };
}
