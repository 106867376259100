
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from '../../../config.js';

import { useNavigate } from "react-router-dom";

export const useArchivedHospPatientsQuery = ({paymentID, hospID, enabled}) => {


    const navigate = useNavigate();

    async function fetchPatients({ queryKey }) {
        const [_key, query] = queryKey;
        const queryString = new URLSearchParams();
        queryString.set('paymentID', query.paymentID);
        // queryString.set('selectedArchiveUserID', query.selectedArchiveUserID);
        queryString.set('hospID', query.hospID);

        return await axios.get(
            `${config.API_URL}/payment/archivedPatientsList?${queryString.toString()}`,
            {
                withCredentials: true
            }
        );

    }



    return useQuery(
        {
            queryKey: ['archivedPatients',

                {
                    hospID: hospID,
                    paymentID: paymentID

                }

            ],
            queryFn: fetchPatients,
            enabled: (!!paymentID && !!hospID && enabled),
            staleTime: 1000 * 60 * 15, // 5 minutes
            refetchInterval: 1000 * 60 * 25, // 5 minutes


        }




    )



}