
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

export const useUpdateGOPStateMutation = ({ setsnackbarStatus }) => {

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {


            return axios.post(`${config.API_URL}/data/updateGOPState`, updaterData, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'], refetchType: 'active'}, {cancelRefetch: false });

            setsnackbarStatus(
                {
                    open: true,
                    message: `GOP State Updated`,
                    severity: 'success'

                }
            )
        },
        onError: (error, variables) => {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Updating GOP State`,
                    severity: 'error'

                }
            )
        },
    })
}

