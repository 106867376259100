import dayjs from 'dayjs';




export function TimestampConverter(time, dateOnly = false) {
    if (!time) return null;
    const date = new Date(time);
    const dateString = date.toISOString();
    const year = dateString.split('-')[0];
    const month = dateString.split('-')[1];
    const day = dateString.split('-')[2].split('T')[0];
    const timeString = dateString.split('T')[1].split('.')[0];
    const dateParsedString = `${day}.${month}.${year}`;
    if (dateOnly) return dateParsedString;

    const fromNowTime = dayjs(date).fromNow();

    const currentTime = new Date(Date.now());
    const diff = currentTime - date;
    if (diff < 86400000) {
        return `${fromNowTime}`;
    } else {
        return `${dateParsedString} ${timeString.split(':')[0]}:${timeString.split(':')[1]}`;
    }
}
