
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";




export const useFileFetchQuery = ({ selectedRowCaseNo, selectedhospCaseUserID,selectedhospCaseHospID, fetchedFileType, displayfileIndex, enabled }) => {


    async function fileFetchFn({ queryKey }) {
        const [_key, QuerySelection] = queryKey;

        const { caseNumber, fetchedFileType, selectedhospCaseUserID, selectedhospCaseHospID } = QuerySelection;
        const queryString = new URLSearchParams();
        queryString.set('filetype', fetchedFileType);
        queryString.set('caseNumber', caseNumber);
        queryString.set('selectedhospCaseHospID', selectedhospCaseHospID);
        queryString.set('selectedhospCaseUserID', selectedhospCaseUserID);
        queryString.set('fileIndex', displayfileIndex);

        return await axios.get(`${config.API_URL}/pdf/fetchFile?${queryString.toString()}`, {
            responseType: 'blob',
            withCredentials: true
        });

    }

    return useQuery({
        queryKey: ['fileFetch',
            {
                caseNumber: selectedRowCaseNo,
                selectedhospCaseUserID: selectedhospCaseUserID,
                selectedhospCaseHospID: selectedhospCaseHospID,
                fetchedFileType: fetchedFileType,
                displayfileIndex: displayfileIndex
            }

        ],
        queryFn: fileFetchFn,
        retry: (failureCount, error) => {
            return (failureCount < 2 && (error.response?.status !== 404 || error.response?.status !== 401))
        },
        enabled: !!enabled,
        refetchOnWindowFocus: false,



    });




}