
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from '../../../config.js';


export const useActivePaymentListQuery = ({opMode}) => {

    async function fetchActivePayment() {

        return await axios.get(`${config.API_URL}/payment/ActivePaymentList`, {
            withCredentials: true,
        })

    }

    return useQuery(
        {
            queryKey: ['ActivePaymentList'],
            queryFn: fetchActivePayment,
            enabled: (!!opMode),

        }
    )





}


