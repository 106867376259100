import React from 'react';
import { Divider, Typography } from '@mui/material';
import { displayCaseNo } from '../../../../functions/displayCaseNo';
import { GOPStateSelector } from './components/GOPStateSelector';
import { titleStyle } from './GOPPageComponent';
import numeral from 'numeral';


export const GOPSelectorForm = ({
    selectedRow, worksWithTL, selectedhospCaseHospID, lang, setsnackbarStatus, viewportWidth
}) => {


    const merged = viewportWidth < 1250;
    const hospCase = selectedRow?.hospCases.find(hospCase => hospCase.hospID === selectedhospCaseHospID);

    const isReso = (selectedRow?.InsuranceCo === 'Reso-Garant');

    const GOP_Date = new Date(hospCase?.GOP_Date);
    const parsedTL = numeral(hospCase?.GOPPriceTL / 100 || 0).format('0,0.00');
    const parsedExr = numeral((hospCase?.GOPPriceExr) || 0).format('0,0.00');
    const parsedUSD = numeral(hospCase?.GOPPriceUSD || 0).format('0,0');
    const parsedEUR = numeral(hospCase?.GOPPriceEUR || 0).format('0,0');


    return (<div className='GOPDocumentPageInfoContainer'>

        {merged ? <>
            <div className='GOPDocumentPageInfoElementContainer' style={{ minWidth: '380px', maxWidth: '450px' }}>
                <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Ref:`}
                    </Typography>
                    <Typography>
                        {`${displayCaseNo(selectedRow)}`}
                    </Typography>
                </div>
                <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Sigortalının Adı, Soyadı:`}
                    </Typography>
                    <Typography>
                        {`${selectedRow?.name}`}
                    </Typography>
                </div>
                <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Garanti Edilen Tutar:`}
                    </Typography>
                    {!worksWithTL ? <Typography>
                        {`${parsedUSD} $ x ${parsedExr} = ${parsedTL} ₺`}
                    </Typography>
                        :
                        <Typography>
                            {`${parsedTL} ₺`}
                        </Typography>}
                </div>
                {(!worksWithTL && isReso) ? <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Proforma Tutar:`}
                    </Typography>
                    <Typography>
                        {`${parsedEUR} €`}
                    </Typography>
                </div> : null}
                <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Garanti Tarihi:`}
                    </Typography>
                    <Typography>
                        {`${GOP_Date.toLocaleDateString()}`}
                    </Typography>
                </div>








            </div>

        </>



            : <>
                <div className='GOPDocumentPageInfoElementContainer' style={{ minWidth: '380px', maxWidth: '450px' }}>
                    <div className='GOPDocumentPageListItem'>
                        <Typography style={{ ...titleStyle }}>
                            {`Ref:`}
                        </Typography>
                        <Typography>
                            {`${displayCaseNo(selectedRow)}`}
                        </Typography>
                    </div>
                    <div className='GOPDocumentPageListItem'>
                        <Typography style={{ ...titleStyle }}>
                            {`Sigortalının Adı, Soyadı:`}
                        </Typography>
                        <Typography>
                            {`${selectedRow?.name}`}
                        </Typography>
                    </div>
                </div>
                <Divider orientation="vertical" flexItem />
                <div className='GOPDocumentPageInfoElementContainer' style={{ minWidth: '455px', maxWidth: '650px' }}>
                    <div className='GOPDocumentPageListItem'>
                        <Typography style={{ ...titleStyle }}>
                            {`Garanti Edilen Tutar:`}
                        </Typography>
                        {!worksWithTL ? <Typography>
                            {`${parsedUSD} $ x ${parsedExr} = ${parsedTL} ₺`}
                        </Typography>
                            :
                            <Typography>
                                {`${parsedTL} ₺`}
                            </Typography>}
                    </div>
                    {(!worksWithTL && isReso) ? <div className='GOPDocumentPageListItem'>
                        <Typography style={{ ...titleStyle }}>
                            {`Proforma Tutar:`}
                        </Typography>
                        <Typography>
                            {`${parsedEUR} €`}
                        </Typography>
                    </div> : null}
                    <div className='GOPDocumentPageListItem'>
                        <Typography style={{ ...titleStyle }}>
                            {`Garanti Tarihi:`}
                        </Typography>
                        <Typography>
                            {`${GOP_Date.toLocaleDateString()}`}
                        </Typography>
                    </div>
                </div>
            </>}












        <Divider orientation="vertical" flexItem />

        <GOPStateSelector
            caseNumber={selectedRow?.caseNumber}
            selectedhospCaseHospID={selectedhospCaseHospID}
            lang={lang}
            setsnackbarStatus={setsnackbarStatus}
            initialValue={hospCase?.GOP_State + 2} />

    </div>);
}
