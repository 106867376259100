
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useParsedPaymentListHospQuery = ({selectedHospital, opMode, enabled}) => {

    async function fetchParsedPayment({ queryKey }) {
        const [_key, selectedHospital] = queryKey;

        return await axios.get(`${config.API_URL}/payment/PaymentPatientListHosp?selectedHospital=${selectedHospital}`, {
            withCredentials: true,
        })

    }



    return useQuery(
        {
            queryKey: ['ParsedPaymentListHosp', selectedHospital],
            enabled:  enabled && opMode && (selectedHospital !== null && selectedHospital !== undefined && selectedHospital !== '') ,
            queryFn: fetchParsedPayment,
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes

        }
    )





}


