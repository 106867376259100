
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useUserSettingsQuery = () => {

    async function fetchSettings() {
        return await axios.get(
            `${config.API_URL}/operator/userSettings`,
            {
                withCredentials: true
            }
        );

    }

    return useQuery(
        {
            queryKey: ['userSettings'],
            queryFn: fetchSettings,
            staleTime: Infinity,
        }
    )
}