function insertUnique(arr, item) {

    let newArr = [...arr];
    if (arr.find((elem) => item.depth === elem.depth) === -1) {
        newArr.push(item);
    }
    return newArr;
}


export function mailTree(mailData) {

    const maxNestLevel = mailData.reduce((acc, mail) => {
        return mail?.references?.length > acc ? mail?.references?.length : acc;
    }, 0);

    let mailTreeNestObject = {};
    for (let i = 1; i < maxNestLevel + 1; i++) { // create obj per nesting lvl : {x1: [...] ,x2:[...] }
        let currentLevel = mailData.filter((currMail) => currMail?.references?.length === i).sort((a, b) => new Date(a.date) - new Date(b.date));

        mailTreeNestObject[`x${i}`] = currentLevel;
    }

    let mailTreeOrderArray = [...mailTreeNestObject['x1']]; // init with first lvl
    for (let i = 1; i < maxNestLevel; i++) { // loop over nested levels, sort and insert children each iter

        let currentLevelArray = [...mailTreeNestObject[`x${i}`]];
        let nextLevelArray = [...mailTreeNestObject[`x${i+1}`]];

        currentLevelArray.forEach((mail) => {
            let mailIndex = mailTreeOrderArray.findIndex((mailData) => mailData.messageId === mail.messageId);
            let nextLevelResponseArray = nextLevelArray.filter((mailData) => mailData.inReplyTo === mail.messageId).sort((a, b) => new Date(a.date) - new Date(b.date));

            mailTreeOrderArray.splice(mailIndex + 1, 0, ...nextLevelResponseArray);
        });


    }

    for (let i = mailTreeOrderArray.length - 1; i >= 0; i--) { // reverse loop, for each entry check for skip lines, then insert lines to draw in entries above with nestedLine: [{depth: , type:}, {...}]

        let mail = mailTreeOrderArray[i];
        let replyIndex = mailTreeOrderArray.findIndex((mailData) => mailData.messageId === mail.inReplyTo); // find where to draw a line towards

        if (replyIndex > 0 && replyIndex !== i - 1) { // if has connection and it's not right above, insert line to all elements in between
            let replyNestedLevel = (mailTreeOrderArray[replyIndex].references.length + 1); // nested level of the reply - use as offset from left

            mailTreeOrderArray[i].nestedLine = Array.isArray(mailTreeOrderArray[i]?.nestedLine) ? insertUnique(mailTreeOrderArray[i]?.nestedLine, { depth: replyNestedLevel, type: 'top' })
                :
                [{ depth: replyNestedLevel, type: 'top' }];

            for (let j = i - 1; j > replyIndex; j--) { // loop over elements above up to connected line
                mailTreeOrderArray[j].nestedLine = Array.isArray(mailTreeOrderArray[j]?.nestedLine) ? insertUnique(mailTreeOrderArray[j]?.nestedLine, { depth: replyNestedLevel, type: 'middle' })
                    :
                    [{ depth: replyNestedLevel, type: 'middle' }];
            }

            mailTreeOrderArray[replyIndex].nestedLine = Array.isArray(mailTreeOrderArray[replyIndex]?.nestedLine) ? insertUnique(mailTreeOrderArray[replyIndex]?.nestedLine, { depth: replyNestedLevel, type: 'bottom' })
                :
                [{ depth: replyNestedLevel, type: 'bottom' }];

        }
    }

    console.log(mailTreeOrderArray);

    return mailData; // mailTreeOrderArray

}
