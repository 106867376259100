import React, { useEffect, useState } from 'react';
import { exampleData, exampleData2 } from './exampleData';
import TextLanguage from '../../data/TextLanguage.json';
import { CompletionBar } from '../DataTable/components/CompletionBar';
import { formatCaseNo } from '../DataTable/ColumnFormats/formatCaseNo';
import { progressHeader } from '../DataTable/components/progressHeader';

import { formatGOPPriceTL } from '../DataTable/ColumnFormats/formatGOPPriceTL';
import { formatGOPPriceUSD } from '../DataTable/ColumnFormats/formatGOPPriceUSD';
export const tableRowStyle = {
    border: '1px solid var(--color-gray-tint)', padding: '0.5rem',
}

const ExampleTableHeader = ({ lang }) => {
    return (
        <thead className='p-datatable-thead'>
            <tr>
                <th style={{ ...tableRowStyle, minWidth: '160px' }}>{TextLanguage["RefNo"][lang]}</th>
                <th style={{ ...tableRowStyle, minWidth: '120px' }}>{TextLanguage["PatientName"][lang]}</th>
                <th style={{ ...tableRowStyle }}>{'GOP (USD)'}</th>
                <th style={{ ...tableRowStyle }}>{'GOP (TL)'}</th>
                <th style={{ ...tableRowStyle, minWidth: '400px' }}>{progressHeader({ lang, searchFilter: {}, dense: false })()}</th>
            </tr>
        </thead>
    )
};
const ExampleTableRow = ({ data, lang, docProgress }) => {



    return (

            <tr key='tableRow' className='p-datatable-row defaultRow'>
                <td style={{ ...tableRowStyle, minWidth: '160px' }}>{formatCaseNo({ confirmedUserID: '10083', opMode: false })(data)}</td>
                <td style={{ ...tableRowStyle, minWidth: '120px' }}>{data.name}</td>
                <td style={{ ...tableRowStyle }}>{formatGOPPriceUSD(data)}</td>
                <td style={{ ...tableRowStyle }}>{formatGOPPriceTL(data)}</td>
                <td style={{ ...tableRowStyle, minWidth: '400px', width: 'clamp(400px, 20vw, 800px)' }}><CompletionBar
                    key={`${data?.RBKCaseNo}-onboard`}
                    uploadShortcutMutation={() => { }}
                    docProgress={docProgress}
                    RBKCaseNo={data.RBKCaseNo}
                    caseNumber={data.caseNumber}
                    index={0}
                    hospCaseAmount={1}
                    barUserID={'10083'}

                /></td>
            </tr>


    )
};

export const ExampleTable = ({ lang }) => {



    const [counter, setCounter] = useState(0);
    const [counterGOP, setCounterGOP] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(prevCounter => { return ((prevCounter + 1) % 3); });
            setCounterGOP(prevCounter => { return ((prevCounter + 1) % 4); });
        }, 2000);

        return () => clearInterval(interval);
    }, []);




    return <div className='p-datatable p-datatable-selectable'>
        <table className='p-datatable-table' style={{ width: '100%', borderCollapse: 'collapse' }}>
            <ExampleTableHeader data={exampleData} lang={lang} />
            <tbody className='p-datatable-tbody'>
                <ExampleTableRow data={exampleData} lang={lang} docProgress={[counter, counterGOP, counter, counter]} />
                <ExampleTableRow data={exampleData2} lang={lang} docProgress={[counter + 1, counterGOP + 1, counter + 1, counter + 1]} />
            </tbody>
        </table>
    </div>;
}
