
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

export const useUploadInsDataMutation = ({ setsnackbarStatus, setUploadProgress, setParsedList }) => {

    //const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ formData, insuranceCo }) => {

            return axios.post(
                `${config.API_URL}/table/insuranceData?insuranceCo=${insuranceCo}`,
                formData,
                {
                    withCredentials: true,
                    onUploadProgress: progressEvent => setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                }

            );
        },
        onSuccess: (data, variables) => {
            //queryClient.invalidateQueries({ queryKey: ['ActivePaymentList'], refetchType: 'active'}, {cancelRefetch: false });

            setParsedList(data.data);
        },
        onError: (error, variables) => {
            if(error.response?.status === 406){
                setsnackbarStatus(
                    {
                        open: true,
                        message: 'File Missing Correct Insurance Name',
                        severity: 'error'

                    }
                )
            } else {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Uploading File`,
                    severity: 'error'

                }
            )
            }
        },
    })
}