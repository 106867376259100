import React,{ useState } from 'react';
import { Typography } from '@mui/material';
import TextLanguage from '../../../../../data/TextLanguage.json';
import { titleStyle } from '../GOPPageComponent';
import { useUpdateGOPStateMutation } from '../../InsurancePage/hooks/useUpdateGOPStateMutation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';

import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';

import styles from './GOPStateSelector.module.css';

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));


export const labels = {
    1: 'Denied',
    2: 'Pending',
    3: 'Approved',
  };
export const colors = {
    1: '#c30404',
    2: '#f57c00',
    3: '#4caf50'
};


const customIcons = {
    1: {
        icon: <CancelIcon sx={{ fontSize: '32px' }} color="error" />,
        label: 'Declined',
    },
    2: {
        icon: <HelpIcon sx={{ fontSize: '32px' }} color="warning" />,
        label: 'Pending',
    },
    3: {
        icon: <CheckCircleIcon sx={{ fontSize: '32px'}} color="success" />,
        label: 'Accepted',
    },

};



const IconContainer = (GOP_State) => (props) => {
    const { value, ...other } = props;
    return <span {...other} style={{ outline:value === GOP_State ? '2px solid var(--color-main-theme-saturated)' : 'none', borderRadius:'100%' }} >{customIcons[value].icon}</span>;
}






export const GOPStateSelector = ({
    lang,
    selectedhospCaseHospID,
    caseNumber,
    setsnackbarStatus=()=>{},
    initialValue,
    demoMode = false,
    setState = () => {},
    state = 0
}) => {


    const updateGOPStateMutation = useUpdateGOPStateMutation({ setsnackbarStatus });
    const [GOP_State, setGOP_State] = useState(initialValue);
    const handleGOPStateChange = (event, newValue) => {
        console.log(newValue)
        if (newValue === null) return;
        setGOP_State(newValue);
        !demoMode && !!caseNumber && updateGOPStateMutation.mutate({ updaterData: { caseNumber: caseNumber, selectedhospCaseHospID: selectedhospCaseHospID, GOP_State: (newValue - 2) } });
        demoMode && setState(newValue);
    };
    const [hover, setHover] = useState(-1);
    const currentState = demoMode ? state : GOP_State;

    return (<div className={styles.container}>
        <Typography style={{ ...titleStyle }}>
            {`Garanti Kabul Durumu`}
        </Typography>
        <StyledRating
            sx={{gap:'0.5rem'}}
            defaultValue={1}
            max={3}
            value={currentState}
            IconContainerComponent={IconContainer(currentState)}
            getLabelText={(value) => customIcons[value].label}
            highlightSelectedOnly
            onChange={handleGOPStateChange}
            onChangeActive={(event, newHover) => {
                setHover(newHover);
            }} />
        <div>
            <Typography style={{
                fontSize: '16px',
                color: (hover !== -1) ? colors[hover] : colors[currentState],
                fontWeight: 'bold',
                pointerEvents:'none',
                userSelect:'none'
            }}>
                {TextLanguage[(hover !== -1) ? labels[hover] : labels[currentState] || "Pending"][lang]}
            </Typography>
        </div>
    </div>);
}
