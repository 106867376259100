import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

export const FormTabs = styled(Tabs)({
    fontWeight: 'bold !important',
    background: 'rgb(77, 77, 77)',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    border: '4px solid rgba(44, 44, 44,0.2)',
    borderBottom: '3px solid rgba(44, 44, 44,0.9)',
    boxShadow: '2px 1px 4px 4px rgba(11, 11, 11,0.15)',
    width: "100%",
    '& .MuiTabs-indicator': {
        backgroundColor: 'rgb(125,188,255)',
        bottom: -2,
        height: 4,
        borderRadius: '12px',
        boxShadow: '0px -2px 25px 4px rgba(125,188,255,0.95)'
    },
    position: 'relative', // Add this line
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0, // Adjust as needed
        left: 0, // Adjust as needed
        width: "100%", // Adjust as needed
        height: '1px', // Adjust as needed
        backgroundColor: 'rgba(22, 22, 22,0.4)',
        borderRadius: '0px',
        boxShadow: '0px 0px 10px 6px rgba(22, 22, 22,0.3)',
    },
});
