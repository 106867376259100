
import React, { useState, useEffect } from 'react';

import FileSelectorHeader from '../../components/FileSelectorHeader';
import { Typography } from '@mui/material';
// import { StyledLoading } from '../../../UserSettings/StyledLoading';




export const DocumentsPageComponent = ({
    onRowFileURLs,
    opMode,
    selectedhospCaseUserID,
    selectedhospCaseHospID,
    displayfileIndex,
    setdisplayfileIndex,

    updateFlagsMutation,
    fileDisplayPdfUrl,

    setsnackbarStatus,
    selectedRow,
    maxPageCount,
    fileFetchQuery

}) => {


    const renderPDF = (fileFetchQuery.isSuccess && fileDisplayPdfUrl && fileDisplayPdfUrl !== 'Wrong_Type' && onRowFileURLs?.length > 0);

    return (
        (<>
            <div className='DocumentsPageContainer'>


                <FileSelectorHeader
                    switchMode={true}
                    onRowFileURLs={onRowFileURLs}
                    displayfileIndex={displayfileIndex}
                    setdisplayfileIndex={setdisplayfileIndex}
                    selectedhospCaseUserID={selectedhospCaseUserID}
                    selectedhospCaseHospID={selectedhospCaseHospID}
                    opMode={opMode}
                    updateFlagsMutation={updateFlagsMutation}
                    setsnackbarStatus={setsnackbarStatus}
                    selectedRow={selectedRow}
                    maxPageCount={maxPageCount}
                    fileFetchQuery={fileFetchQuery}
                />


                {renderPDF &&
                    <div className='docspdfContainer' >
                        <iframe id='docsPage-pdf-docs' title="PDF Viewer" width="100%" height="600px" src={fileDisplayPdfUrl}></iframe>
                    </div>}

                {
                    (fileDisplayPdfUrl === 'Wrong_Type') ?
                        <div style={{ display: 'flex', flexGrow: 1, width: '100%', justifyContent: 'center', alignItems: 'center', background: 'rgb(45,45,45)' }}>
                            <Typography sx={{ fontSize: '2rem', pointerEvents:'none', userSelect:'none' }}>Wrong File Type</Typography>
                        </div>
                        :
                        fileFetchQuery.isError ? <div style={{ display: 'flex', flexGrow: 1, width: '100%', justifyContent: 'center', alignItems: 'center', background: 'rgb(45,45,45)' }}>
                            <Typography sx={{ fontSize: '2rem', pointerEvents:'none', userSelect:'none' }} variant='h1'>File Fetch Error</Typography>
                        </div>
                            :
                            null

                }
            </div>


        </>)
    )



}



