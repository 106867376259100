
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';
export const useCreatePatientMutation = ({setSubmissionState, setsnackbarStatus}) => {

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ patientForm }) => {

            return axios.post(
                `${config.API_URL}/data/createPatient`,
                patientForm,
                {
                    withCredentials: true,
                }
            )
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'], refetchType: 'active'}, {cancelRefetch: false });
            queryClient.invalidateQueries({ queryKey: ['activityLog'], refetchType: 'active'}, {cancelRefetch: false });

            setSubmissionState(false);
        },
        onError: (error, variables) => {
            console.log(error);
            queryClient.invalidateQueries({ queryKey: ['patients'], refetchType: 'active'}, {cancelRefetch: false });
            queryClient.invalidateQueries({ queryKey: ['activityLog'], refetchType: 'active'}, {cancelRefetch: false });
            const errorMessage = error?.response?.data?.error?.message || 'Failed to Create Patient Case';
            setsnackbarStatus({ open: true, message: errorMessage, severity: 'error', duration: 12000 });
            setSubmissionState(true);
        }
    });
}
