export const exampleData = {
    "_id": "6792044eec77e6896a76e222",
    "name": "PATIENT NAME",
    "LDMCaseNo": "TUR 2025-123",
    "dob": "2020-05-11T00:00:00.000Z",
    "admissionDate": "2025-03-07T00:00:00.000Z",
    "mobilePhone": "+ 7 125 852 33 61",
    "hotelRoom": "1262",
    "policyNo": "A107-9180765/1",
    "InsuranceCo": "ЕВРОИНС",
    "complaints": "Отек губ",
    "notes": "",
    "franchise": "30$",
    "InsurancePeriodStart": "2025-01-23T08:56:08.766Z",
    "InsurancePeriodEnd": "2025-01-27:56:08.766Z",
    "hospCases": [{
        "userID": "10083",
        "hospID": "6560d9bc86acb9912104ec32",
        "flags": {
            "UnreadMessageOp": false,
            "UnreadMessageUser": true,
            "RejectedProformabyOp": false,
            "RejectedGOPbyUser": false,
            "RejectedInvoicebyOp": false,
            "MissingDocs": true
        },

        "files": [
            {
                "originalname": "proforma.jpg",
                "filetype": "proforma",
                "blobUrl": "",
                "uploadedat": "2025-02-14T16:57:14.674Z"
            },
            {
                "originalname": "patientDoc1.pdf",
                "filetype": "patientDoc",
                "blobUrl": "",
                "uploadedat": "2025-02-16T05:27:39.730Z"
            },
            {
                "originalname": "patientDoc2.pdf",
                "filetype": "patientDoc",
                "blobUrl": "",
                "uploadedat": "2025-02-18T05:27:39.730Z"
            },
            {
                "originalname": "invoice.pdf",
                "filetype": "invoice",
                "blobUrl": "",
                "uploadedat": "2025-02-16T05:28:16.737Z"
            }
        ],
        "GOPPriceEUR": 0,
        "GOPPriceExr": 36.02,
        "GOPPriceTL": 126070,
        "GOPPriceUSD": 35,
        "GOP_Date": "2025-02-16T05:27:29.180Z",
        "Proforma_Date": "2025-02-15T17:07:54.331Z",
        "GOP_State": 0,
        "comments": [{ "by": { "type": "RBK", "label": "Admin" }, "comment": "test", "commentDate": "2025-02-16T05:27:24.440Z" }]
    }],
    "LDMPrefix": "TUR",
    "LDMYear": 2025,
    "LDMCaseNoInt": 123,
    "RBKCaseNo": "RBK1504",
    "caseNumber": 1504,
    "createdAt": "2025-01-23T08:56:46.747Z",
    "LastEditAt": "2025-01-23T08:56:46.747Z",
    "createdBy": "Admin"
};
export const exampleData2 = {
    "_id": "6792044eec77e6896a76e222",
    "name": "PATIENT NAME",

    "dob": "2020-05-11T00:00:00.000Z",
    "admissionDate": "2025-03-07T00:00:00.000Z",
    "mobilePhone": "+ 7 125 852 33 61",
    "hotelRoom": "1262",
    "policyNo": "A107-9180765/1",
    "InsuranceCo": "ЕВРОИНС",
    "complaints": "Отек губ",
    "notes": "",
    "franchise": "30$",
    "InsurancePeriodStart": "2025-01-23T08:56:08.766Z",
    "InsurancePeriodEnd": "2025-01-27:56:08.766Z",
    "hospCases": [{
        "userID": "10083",
        "hospID": "6560d9bc86acb9912104ec32",
        "flags": {
            "UnreadMessageOp": false,
            "UnreadMessageUser": false,
            "RejectedProformabyOp": false,
            "RejectedGOPbyUser": false,
            "RejectedInvoicebyOp": false,
            "MissingDocs": false
        },

        "files": [
            {
                "originalname": "proforma.jpg",
                "filetype": "proforma",
                "blobUrl": "",
                "uploadedat": "2025-02-14T16:57:14.674Z"
            },
            {
                "originalname": "patientDoc.pdf",
                "filetype": "patientDoc",
                "blobUrl": "",
                "uploadedat": "2025-02-16T05:27:39.730Z"
            },
            {
                "originalname": "invoice.pdf",
                "filetype": "invoice",
                "blobUrl": "",
                "uploadedat": "2025-02-16T05:28:16.737Z"
            }
        ],
        "GOPPriceEUR": 0,
        "GOPPriceExr": 34,
        "GOPPriceTL": 68000,
        "GOPPriceUSD": 20,
        "GOP_Date": "2025-02-16T05:27:29.180Z",
        "Proforma_Date": "2025-02-15T17:07:54.331Z",
        "GOP_State": 0,
        "comments": [{ "by": { "type": "RBK", "label": "Admin" }, "comment": "test", "commentDate": "2025-02-16T05:27:24.440Z" }]
    }],
    "LDMPrefix": "TUR",
    "LDMYear": 2025,
    "LDMCaseNoInt": 123,
    "RBKCaseNo": "RBK1504",
    "caseNumber": 1504,
    "createdAt": "2025-01-23T08:56:46.747Z",
    "LastEditAt": "2025-01-23T08:56:46.747Z",
    "createdBy": "Admin"
};
export const exampleList = [
    exampleData,
    {
        "_id": "6792044eec77e6896a76e222",
        "name": "PATIENT NAME",
        "LDMCaseNo": "TUR 2025-235",
        "dob": "2020-05-11T00:00:00.000Z",
        "admissionDate": "2025-03-07T00:00:00.000Z",
        "mobilePhone": "+ 7 125 852 33 61",
        "hotelRoom": "1262",
        "policyNo": "A107-9180765/1",
        "InsuranceCo": "ЕВРОИНС",
        "complaints": "Отек губ",
        "notes": "",
        "franchise": "30$",
        "InsurancePeriodStart": "2025-01-23T08:56:08.766Z",
        "InsurancePeriodEnd": "2025-01-27:56:08.766Z",
        "hospCases": [{
            "userID": "10083",
            "hospID": "6560d9bc86acb9912104ec32",
            "flags": {
                "UnreadMessageOp": false,
                "UnreadMessageUser": true,
                "RejectedProformabyOp": false,
                "RejectedGOPbyUser": false,
                "RejectedInvoicebyOp": false,
                "MissingDocs": true
            },

            "files": [
                {
                    "originalname": "proforma.jpg",
                    "filetype": "proforma",
                    "blobUrl": "",
                    "uploadedat": "2025-02-14T16:57:14.674Z"
                },
                {
                    "originalname": "patientDoc.pdf",
                    "filetype": "patientDoc",
                    "blobUrl": "",
                    "uploadedat": "2025-02-16T05:27:39.730Z"
                },
                {
                    "originalname": "invoice.pdf",
                    "filetype": "invoice",
                    "blobUrl": "",
                    "uploadedat": "2025-02-16T05:28:16.737Z"
                }],
            "GOPPriceEUR": 0,
            "GOPPriceExr": 32.02,
            "GOPPriceTL": 80000,
            "GOPPriceUSD": 22,
            "GOP_Date": "2025-02-16T05:27:29.180Z",
            "Proforma_Date": "2025-02-15T17:07:54.331Z",
            "GOP_State": 0,
            "comments": [{ "by": { "type": "RBK", "label": "Admin" }, "comment": "test", "commentDate": "2025-02-16T05:27:24.440Z" }]

        }],
        "LDMPrefix": "TUR",
        "LDMYear": 2025,
        "LDMCaseNoInt": 235,
        "RBKCaseNo": "RBK1505",
        "caseNumber": 1505,
        "createdAt": "2025-01-23T08:56:46.747Z",
        "LastEditAt": "2025-01-23T08:56:46.747Z",
        "createdBy": "Admin"
    },
    {
        "_id": "6792044eec77e6896a76e222",
        "name": "PATIENT NAME",
        "LDMCaseNo": "TUR 2025-328",
        "dob": "2020-05-11T00:00:00.000Z",
        "admissionDate": "2025-03-07T00:00:00.000Z",
        "mobilePhone": "+ 7 125 852 33 61",
        "hotelRoom": "1262",
        "policyNo": "A107-9180765/1",
        "InsuranceCo": "ЕВРОИНС",
        "complaints": "Отек губ",
        "notes": "",
        "franchise": "0$",
        "InsurancePeriodStart": "2025-01-23T08:56:08.766Z",
        "InsurancePeriodEnd": "2025-01-27:56:08.766Z",
        "hospCases": [{
            "userID": "10083",
            "hospID": "6560d9bc86acb9912104ec32",
            "flags": {
                "UnreadMessageOp": false,
                "UnreadMessageUser": true,
                "RejectedProformabyOp": false,
                "RejectedGOPbyUser": false,
                "RejectedInvoicebyOp": false,
                "MissingDocs": true
            },

            "files": [
                {
                    "originalname": "proforma.jpg",
                    "filetype": "proforma",
                    "blobUrl": "",
                    "uploadedat": "2025-02-14T16:57:14.674Z"
                },
                {
                    "originalname": "patientDoc.pdf",
                    "filetype": "patientDoc",
                    "blobUrl": "",
                    "uploadedat": "2025-02-16T05:27:39.730Z"
                },
                {
                    "originalname": "invoice.pdf",
                    "filetype": "invoice",
                    "blobUrl": "",
                    "uploadedat": "2025-02-16T05:28:16.737Z"
                }],
            "GOPPriceEUR": 0,
            "GOPPriceExr": 32,
            "GOPPriceTL": 320000,
            "GOPPriceUSD": 100,
            "GOP_Date": "2025-02-16T05:27:29.180Z",
            "Proforma_Date": "2025-02-15T17:07:54.331Z",
            "GOP_State": 0,
            "comments": [{ "by": { "type": "RBK", "label": "Admin" }, "comment": "test", "commentDate": "2025-02-16T05:27:24.440Z" }]

        }],
        "LDMPrefix": "TUR",
        "LDMYear": 2025,
        "LDMCaseNoInt": 328,
        "RBKCaseNo": "RBK1506",
        "caseNumber": 1506,
        "createdAt": "2025-01-23T08:56:46.747Z",
        "LastEditAt": "2025-01-23T08:56:46.747Z",
        "createdBy": "Admin"
    },
    {
        "_id": "6792044eec77e6896a76e222",
        "name": "PATIENT NAME",
        "LDMCaseNo": "TUR 2025-754",
        "dob": "2020-05-11T00:00:00.000Z",
        "admissionDate": "2025-03-07T00:00:00.000Z",
        "mobilePhone": "+ 7 125 852 33 61",
        "hotelRoom": "1262",
        "policyNo": "A107-9180765/1",
        "InsuranceCo": "ЕВРОИНС",
        "complaints": "Отек губ",
        "notes": "",
        "franchise": "30$",
        "InsurancePeriodStart": "2025-01-23T08:56:08.766Z",
        "InsurancePeriodEnd": "2025-01-27:56:08.766Z",
        "hospCases": [{
            "userID": "10083",
            "hospID": "6560d9bc86acb9912104ec32",
            "flags": {
                "UnreadMessageOp": false,
                "UnreadMessageUser": true,
                "RejectedProformabyOp": false,
                "RejectedGOPbyUser": false,
                "RejectedInvoicebyOp": false,
                "MissingDocs": true
            },

            "files": [
                {
                    "originalname": "proforma.jpg",
                    "filetype": "proforma",
                    "blobUrl": "",
                    "uploadedat": "2025-02-14T16:57:14.674Z"
                },
                {
                    "originalname": "patientDoc.pdf",
                    "filetype": "patientDoc",
                    "blobUrl": "",
                    "uploadedat": "2025-02-16T05:27:39.730Z"
                },
                {
                    "originalname": "invoice.pdf",
                    "filetype": "invoice",
                    "blobUrl": "",
                    "uploadedat": "2025-02-16T05:28:16.737Z"
                }],
            "GOPPriceEUR": 32,
            "GOPPriceExr": 36.02,
            "GOPPriceTL": 126070,
            "GOPPriceUSD": 35,
            "GOP_Date": "2025-02-16T05:27:29.180Z",
            "Proforma_Date": "2025-02-15T17:07:54.331Z",
            "GOP_State": 0,
            "comments": [{ "by": { "type": "RBK", "label": "Admin" }, "comment": "test", "commentDate": "2025-02-16T05:27:24.440Z" }]

        }],
        "LDMPrefix": "TUR",
        "LDMYear": 2025,
        "LDMCaseNoInt": 754,
        "RBKCaseNo": "RBK1509",
        "caseNumber": 1509,
        "createdAt": "2025-01-23T08:56:46.747Z",
        "LastEditAt": "2025-01-23T08:56:46.747Z",
        "createdBy": "Admin"
    },
    {
        "_id": "6792044eec77e6896a76e222",
        "name": "PATIENT NAME",
        "LDMCaseNo": "TUR 2025-934",
        "dob": "2020-05-11T00:00:00.000Z",
        "admissionDate": "2025-03-07T00:00:00.000Z",
        "mobilePhone": "+ 7 125 852 33 61",
        "hotelRoom": "1262",
        "policyNo": "A107-9180765/1",
        "InsuranceCo": "ЕВРОИНС",
        "complaints": "Отек губ",
        "notes": "",
        "franchise": "0$",
        "InsurancePeriodStart": "2025-01-23T08:56:08.766Z",
        "InsurancePeriodEnd": "2025-01-27:56:08.766Z",
        "hospCases": [{
            "userID": "10083",
            "hospID": "6560d9bc86acb9912104ec32",
            "flags": {
                "UnreadMessageOp": false,
                "UnreadMessageUser": true,
                "RejectedProformabyOp": false,
                "RejectedGOPbyUser": false,
                "RejectedInvoicebyOp": false,
                "MissingDocs": true
            },

            "files": [
                {
                    "originalname": "proforma.jpg",
                    "filetype": "proforma",
                    "blobUrl": "",
                    "uploadedat": "2025-02-14T16:57:14.674Z"
                },
                {
                    "originalname": "patientDoc.pdf",
                    "filetype": "patientDoc",
                    "blobUrl": "",
                    "uploadedat": "2025-02-16T05:27:39.730Z"
                },
                {
                    "originalname": "invoice.pdf",
                    "filetype": "invoice",
                    "blobUrl": "",
                    "uploadedat": "2025-02-16T05:28:16.737Z"
                }],
            "GOPPriceEUR": 0,
            "GOPPriceExr": 33.52,
            "GOPPriceTL": (135 * 33.52),
            "GOPPriceUSD": 135,
            "GOP_Date": "2025-02-16T05:27:29.180Z",
            "Proforma_Date": "2025-02-15T17:07:54.331Z",
            "GOP_State": 0,
            "comments": [{ "by": { "type": "RBK", "label": "Admin" }, "comment": "test", "commentDate": "2025-02-16T05:27:24.440Z" }]

        }],
        "LDMPrefix": "TUR",
        "LDMYear": 2025,
        "LDMCaseNoInt": 934,
        "RBKCaseNo": "RBK1514",
        "caseNumber": 1514,
        "createdAt": "2025-01-23T08:56:46.747Z",
        "LastEditAt": "2025-01-23T08:56:46.747Z",
        "createdBy": "Admin"
    }
];
export const exampleLogList = [
    { "_id": "67bbb658fa207ff146427279", "logType": "UpdateGOP", "logUser": "Admin", "logUserType": "Admin", "RBKCaseNo": "RBK1510", "caseNumber": 1510, "timestamp": "2025-02-23T23:59:20.780Z", "hospital": "10081", "GOPPriceEUR": 0, "GOPPriceUSD": 125, "GOPPriceExr": 33.82, "GOPPriceTL": 125 * 3382 },
    { "_id": "67bbb653fa207ff146427278", "logType": "NewMessage", "logUser": "Admin", "logUserType": "Admin", "RBKCaseNo": "RBK1510", "caseNumber": 1510, "displayCaseNo": "RBK1510", "timestamp": "2025-02-23T23:59:15.522Z", "hospital": "10081", "comment": "asdsa" },
    { "_id": "67bb98a8e8894265dbc32bb9", "logType": "UpdateGOP", "logUser": "Hastane", "logUserType": "Client", "RBKCaseNo": "RBK1501", "caseNumber": 1501, "timestamp": "2025-02-23T21:52:40.691Z", "hospital": "10099", "GOP_State": -1 },
    { "_id": "67bbbf2369c9fddebc9e694f", "logType": "UploadDocuments", "logUser": "Admin", "logUserType": "Admin", "RBKCaseNo": "RBK1514", "caseNumber": 1514, "timestamp": "2025-02-24T00:36:50.867Z", "hospital": "10071" }

]
