import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";



export const CountdownDisplay = ({
    timer = 300,
    initialCount,
    setAwaitingVerification
}) => {

    const [countdown, setCountdown] = useState(initialCount);

    useEffect(() => {
        if(timer - Math.floor((Date.now() - initialCount) / 1000) <= 1){
            setTimeout(() => {
                setAwaitingVerification(false);
            }, 1000)

        }
        if (countdown > 0) {
            const timerId = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }

        // Clear the timer if the component is unmounted
    }, [countdown]);



    return (  ( timer - Math.floor((Date.now() - initialCount) / 1000) > 0 ) ?
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
    background:'var(--background-gradient-c)', borderRadius: '0.5rem', border:'1px solid var(--color-shadow1)',
    boxShadow: '0px 0px 12px 0px var(--color-shadow1)', width: '3.5rem'


    }}>
        <Typography sx={{
            color: 'white', fontSize: '1rem', fontWeight: 'bold',
            pointerEvents:'none', userSelect:'none' }}>
        {`${`${timer - Math.floor((Date.now() - initialCount) / 1000)}`}`}
        </Typography>
    </div> : null);
}

