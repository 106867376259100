

import React, { useRef, useEffect } from 'react';

import {
    Typography,


} from '@mui/material';

import { styled } from '@mui/material/styles';

import Accordion from '@mui/material/Accordion';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TimestampConverter } from '../functions/TimestampConverter';


const MailAccordion = styled((props) => ( // Accordion Settings
    <Accordion disableGutters elevation={12} square {...props} />
))(({ theme }) => ({

    background: 'rgb(73 73 75)',
    border: `none`,
    outline: '2px solid var(--color-dark1)',
    transition: 'all 0.3s ease',
    padding: '0.15rem',
    '&:not(:last-child)': {
        borderBottom: '2px solid rgba(34, 34, 37, 0.5)',
    },
    '&:before': {
        display: 'none',
    },
}));

export const MailDisplay = ({
    orderedMailTreeArray,
    selectedRow,
    selectedMailID,
    setSelectedMailID
}) => {

    const handleMailSelection = (mailID) => () => {
        setSelectedMailID(mailID);
    };


    const mailSelectionContainerRef = useRef(null);
    const buttonRefs = useRef({});

    useEffect(() => {
        if (selectedMailID && buttonRefs.current[selectedMailID]) {
            buttonRefs.current[selectedMailID].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
        }
    }, [selectedMailID]);

    return (<>

        <div className='mailWrapper' ref={mailSelectionContainerRef}>

            {orderedMailTreeArray?.filter(mail => !!mail?.html).map((mail, index) => {

                const mailRBK = mail?.from?.some((from) => from?.address?.includes('rbkassistance'));

                const innerHTML = { __html: mail?.html }
                const parsedDateTime = TimestampConverter(mail?.date);

                return (

                    <MailAccordion

                        key={`mail-accordion-root-${mail?.messageId}`}
                        onClick={handleMailSelection(mail?.messageId)}
                        expanded={(selectedMailID === mail?.messageId) || true}
                        sx={{ width: '100%',
                            borderLeft: mailRBK ? '6px solid var(--color-main-theme-saturated-x)' : '6px solid var(--color-theme-client)',
                            filter: selectedMailID === mail?.messageId ? 'saturate(1.25) contrast(1) brightness(1.25)' : 'brightness(1)',
                            boxShadow: selectedMailID === mail?.messageId ?
                            '0px 0 1rem -0.2rem var(--color-dark-shadow)' : 'inset 0px -0.75rem 1rem -0.75rem var(--color-shadow1)'
                         }}

                    >
                        <AccordionSummary
                            ref={ el => buttonRefs.current[mail?.messageId] = el}
                            expandIcon={<ExpandMoreIcon />}
                            id={`mail-accordion-${mail?.messageId}`}
                        >
                            <div className='mailAccordionSummary' >

                                <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem' }}>
                                    <Typography>
                                        {mail?.subject}
                                    </Typography>

                                </div>
                                <Typography sx={{ fontSize: '0.8rem', color: '#b5b5b5' }}>
                                    {`${parsedDateTime}`}
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div
                                id='mailContent'
                                className={`mailContainer`}
                                dangerouslySetInnerHTML={innerHTML}>
                            </div>
                            {   /*
                            <iframe
                            width={'100%'}
                            height={2200}
                            srcdoc={mail?.html}

                            />
*/}

                        </AccordionDetails>
                    </MailAccordion>


                )

            })}


        </div>

    </>)

}

















