import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextLanguage from '../data/TextLanguage.json';
import MailIcon from '@mui/icons-material/Mail';
import { useRemoveCaseMutation } from '../hooks/useRemoveCaseMutation';
import { displayCaseNo } from '../functions/displayCaseNo';
import { Divider, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDialogButton } from './PatientFormContainer/components/StyledDialogButton';

export default function RemoveConfirmationDialog({
    removeFormOpen, setRemoveFormOpen, setsnackbarStatus, lang = "enUS"
}) {

    const selectedRow = removeFormOpen?.selectedRow;

    const selectedhospCaseUserID = removeFormOpen?.selectedhospCaseUserID || null;
    const selectedhospCaseHospID = removeFormOpen?.selectedhospCaseHospID || null;

    const [message, setMessage] = React.useState('');

    const removeCaseMutation = useRemoveCaseMutation({ setsnackbarStatus });

    const handleDeclineClose = () => {
        setRemoveFormOpen({ state: false, selectedRow: null, selectedhospCaseUserID: null, selectedhospCaseHospID: null });
    };

    const handleRemoveCase = (notify) => () => {
        const updaterData = { caseNumber: selectedRow?.caseNumber, notify: notify, message: message, selectedhospCaseUserID: selectedhospCaseUserID, selectedhospCaseHospID: selectedhospCaseHospID };
        removeCaseMutation.mutate({ updaterData: updaterData })
        setRemoveFormOpen({ state: false, selectedRow: null, selectedhospCaseUserID: null, selectedhospCaseHospID: null });
    };




    return (
        <>

            <Dialog
                open={removeFormOpen?.state}
                onClose={handleDeclineClose}
                aria-labelledby="remove-dialog-title"

            >
                <IconButton
                    aria-label="close"
                    onClick={handleDeclineClose}
                    fontSize="large"
                    sx={{
                        position: 'absolute',
                        right: '8px', top: '8px',
                        color: 'red',
                        zIndex: 1000,
                        '&:hover': { background: 'transparent' }
                    }}>
                    <CloseIcon />
                </IconButton>

                <DialogTitle id="remove-dialog-title"  >
                    <Typography sx={{ fontWeight: 'bold', pointerEvents: 'none', userSelect: 'none' }}>
                        {displayCaseNo(selectedRow)}
                    </Typography>

                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: 'var(--color-text)', fontWeight: 'bold' }} id="alert-dialog-description">
                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center',
                            gap: '1.5rem', padding: '1rem 1rem 0.5rem 1rem',
                            background: 'var(--color-dark-shadow)', borderRadius: '0.5rem', border: '1px solid var(--color-dark-shadow)',
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem', paddingInline: '0.5rem' }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '1.15rem', pointerEvents: 'none', userSelect: 'none' }}>
                                    {TextLanguage["RemoveCaseDialog"][lang]}
                                </Typography>
                                <MailIcon sx={{ color: 'lightblue', marginLeft:'auto' }} />
                            </div>

                            <Divider sx={{ width: '100%' }} />

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem', padding: '0.5rem 1rem 1rem 0.5rem' }}>
                                <TextField
                                    label={TextLanguage["MailContent"][lang]}
                                    InputLabelProps={{ shrink: true }} d
                                    multiline
                                    rows={5}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    sx={{ maxWidth: '500px', "& .MuiInputBase-input":{
                                        padding:'12px 4px 8px 4px'
                                    } }} />
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledDialogButton onClick={handleRemoveCase(false)}>  {TextLanguage["No"][lang]}</StyledDialogButton>
                    <StyledDialogButton onClick={handleRemoveCase(true)}>{TextLanguage["Yes"][lang]}</StyledDialogButton>
                </DialogActions>
            </Dialog>
        </>
    );
}