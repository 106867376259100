import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

const useUpdateFlagsMutation = ({ setsnackbarStatus }) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ flag, state, selectedRow, selectedhospCaseHospID }) => {

            const prevFlags = selectedRow?.hospCases.find(hospCase => hospCase.hospID === selectedhospCaseHospID)?.flags;

            const updaterData = {
                selectedhospCaseHospID: selectedhospCaseHospID,
                caseNumber: selectedRow?.caseNumber,
                flags: { ...prevFlags, [flag]: state }
            }

            return axios.post(
                `${config.API_URL}/data/updateFlags`,
                updaterData,
                {
                    withCredentials: true
                }
            )
        },
        onSuccess: (variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'], refetchType: 'active'}, {cancelRefetch: false }   );
        },
        onError: (error, variables) => {
            if (error.response.status !== 403) {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Error Updating Flags`,
                        severity: 'error'
                    }
                )

            }
        },

    })
}

export { useUpdateFlagsMutation };