import React from "react";
import { Typography } from '@mui/material';
import numeral from "numeral";

export const formatGOPPriceTL = (patientRow) => {


    return (


        patientRow?.hospCases?.map(
            (hospCase, index) => {

                const parsedPrice = (hospCase?.GOPPriceTL !== undefined && hospCase?.GOPPriceTL !== 0) ? `${numeral(hospCase?.GOPPriceTL / 100).format('0,0.00')} ₺` : '-'
                return (
                    <Typography key={`${index}-goptl`} className='RowPriceText'>
                        {`${parsedPrice}`}
                    </Typography>
                )
            }
        )



    )
}
