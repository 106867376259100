
import React from 'react';



import GOPDocument from '../../../PDF/GOPDocument';

import PDFCreator from '../../../PDF/PDFCreator';

import { GOPInputForm } from './GOPInputForm';
import FileSelectorHeader from '../../components/FileSelectorHeader';
import { useQueryClient } from '@tanstack/react-query';

import { GOPSelectorForm } from './GOPSelectorForm';

export const titleStyle = {
    textAlign: 'center', fontWeight: 'bold', color: '#c30404', pointerEvents: 'none', userSelect: 'none'
};

export const GOPPageComponent = ({
    lang,
    opMode,
    selectedhospCaseUserID,
    selectedhospCaseHospID,
    handleFieldChange,
    patientForm,
    dispatch,
    selectedRow,
    setsnackbarStatus,
    onRowFileURLs,
    displayfileIndex,
    setdisplayfileIndex,
    updateFlagsMutation,
    fileDisplayPdfUrl,
    fileFetchQuery,
    paymentMode,
    viewportWidth

}) => {


    const queryClient = useQueryClient();
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);
    const userListData = hospitalUserListData?.data || [];
    const hospCase = selectedRow?.hospCases.find(hospCase => hospCase.hospID === selectedhospCaseHospID);
    const userData = userListData?.find((hospitalUser) => hospitalUser?.value === selectedhospCaseUserID);

    //const regexGov = /Devlet/i;
    const worksWithTL = userData?.worksWithTL;



    return (
        (
            (
                <div className='GOPDocumentPageContainer'>

                    {opMode ? <GOPInputForm
                        lang={lang}
                        // handleReplaceForm={handleReplaceForm}

                        setsnackbarStatus={setsnackbarStatus}
                        patientForm={patientForm}
                        dispatch={dispatch}
                        handleFieldChange={handleFieldChange}
                        selectedhospCaseHospID={selectedhospCaseHospID}
                        selectedRow={selectedRow}
                        worksWithTL={worksWithTL}
                        paymentMode={paymentMode}

                    /> :
                        <GOPSelectorForm
                            selectedRow={selectedRow}
                            worksWithTL={worksWithTL}
                            selectedhospCaseHospID={selectedhospCaseHospID}
                            lang={lang}
                            setsnackbarStatus={setsnackbarStatus}
                            viewportWidth={viewportWidth}
                        />

                    }




                    <div className='GOPpdfContainer'>

                        {<div className='HorizontalpdfContainer' style={{ width: '50vw !important' }}>

                            <FileSelectorHeader
                                switchMode={false}
                                onRowFileURLs={onRowFileURLs}
                                displayfileIndex={displayfileIndex}
                                setdisplayfileIndex={setdisplayfileIndex}
                                selectedhospCaseUserID={selectedhospCaseUserID}
                                selectedhospCaseHospID={selectedhospCaseHospID}
                                opMode={opMode}
                                updateFlagsMutation={updateFlagsMutation}
                                fileFetchQuery={fileFetchQuery}

                            />

                            {onRowFileURLs?.length > 0 &&
                                <div className='docspdfContainer' >
                                    <iframe id='docsPage-pdf-docs' title="PDF Viewer Invoice" width="100%" height="600px" src={fileDisplayPdfUrl}></iframe>
                                </div>

                            }



                        </div>}



                        <div className='HorizontalpdfContainer' style={{ width: '50vw !important' }}>
                            {!!(hospCase?.GOPPriceUSD > 0) && <PDFCreator
                                DocComponent={GOPDocument}
                                selectedRow={selectedRow}
                                hospCaseID={selectedhospCaseUserID}
                                selectedhospCaseHospID={selectedhospCaseHospID}
                                hospitalUserListData={hospitalUserListData}
                            />}
                        </div>
                    </div>

                </div>
            ))
    )



}










