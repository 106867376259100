
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

const useUploadMutation = ({ setUploadFormProgress, setsnackbarStatus }) => {

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ formData, uploadingFileType, clickedRowCaseNo, selectedhospCaseHospID }) => {

            return axios.post(
                `${config.API_URL}/data/pushBlob?filetype=${uploadingFileType}&caseNumber=${clickedRowCaseNo}&selectedhospCaseHospID=${selectedhospCaseHospID}`,
                formData,
                {
                    withCredentials: true,
                    onUploadProgress: ((progressEvent) => {
                        setUploadFormProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));

                    })
                }

            );
        },
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({ queryKey: ['patients'] , refetchType: 'active'}, {cancelRefetch: false});
            await queryClient.invalidateQueries({ queryKey: ['activityLog'], refetchType: 'active'}, {cancelRefetch: false });
            await queryClient.invalidateQueries({ queryKey:
                ['fileTokens', { caseNumber: variables?.clickedRowCaseNo ,selectedhospCaseHospID: variables?.selectedhospCaseHospID }], refetchType: 'active'},
                {cancelRefetch: false});

            //const currentPageData = queryClient.getQueriesData({ queryKey: ['patients'], type: 'active' })
           // setselectedRow(currentPageData[0][1].data.patientList.find(patient => patient.RBKCaseNo === variables?.clickedRowCaseNo));
           // console.log('foundNewData:', currentPageData[0][1].data.patientList.find(patient => patient.RBKCaseNo === variables?.clickedRowCaseNo));

            setUploadFormProgress(0);

            setsnackbarStatus(
                {
                    open: true,
                    message: `Successfully Uploaded
                    ${
                    variables?.uploadingFileType === 'patientDoc' ? 'Documents'
                    :
                    variables?.uploadingFileType === 'proforma' ? 'Proforma'
                    :
                    'Invoice'
                } for ${variables?.clickedRowCaseNo}`,
                    severity: 'success'
                }
            );

        },
        onError: (error, variables) => {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Uploading File for ${variables?.clickedRowCaseNo}`,
                    severity: 'error'
                }
            );
        },
        onSettled: (data, error, variables, context) => {
        }
    });
}

export { useUploadMutation };