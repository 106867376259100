import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

export const useDeleteFileMutation = ({setsnackbarStatus}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ caseNumber, selectedhospCaseUserID, selectedhospCaseHospID, blobUrl }) => {


            return axios.post(
                `${config.API_URL}/data/deleteFile`,
                { caseNumber, selectedhospCaseUserID, selectedhospCaseHospID, blobUrl },
                { withCredentials: true }
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'], refetchType: 'active'}, {cancelRefetch: false });
            queryClient.invalidateQueries({ queryKey: ['activityLog'], refetchType: 'active'}, {cancelRefetch: false });
            setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ['fileTokens'] , refetchType: 'active'}, {cancelRefetch: false});
            }, 500);
        },
        onError: (error, variables) => {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Deleting File`,
                    severity: 'error'

                }
            )
        },
    });
}

