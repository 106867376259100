
import React from 'react';
import { Tooltip, Button } from '@mui/material';
import { StyledTooltip } from './StyledTooltip';

export const NavBarSwitchButton = ({ children, onClick, disableButton = false, label, placement }) => {
    return (
        <StyledTooltip
            placement={placement}
            title={label}
        >
            <span>

                <Button
                    onClick={onClick}
                    disabled={disableButton}
                    sx={{
                        background: 'transparent', minWidth: '30px !important', padding: '8px 12px', borderRadius: 0,
                        gap: '0.25rem',
                        '&:hover': {
                            background: 'rgb(42,45,43)',
                            boxShadow: 'none',
                            outline: 'none',
                            border: 'none',
                        }
                    }}

                >
                    {children}
                </Button>

            </span>
        </StyledTooltip>
    );
};