
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config';
import { useNavigate } from 'react-router-dom';

export const useVerificationMutation = ({setsnackbarStatus}) => {

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation({

        mutationFn: ({username, verificationCode}) => {

            return axios.post(
                `${config.API_URL}/login/verify`,
                {
                    username: username,
                    verificationCode: verificationCode,
                    appName: 'FileshareApp'
                },

                {
                    withCredentials: true
                }

            );
        },
        onSuccess: (data, variables, context) => {
            if (data.status === 200) {
                queryClient.invalidateQueries(); // { queryKey: [] }

                navigate("/fileshare");
            }
        },
        onError: (error, variables, context) => {
            console.log(error);

            if( error?.response?.status === 429 ) {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Too many requests. Please try again later.`,
                        severity: 'error'
                    }
                )
            }
        }
    })




}