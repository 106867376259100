import React, { useEffect } from 'react';
import TextLanguage from '../../../../data/TextLanguage.json';
import {
    Typography,
    TextField,
    Button,
    Slider
} from '@mui/material';

import { useUpdatePatientGOPMutation } from './hooks/useUpdatePatientGOPMutation';
import { useQueryClient } from '@tanstack/react-query';
import { displayCaseNo } from '../../../../functions/displayCaseNo';
import { styled } from '@mui/material/styles';

import numeral from 'numeral';
import Decimal from 'decimal.js';
import { colors, labels } from './components/GOPStateSelector';

const DividerElement = () => (<svg height="35" width="4">
    <line x1="0" y1="0" x2="0" y2="50" stroke="lightblue" strokeWidth="4" />
</svg>)
const titleStyle = {
    textAlign: 'center', fontWeight: 'bold', color: '#c30404', pointerEvents: 'none', userSelect: 'none'
};


const calculateTL = (USD, Exr) => {
    const USDDecimal = new Decimal(USD || 0);
    const ExrDecimal = new Decimal(Exr || 0);
    const TLCalculation = USDDecimal.mul(ExrDecimal);
    return TLCalculation.toFixed(2);
}

const calculateExr = (TL, USD) => {
    const TLDecimal = new Decimal(TL || 0);
    const USDDecimal = new Decimal(USD || 0);
    const ExrCalculation = TLDecimal.div(USDDecimal);
    return ExrCalculation.toFixed(2);
}

Decimal.set({ precision: 13, rounding: 1 })


const DrivenTextField = styled(TextField)({
    pointerEvents: 'none',
    userSelect: 'none',
    background: 'var(--color-main-theme-darker-desaturated)',
    boxShadow: 'inset 0px 0px 12px 2px rgba(0, 0, 0, 0.15)',
    width: '100%',
    '& .MuiInputBase-root': {
        color: 'var(--color-text)',
        height: '3rem',
    },
    '& .MuiInputBase-input' : {
        height: '3rem',
        padding: '0.5rem 0.75rem',
    },
});
const StyledTextField = styled(TextField)({
    boxShadow: 'inset 0px 0px 12px 2px rgba(0, 0, 0, 0.15)',
    width: '100%',
    '& .MuiInputBase-root': {
        color: 'var(--color-text)',
        height: '3rem',
    },
    '& .MuiInputBase-input' : {
        height: '3rem',
        padding: '0.5rem 0.75rem',
    },
});

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '0.35rem !important',
    padding: '0.5rem 1rem !important',
    border: `1px solid ${theme.palette.dark[0]}`,
    background: theme.palette.gradient.a,
    color: 'var(--color-text)',
    height: 'auto',
    '&:hover': {
        border: `1px solid ${theme.palette.buttonGlow[3]}`,
        boxShadow: `inset 0px 0px 8px -2px ${theme.palette.buttonGlow[3]}`,
        background: theme.palette.gradient.a,
        outline: 'none',

        filter: 'brightness(1.15) contrast(1.1)',
    },

}));






export const GOPInputForm = ({
    lang,
    setsnackbarStatus,
    selectedRow,

    // handleReplaceForm,

    selectedhospCaseHospID,
    worksWithTL,
    paymentMode = false
}) => {


    const updatePatientGOPMutation = useUpdatePatientGOPMutation({ setsnackbarStatus, paymentMode });


    const queryClient = useQueryClient();

    const exchangeRateData = queryClient.getQueryData(['exchangeRate']);
    const exchangeRate = exchangeRateData?.data?.BanknoteSellingValue;

    /*
    const handleIntegerInput = (fieldName) => (event) => {

        const inputValue = event.target.value;

        // Check if the input is a valid integer before updating the state
        if (/^\d*$/.test(inputValue)) {

            dispatch({
                type: 'SET_FIELD',
                fieldName: fieldName,
                payload: inputValue,
            });

        }

    }
*/
    const [GOPPriceEUR, setGOPPriceEUR] = React.useState(0);
    const [GOPPriceUSD, setGOPPriceUSD] = React.useState(0);
    const [GOPPriceExr, setGOPPriceExr] = React.useState(exchangeRate);
    const [GOPPriceTL, setGOPPriceTL] = React.useState(0);

    const hospCase = selectedRow?.hospCases?.find((hospCase) => hospCase?.hospID === selectedhospCaseHospID);
    const GOP_Date = new Date(hospCase?.GOP_Date);
    const GOP_State = (hospCase?.GOP_State + 2);

    useEffect(() => {

        hospCase?.GOPPriceEUR && setGOPPriceEUR(hospCase?.GOPPriceEUR); // not null or 0
        hospCase?.GOPPriceUSD && setGOPPriceUSD(hospCase?.GOPPriceUSD);
        hospCase?.GOPPriceExr && setGOPPriceExr(hospCase?.GOPPriceExr);
        hospCase?.GOPPriceTL && setGOPPriceTL(hospCase?.GOPPriceTL / 100);

    }, [])

    useEffect(() => {
        hospCase?.GOPPriceEUR && setGOPPriceEUR(hospCase?.GOPPriceEUR); // not null or 0
        hospCase?.GOPPriceUSD && setGOPPriceUSD(hospCase?.GOPPriceUSD);
        hospCase?.GOPPriceExr && setGOPPriceExr(hospCase?.GOPPriceExr);
        hospCase?.GOPPriceTL && setGOPPriceTL(hospCase?.GOPPriceTL / 100);
    }, [selectedRow])




    const handleSubmitPostGOP = async (e) => { // patientForm: {RBKCaseNo, GOPPriceUSD, GOPPriceExr}
        e.preventDefault();
        const decimalTL = new Decimal(GOPPriceTL || 0);
        const newTL = decimalTL.mul(100).toFixed(0);

        updatePatientGOPMutation.mutate({
            updaterData: {
                caseNumber: selectedRow?.caseNumber,
                GOPPriceEUR: GOPPriceEUR ? parseInt(GOPPriceEUR) : 0,
                GOPPriceUSD: parseInt(GOPPriceUSD),
                GOPPriceExr: GOPPriceExr,
                GOPPriceTL: newTL,
                selectedhospCaseHospID: selectedhospCaseHospID,
                paymentMode: false
            }
        })
    };



    const handleSubmitPaymentGOP = async (e) => { // patientForm: {RBKCaseNo, GOPPriceUSD, GOPPriceExr}
        e.preventDefault();
        const decimalTL = new Decimal(GOPPriceTL || 0);
        const newTL = decimalTL.mul(100).toFixed(0);

        updatePatientGOPMutation.mutate({
            updaterData: {
                caseNumber: selectedRow?.caseNumber,
                GOPPriceEUR: GOPPriceEUR ? parseInt(GOPPriceEUR) : 0,
                GOPPriceUSD: parseInt(GOPPriceUSD),
                GOPPriceExr: GOPPriceExr,
                GOPPriceTL: newTL,
                selectedhospCaseHospID: selectedhospCaseHospID,
                paymentMode: true
            }
        })
    };

    const handleTLChange = (e) => {
        setGOPPriceTL(e.target.value);
        if (e.target.value > 0 && GOPPriceUSD > 0) {
            setGOPPriceExr(calculateExr(e.target.value, GOPPriceUSD));
            // setGOPPriceExr(parseFloat((newTL / GOPPriceUSD).toFixed(2)));
        }

    };

    const handleUSDChange = (e) => {
        setGOPPriceUSD(e.target.value);
        if (worksWithTL && e.target.value > 0 && GOPPriceTL > 0) {
            setGOPPriceExr(calculateExr(GOPPriceTL, e.target.value));
        } else if (!worksWithTL) {
            setGOPPriceTL(calculateTL(e.target.value, GOPPriceExr));
        }
    };

    const handleExrChange = (e) => {

        const sanitizedExr = new Decimal(e.target.value || 0).clampedTo(0, exchangeRate).toDecimalPlaces(2).toNumber();

        setGOPPriceExr(sanitizedExr);
        if (!worksWithTL) {
            setGOPPriceTL(calculateTL(GOPPriceUSD, sanitizedExr));
        };
    };

    const handleEURChange = (e) => { setGOPPriceEUR(e.target.value) };

    const decimalTL = new Decimal(hospCase?.GOPPriceTL || 0);
    const newTL = decimalTL.div(100).toFixed(2);
    const parsedTLPrice = numeral(newTL).format('0,0.00');
    const parsedUSDPrice = numeral(hospCase?.GOPPriceUSD).format('0,0');
    const parsedEURPrice = numeral(hospCase?.GOPPriceEUR).format('0,0');
    const parsedExr = numeral(hospCase?.GOPPriceExr).format('0,0.00');


    const percentage = ((exchangeRate - GOPPriceExr) / exchangeRate * 100).toFixed(2) || 0;
    const exrRateOutOfBounds = parseFloat(percentage) > 25 || parseFloat(percentage) <= 0;

    const isReso = (selectedRow?.InsuranceCo === 'Reso-Garant');

    return (<div className="GOPDocumentPageInputWrapper">

        <div className='GOPDocumentPageInfoColumnContainer'  >

            <div className='GOPDocumentPageInfoElementContainer'>
                <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Ref:`}
                    </Typography>
                    <Typography >
                        {`${displayCaseNo(selectedRow)}`}
                    </Typography>
                </div>

                <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Sigortalının Adı, Soyadı:`}
                    </Typography>
                    <Typography sx={{ textOverflow: 'ellipsis !important', overflow: 'hidden', whiteSpace: 'nowrap', textWrap: 'nowrap' }}>
                        {`${selectedRow?.name}`}
                    </Typography>
                </div>
                {!!hospCase?.GOPPriceUSD && <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Garanti Edilen Tutar:`}
                    </Typography>
                    <Typography >
                        {`${parsedUSDPrice} $ x ${parsedExr} = ${parsedTLPrice} ₺`}
                    </Typography>
                </div>}
                {!!hospCase?.GOPPriceEUR ? <div className='GOPDocumentPageListItem' >
                    <Typography style={{ ...titleStyle }}>
                        {`Proforma Tutar:`}
                    </Typography>
                    <Typography >
                        {`${parsedEURPrice} €`}
                    </Typography>
                </div> : null}

                {!!hospCase?.GOPPriceUSD && <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Garanti Tarihi:`}
                    </Typography>
                    <Typography>
                        {`${GOP_Date.toLocaleDateString()}`}
                    </Typography>
                </div>}
                {!!hospCase?.GOPPriceUSD && <div className='GOPDocumentPageListItem'>
                    <Typography style={{ ...titleStyle }}>
                        {`Garanti Kabul Durumu:`}
                    </Typography>
                    <Typography sx={{
                        color: colors[GOP_State],
                    }}>
                        {TextLanguage[labels[GOP_State] || "Pending"][lang]}
                    </Typography>
                </div>}




            </div>


        </div>

       {!paymentMode ? <div className='GOPDocumentPageInputContainerMain'>
            <div className='GOPDocumentPageInputContainerTop'>
                <Typography sx={{ fontSize: '1rem', color: 'lightgreen', fontWeight: 'bold', textWrap: 'nowrap', gap: '0.25rem' }}>
                    <span style={{ color: 'white', pointerEvents: 'none', userSelect: 'none' }}>{`${TextLanguage['CurrentExrRate'][lang]}:`}</span>
                    <span style={{ color: 'lightgreen', pointerEvents: 'none', userSelect: 'none' }}>{` ${exchangeRate} `}</span>
                    <span style={{ color: '#f68128', pointerEvents: 'none', userSelect: 'none', marginLeft: '2px' }}>{`(${percentage}%)`}</span>
                </Typography>

                {!worksWithTL && <div style={{
                    display: 'flex',
                    flexDirection: 'row', width: '100%', maxWidth: '500px', alignItems: 'center',
                    margin: '10px', justifyContent: 'center'

                }}>
                    <Slider
                        defaultValue={exchangeRate * 0.95}
                        value={GOPPriceExr}
                        onChange={handleExrChange}
                        //set range
                        min={parseFloat(parseFloat(exchangeRate * 0.75).toFixed(2))}
                        max={parseFloat(parseFloat(exchangeRate).toFixed(2))}
                        step={0.0001}
                        marks={[
                            {
                                value: exchangeRate * 0.8,
                                label: `20%`
                            },
                            {
                                value: exchangeRate * 0.85,
                                label: `15%`
                            },
                            {
                                value: exchangeRate * 0.9,
                                label: `10%`
                            },

                            {
                                value: exchangeRate * 0.95,
                                label: `5%`
                            },

                        ]}
                        valueLabelDisplay='off'
                        sx={{
                            color: 'lightgreen', width: '100%', margin: '0rem 4rem',
                            '& .MuiSlider-rail': { color: 'lightgreen' },
                            '& .MuiSlider-track': { color: 'lightgreen' },
                            '& .MuiSlider-thumb': { color: 'lightgreen' },
                            '& .MuiSlider-valueLabel': { color: 'lightgreen' },
                            '& .MuiSlider-mark': { color: 'lightgreen' },
                            '& .MuiSlider-markLabel': { color: 'lightgreen' },
                            '& .MuiSlider-markLabelActive': { color: 'lightgreen' }
                        }}
                    />
                </div>}
            </div>
            <div className='GOPDocumentPageInputContainer'>

                <div className="GOPInputFormRow" >
                    <StyledTextField type="number" label="USD" InputLabelProps={{ shrink: true }}
                        value={GOPPriceUSD}
                        onChange={handleUSDChange} />
                </div>
                <div>
                    <Typography sx={{ pointerEvents: 'none', fontSize: '1.5rem', color: 'lightblue', fontWeight: 'bold', textWrap: 'nowrap', gap: '0.25rem' }}>
                        x
                    </Typography>
                </div>
                {


                    !worksWithTL ? <div className="GOPInputFormRow">
                        <StyledTextField type="number" label={TextLanguage["ExchangeRate"][lang]} InputLabelProps={{ shrink: true }}
                            value={GOPPriceExr}
                            onChange={handleExrChange} />
                    </div> :

                        <div className="GOPInputFormRow">
                            <DrivenTextField
                                type="number"
                                label={TextLanguage["ExchangeRate"][lang]}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                value={GOPPriceExr}
                                sx={{ outline: exrRateOutOfBounds ? '1px solid var(--color-error-light)' : 'none' }}
                            />
                        </div>

                }

                <div>
                    <Typography sx={{ pointerEvents: 'none', fontSize: '1.5rem', color: 'lightblue', fontWeight: 'bold', textWrap: 'nowrap', gap: '0.25rem' }}>
                        =
                    </Typography>
                </div>




                {!worksWithTL ?
                    <div className="GOPInputFormRow">
                        <DrivenTextField
                            type="number"
                            label={'TL'}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            value={GOPPriceTL}
                        />
                    </div>
                    :
                    <StyledTextField type="number" label={'TL'} InputLabelProps={{ shrink: true }}
                        sx={{
                            maxWidth: '10rem',
                            '@media screen and (max-height: 900px)': {
                                maxWidth: '8rem',
                            }
                        }}
                        value={GOPPriceTL}
                        onChange={handleTLChange}

                    />


                }





                {isReso && <>
                    <DividerElement />
                    <div className="GOPInputFormRow">
                        <StyledTextField type="number" label="EUR" InputLabelProps={{ shrink: true }}
                            value={GOPPriceEUR}
                            onChange={handleEURChange} />
                    </div>

                </>

                }
                <div className="GOPInputFormRow">
                    <StyledButton  onClick={handleSubmitPostGOP}
                        disabled={exrRateOutOfBounds || updatePatientGOPMutation.isPending}
                    >
                        <Typography>{TextLanguage["Submit"][lang]}</Typography>
                    </StyledButton>
                </div>
            </div>
        </div>
        :

        <div className='GOPDocumentPageInputContainerMain' style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <div className='GOPDocumentPageInputContainerTop'>
                <div className="GOPInputFormRow" style={{ justifyContent: 'center', paddingBlock: '0.5rem', height: '100%' }}>
                    <StyledTextField type="number" label={'TL'} InputLabelProps={{ shrink: true }}
                    sx={{
                        maxWidth: '10rem',
                        '@media screen and (max-height: 900px)': {
                            maxWidth: '8rem',
                        }
                    }}
                    value={GOPPriceTL}
                    onChange={handleTLChange}
                        />
                </div>
                     <div className="GOPInputFormRow" style={{ justifyContent: 'center', paddingBlock: '0.5rem', height: '100%' }}>
                    <StyledButton  onClick={handleSubmitPaymentGOP}
                    >
                        <Typography>{TextLanguage["Submit"][lang]}</Typography>
                    </StyledButton>
                </div>
            </div>
        </div>

        }





    </div>);
}

