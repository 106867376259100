import React from 'react';


//import TextLanguage from './data/TextLanguage.json';
import useLocalStorage from './hooks/useLocalStorage';
import { ruRU, trTR, enUS } from '@mui/material/locale';

import "flag-icons/css/flag-icons.min.css";
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

//import './prime-theme.css';
import 'primereact/resources/themes/md-dark-indigo/theme.css'
//import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

import 'primeflex/primeflex.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import { MutationCache, QueryCache, QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// import { ErrorBoundary } from "react-error-boundary";
import { SocketProvider } from './components/contextProviders/SocketProvider';
import { DashboardPage } from './pages/DashboardPage';

import { ChatPage } from './pages/ChatPage';
import { InsurancePage } from './pages/InsurancePage';
import { AdminPage } from './pages/AdminPage';
import { LoginPage } from './pages/LoginPage';
import { FilesharePage } from './pages/FilesharePage';
import { PaymentPage } from './pages/PaymentPage';



// ($env:HTTPS = "true") -and ($env:SSL_CRT_FILE="./certs/localhost.pem") -and ($env:SSL_KEY_FILE="./certs/localhost-key.pem") -and (npm start)
const customBreakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
        custom: 1360, // Custom breakpoint for 1360px width
        heightS: 800
    },
};




function App() {


    const lang = useLocalStorage('lang', 'trTR');

    const theme = createTheme({
        breakpoints: customBreakpoints,
        palette: {
            text: {
                primary: 'rgb(255, 255, 255)',
                secondary: 'rgb(175, 175, 175)',
            },
            grey: {
                50: '#fafafa',
                100: '#f5f5f5',
                200: '#eeeeee',
                300: '#e0e0e0',
                400: '#bdbdbd',
                500: '#9e9e9e',
                600: '#757575',
                700: '#616161',
                750: '#424242',
                800: '#333333',
                850: '#2e2e2e',
                900: '#1f1f1f',
            },
            gray: {
                tint: {
                    0: 'var(--color-gray-tint)',
                    1: 'var(--color-gray-tint1)',
                },
                0: 'var(--color-middle-gray)',

            },
            light: {
                0: 'var(--color-light0)',
                1: 'var(--color-light1)',
                2: 'var(--color-light2)',
                3: 'var(--color-light3)',
                4: 'var(--color-light-gray-tint)',
            },
            shadow: {
                0: 'var(--color-shadow0)',
                1: 'var(--color-shadow1)',
                2: 'var(--color-shadow2)',
                3: 'var(--color-shadow3)',
            },
            dark: {
                shadow: 'var(--color-dark-shadow)',
                0: 'var(--color-dark0)',
                1: 'var(--color-dark1)',
                2: 'var(--color-dark2)',
                3: 'var(--color-dark3)',
                4: 'var(--color-dark-gray-tint)',
                transparent: {
                    0: 'var(--color-dark0-transparent)',
                    1: 'var(--color-dark1-transparent)',
                    2: 'var(--color-dark2-transparent)',
                    3: 'var(--color-dark3-transparent)',
                }
            },
            gradient: {
                a: 'var(--background-gradient-a)',
                b: 'var(--background-gradient-b)',
                c: 'var(--background-gradient-c)',
                d: 'var(--background-gradient-d)',
                tableA: 'var(--background-gradient-tableA)',
                tableB: 'var(--background-gradient-tableB)',
                glow: 'var(--background-gradient-glow)',
                glowButton: 'var(--button-hover-glow-gradient)',

            },
            warningColor: {
                main: 'var(--color-warning)',
            },
            errorColor: {
                main: 'var(--color-error)',
                light: 'var(--color-error-light)',
                dark: 'var(--color-error-dark)',
            },
            successColor: {
                main: 'var(--color-success)',
                dark: 'var(--color-success-dark)',
                light: 'var(--color-success-light)',
            },
            mainColor: {
                default: 'var(--color-main-theme)',
                Light: 'var(--color-main-theme-light)',
                LightTransp: 'var(--color-main-theme-light-transparent)',
                DarkDesat: 'var(--color-main-theme-dark-desaturated)',
                DarkerDesat: 'var(--color-main-theme-darker-desaturated)',
                Transp: 'var(--color-main-theme-alpha)',
                Dark: 'var(--color-main-theme-dark)',
                DarkTransp: 'var(--color-main-theme-dark-transparent)',
                Darker: 'var(--color-main-theme-darker)',
                Darkest: 'var(--color-main-theme-darkest)',
                DarkestTransp: 'var(--color-main-theme-darkest-transparent)',
                Sat: 'var(--color-main-theme-saturated)',
            },
            typeColor: {
                Hospital: 'var(--theme-hospital-color)',
                Clinic: 'var(--theme-clinic-color)',
                MedCenter: 'var(--theme-medcenter-color)',
                Dentist: 'var(--theme-dentist-color)',
                Doctor: 'var(--theme-doctor-color)',
            },
            buttonGlow: {
                0: 'var(--button-hover-glow)',
                1: 'var(--button-hover-glow-transparent1)',
                2: 'var(--button-hover-glow-transparent2)',
                3: 'var(--button-hover-glow-transparent3)',
            },
            mode: 'dark',
            dashboardTheme: {
                Patients: 'rgb(84, 248, 158)',
                Docs: 'rgb(225,0,0)',
                GOP: 'rgb(208, 77, 1)',
                Proforma: 'rgb(9, 95, 200)',
                Invoice: 'rgb(146, 156, 2)'
            }
        },

        spacing: 8, // Custom spacing between components
        zIndex: {
            drawer: 1200, // Custom zIndex for drawer
        },
        components: {

            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#4d4d4db8 !important',

                        marginRight: '2px !important',
                        color: 'white',
                        fontSize: '18px'
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgb(40, 50, 60)',
                        color: 'white',
                        padding: '10px',
                        outline: '2px rgba(104, 139, 192, 0.25) solid',

                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        backdropFilter: 'none',
                        transition: 'none',
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        [`@media (max-width: ${customBreakpoints.values.custom}px)`]: {
                            fontSize: '0.8rem', // Adjust font size for screen sizes <= 1360px

                        },
                        alignItems: 'center',
                        display: 'flex',
                        fontFamily: 'Ariel, sans-serif', // Custom font family
                        fontSize: '1rem', // Custom base font size

                    },

                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: { // apply the style to the root
                        color: 'white',
                        '&.Mui-focused': { // change the color when focused
                            color: 'var(--color-success-lightest)',

                            fontSize: '1.2rem'
                        },
                    }
                }
            },
            MuiButton: {
                styleOverrides: {

                    root: {
                        color: 'white',
                        background: 'rgb(49,80,125)',
                        borderRadius: '8px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        '&:hover': { // button hover override
                            background: 'rgb(49,80,125)',
                            outline: '2px solid rgba(83, 141, 228, 0.5)',
                            boxShadow: '0px 0px 8px 2px rgba(64, 117, 196, 0.5)',
                            filter: 'brightness(1.25) saturate(1.25)',
                        },
                    },


                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {

                        '&:focus-visible': { // button hover override
                            background: 'var(--color-main-theme-dark-transparent)',
                            outline: '2px solid var(--color-main-theme-light)',
                            boxShadow: '0px 0px 8px 2px var(--color-main-theme-alpha)',
                            filter: 'brightness(1.25) saturate(1.25)',
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        /*
                        background:' hsl(235, 7%, 40%)',
                        background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 40%) 60%)',
                        boxShadow: 'inset 1px 1px 0px hsl(235, 7%, 28%), inset 2px 2px 0px hsl(235, 7%, 25%), inset 3px 3px 0px hsl(235, 7%, 22%), inset 4px 4px 0px hsl(235, 7%, 18%), inset 5px 5px 0px hsl(235, 7%, 16%)',
                        borderRadius:6,
                        border: '0px',

                        */
                        width: 350,
                        background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 36%) 60%)',
                        border: '1px solid black !important',
                        // [`@media (max-width: ${customBreakpoints.values.custom}px)`]: {
                        //     padding: '0px !important',
                        // },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid rgba(205, 229, 254, 0.5)',
                          outline: 'none'
                        },
                        '&:focus-within .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid rgba(205, 229, 254, 0.5)',
                          outline: 'none'
                        },
                        '&:focus-visible .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid rgba(205, 229, 254, 0.5)',
                            outline: 'none'
                        }

                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        /*
                        background:' hsl(235, 7%, 40%)',
                        background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 40%) 60%)',
                        boxShadow: 'inset 1px 1px 0px hsl(235, 7%, 28%), inset 2px 2px 0px hsl(235, 7%, 25%), inset 3px 3px 0px hsl(235, 7%, 22%), inset 4px 4px 0px hsl(235, 7%, 18%), inset 5px 5px 0px hsl(235, 7%, 16%)',
                        borderRadius:6,
                        border: '0px',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: '3px solid rgba(205, 229, 254, 0.46)',
                          borderRadius:6,
                          boxShadow:'0px 0px 15px 6px rgba(125,188,255,0.54)'
                        },
                        */
                        width: 250,
                        background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 36%) 60%)',
                        border: '1px solid black',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: '0px', // Set the border radius here

                        border: '0px'
                    },
                },
            },
            MuiDateCalendar: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        background: 'linear-gradient(to bottom, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 46%) 60%)'
                    }
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        [`@media (max-height: ${customBreakpoints.values.heightS}px)`]: {
                            fontSize: '0.8rem !important', // Adjust font size for screen sizes <= 1360px
                            padding: '0px 10px 0px 10px',
                        },
                        color: 'white',

                    }
                },
            },
            MuiDayCalendar: {
                styleOverrides: {
                    monthContainer: {
                        background: 'linear-gradient(to bottom, hsl(235, 7%, 32%) 10%, hsl(235, 7%, 46%) 100%)',
                    },
                    header: {
                        background: 'linear-gradient(to top, rgba(22, 22, 22, 0.5) 0%, rgba(22, 22, 22, 0.4) 60%)',
                        borderBottom: '1px solid black',
                        borderTop: '1px solid black',
                    },
                    root: {
                        color: 'white',
                        background: 'linear-gradient(to bottom, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 46%) 60%)'
                    }
                },
            },

            MuiAutocomplete: {
                styleOverrides: {
                    // This will apply to all autocomplete components
                    root: {
                        // Your styles here
                    },
                },
            },
        },
    },
        lang === "enUS" ? enUS : lang === "trTR" ? trTR : lang === "ruRU" ? ruRU : null
    );



    // const handleTokenFailure = async () => {
    //     // await queryClient.cancelQueries();
    //     await queryClient.refetchQueries({ queryKey: ['refresh'] });

    //     // setTimeout(async () => {
    //     //     await queryClient.refetchQueries({ type: 'active', stale: true });
    //     // }, 2000);


    // };

    const queryClient = new QueryClient(
        {
            defaultOptions: {

                queries: {
                    throwOnError: false,
                    refetchOnWindowFocus: false,
                    refetchOnMount: true,
                    refetchOnReconnect: true,
                    retry: (failureCount, error) => {

                        if (error?.response?.status === 401) return false;
                        if (failureCount < 3) return true;
                        return false;
                    },
                    staleTime: 1000 * 60 * 30,
                    cacheTime: 1000 * 60 * 30,

                },
            },
            queryCache: new QueryCache({
                onError: async (error, _variables) => {
                    if (error.response?.status === 401 && !queryClient.isFetching({ queryKey: ["refresh"] })) {
                        try {
                            await queryClient.refetchQueries({ queryKey: ["refresh"]},{ cancelRefetch: false, throwOnError: true })
                        } catch (error) {
                            return;
                        } finally{
                            await queryClient.refetchQueries({ stale: true }, { cancelRefetch: false });
                        }

                    } else if (error.response?.status === 401 && queryClient.isFetching({ queryKey: ["refresh"] })) {
                        setTimeout(async () => {
                            await queryClient.refetchQueries({ queryKey: _variables?.options?.queryKey }, { cancelRefetch: false });
                        }, 100);
                    };
                }
            }),

            mutationCache: new MutationCache({
                onError: async (error, variables, context, mutation) => {
                    if (error.response?.status === 401 && !queryClient.isFetching({ queryKey: ["refresh"] })) {
                        await queryClient.refetchQueries({ queryKey: ["refresh"]},{ cancelRefetch: false })
                        // try {
                        //     await queryClient.refetchQueries({ queryKey: ["refresh"]},{ cancelRefetch: false, throwOnError: true })
                        // } catch (error) {
                        //     return;
                        // } finally{
                        //     await mutation?.mutate(variables);
                        // }
                    }
                },
            })


        }
    );


    return (

        <BrowserRouter>

                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <ThemeProvider theme={theme}>
                        <SocketProvider>
                            <Routes>
                                <Route path="/" element={<LoginPage />} />
                                <Route path="/fileshare" element={<FilesharePage />} />
                                <Route path="/payment" element={<PaymentPage />} />
                                <Route path="/dashboard" element={<DashboardPage />} />
                                <Route path="/insuranceInfo" element={<InsurancePage />} />
                                <Route path="/chat" element={<ChatPage />} />
                                <Route path="/admin" element={<AdminPage />} />
                            </Routes>
                        </SocketProvider>
                    </ThemeProvider>
                </QueryClientProvider>


        </BrowserRouter>

    );
}

export default App;
