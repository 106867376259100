import React from 'react';
import TextLanguage from '../../../data/TextLanguage.json';
import { IconButton } from '@mui/material';
import { FormTabs } from './FormTabs';
import { FormTab } from './FormTab';
import CloseIcon from '@mui/icons-material/Close';
import { useQueryClient } from '@tanstack/react-query';
import { userTypesRBK } from '../../../data/userTypes';

export const FormTabSelector = ({
    hideTabs, selectedhospCaseHospID, selectedTab,
    setSelectedTab, lang, selectedRow, hideFileTab = false,
    handleModalFormClose, paymentMode = false

}) => {


    const hideTabStr = selectedhospCaseHospID && !hideTabs ? 'inline-flex' : 'none';
    const hideFileTabStr = selectedhospCaseHospID && !hideFileTab ? 'inline-flex' : 'none';

    const queryClient = useQueryClient();

    const validateData = queryClient.getQueryData(['validate']);
    const confirmedUserID = validateData?.data?.userID;
    const opMode = userTypesRBK.includes(confirmedUserID);

    const OperatorMode = ['Operator'].includes(confirmedUserID);


    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>
            <FormTabs
                value={selectedTab}
                onChange={(event, newValue) => { setSelectedTab(newValue); }}
                sx={{paddingInline:'0px 50px'}}
            >
                <FormTab value="PatientCaseInformation" label={TextLanguage["PatientCaseInformation"][lang]} />
                {selectedRow && <FormTab value="CommPage" label={TextLanguage["Comm"][lang]} sx={{ display: hideTabStr }} />}
                {selectedRow && <FormTab value="DocumentsPage" label={TextLanguage["Documents"][lang]} sx={{ display: hideFileTabStr }}
                    disabled={opMode ?
                        (selectedRow?.hospCases?.find((hospCase) => hospCase.hospID === selectedhospCaseHospID)?.files?.length > 0 ? false : true)
                        :
                        (selectedRow?.hospCases[0].files?.length > 0 ? false : true)} />}
                {
                    /*
                                    <FormTab value="ProformaPage" label={TextLanguage["Proforma"][lang]} sx={{ display: hideTab }}
                                        disabled={opMode ?
                                            (selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseUserID)?.Proforma_Date ? false : true)
                                            :
                                            (false)} />
                    */
                }
                {selectedRow && !OperatorMode && <FormTab value="GOPDocumentPage" label={TextLanguage["GOP"][lang]} sx={{ display: hideTabStr }}
                    disabled={opMode ?
                        ((selectedRow?.hospCases?.find((hospCase) => hospCase.hospID === selectedhospCaseHospID)?.files?.length > 0) ? false : true)
                        :
                        (selectedRow?.hospCases[0].GOP_Date ? false : true)} />}
                {(opMode && selectedRow && !paymentMode && !OperatorMode) &&
                    <FormTab value="ReviewPage" label={TextLanguage["Review"][lang]} sx={{ display: hideTabStr }} />}

            </FormTabs>

            <IconButton
                    aria-label="close"
                    onClick={handleModalFormClose}
                    fontSize="large"
                    sx={{
                        position: 'absolute', right: '24px', top: '24px',
                        color: 'red',
                        zIndex: 1000,
                        '&:hover': { background: 'transparent' }
                    }}
                >
                    <CloseIcon />
                </IconButton>



        </div>
    );
}

