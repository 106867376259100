import React from "react";
import { Typography } from '@mui/material';
import numeral from "numeral";

export const formatGOPPriceUSD = (patientRow) => {


    return (


        patientRow?.hospCases?.map(
            (hospCase, index) => {

                const parsedPrice = (hospCase?.GOPPriceUSD !== undefined && hospCase?.GOPPriceUSD !== 0) ? `${numeral(hospCase?.GOPPriceUSD || 0).format('0,0')} $` : '-'

                return (
                    <Typography key={`${index}-gopusd`} className='RowPriceText'>
                        {`${parsedPrice}`}
                    </Typography>
                )
            }
        )



    )
}
